import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';


import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';

import { RecoveryPasswordService } from '../../../pages/password-recovery/password-recovery.service';
import { confirmPasswordAction } from '../../actions/password-recovery/confirmPassword.action';
import { Route, Router } from '@angular/router';


@Injectable()
export class recoveryChangePasswordActionEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router

  ) {}
  recoveryVhangePasswordActionEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      recoveryChangePasswordService = inject(RecoveryPasswordService)
    ) => {
      return actions$.pipe(
        ofType(confirmPasswordAction.confirmPassword),
        switchMap(( data ) => {
          return recoveryChangePasswordService.recoveryChangePassword(data.email,data.password,data.verificationCode)
            .pipe(
              map(() => {
                this._snackBar.openFromComponent(SnackBarSuccess, {
                  data: {
                    message: 'Su contraseña fue modificada correctamente',
                    subtitle:
                      'Inicie sesión para volver a utilizar el sistema',
                  },
                  panelClass: ['success-snackbar'],
                  duration: 3000,
                });
                this.router.navigate(['/login']);
                return confirmPasswordAction.recoveryPasswordSuccess()
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Código de validación incorrecto',
                    subtitle:
                     'El código ingresado es incorrecto. Por favor ingresa nuevamente el codigo para modificar la contraseña.',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                    confirmPasswordAction.recoveryPasswordFailure()
                );
              })
            );
        })
      );
    }
  );
}