import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { TypeProducts } from './updateTypeProducts.reducer';
import { typeProductsAction } from '../../actions/type-products/typeProducts.action';


export interface TypeProductsState {
  typeProducts: TypeProducts[];
  isLoading: boolean;
  error: any;
}

export interface TypeProduct {
  name: string;
  description: string;
  active: string;
}

export const initialTypeProductsState: TypeProductsState = {
  isLoading: false,
  error: null,
  typeProducts: [],
};

const typeProductsReducer = createFeature({
  name: 'typeProducts',
  reducer: createReducer(
    initialTypeProductsState,
    on(typeProductsAction.getTypeProductsFeed, (state) => ({
      ...state,
      isLoading: true,
      error: null
    })),
    on(typeProductsAction.getTypeProductsFeedSuccess, (state, {typeProducts}) => ({
      ...state,
      typeProducts,
      isLoading: false,
      error: null
    })),
    on(typeProductsAction.getTypeProductsFeedFailure, (state) => ({
      ...state,
      isLoading: false,
      error: 'Error al cargar los tipos de producto'
    }))
  ),
  extraSelectors: ({ selectTypeProducts }) => ({
    selectTypeProductsInactives: createSelector(
      selectTypeProducts,
      (typeProducts) => typeProducts.filter((typeProduct) => Boolean(typeProduct.active) == false)
    ),
    selectTypeProductsActives: createSelector(
      selectTypeProducts,
      (typeProducts) => typeProducts.filter((typeProduct) => Boolean(typeProduct.active) == true)
    ),
  }),
});

export const {
  name: typeProductReducerKey,
  reducer: typeProductReducer,
  selectError,
  selectIsLoading,
  selectTypeProducts,
  selectTypeProductsActives,
  selectTypeProductsInactives
} = typeProductsReducer;
