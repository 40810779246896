
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeProductsService } from '../type-product.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { updateTypeProductsAction } from 'src/app/state/actions/type-products/updateTypeProducts.action';
import { createTypeProductsAction } from 'src/app/state/actions/type-products/createTypeProduct.action';
import { selectIsSubmittingTypeProductsCreate } from 'src/app/state/reducers/type-products/createTypeProducts.reducer';
import { selectIsSubmittingTypeProductsUpdate, selectTypeProductsStatus } from 'src/app/state/reducers/type-products/updateTypeProducts.reducer';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeProductInterface } from 'src/app/shared/types/typeProduct.interface';
import { selectTypeProductsInactives } from 'src/app/state/reducers/type-products/typeProducts.reducer';


@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './type-product-modal.component.html',
  standalone: true,
  styleUrls: ['./type-product-modal.component.sass'],
  imports: [MatDialogModule, MatButtonModule, FormsModule, CommonModule, MatSelectModule, MatOptionModule, MatIconModule, MatTooltipModule],
})
export class TypeProductModal {

  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TypeProductModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private typeProductsService: TypeProductsService,
    private store: Store,
  ) { }

  dataRes: any
  errorCode?: any = null;
  dataSource = new MatTableDataSource<any>();

  onCancelButtonClick(): void {
    if (this.inputActive && this.isAcceptButtonEnabled) {
      this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent, {
        data: {
          title: "¡Espera un momento!",
          subtitle: "No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
          subtitleQuestion: "¿Deseas salir sin guardar?",
          acceptButton: "Si, descartar",
          cancelButton: "Cancelar",
          icon: true,

        }
      });
      this.notSavedDialogRef.afterClosed().subscribe(result => {
        if (result === 'accept') {
          this.dialog.closeAll()
          if (this.typeProductsService.getEditForm()) {
            this.typeProductsService.openTypeProductDetail(this.id)
            this.typeProductsService.setEditForm(false);
          }
        }
      });
    } else if (this.inputActive && this.typeProductsService.getEditForm()) {
      this.dialogRef.close()
      this.typeProductsService.openTypeProductDetail(this.id)
      this.typeProductsService.setEditForm(false);
    } else {
      this.typeProductsService.disabledEdit = false
      this.dialogRef.close()
    }
  }

  async onCreateButtonClick(modify: boolean, active?: boolean) {
    this.typeProductsService.disabledEdit = false;
    const dataOU: TypeProductInterface = {
      name: this.name,
      description: this.description,
      active: active ? true : undefined,
    };
    if (modify) {
      this.store.dispatch(
        updateTypeProductsAction.updateTypeProducts({
          request: dataOU,
          id: this.data.id,
        })
      );

      this.store.select(selectTypeProductsInactives).subscribe((typeProductsInActive)=>{
        if (!typeProductsInActive.length) {
          this.updateDisabledProductTypeData(false);
          this.disabledBtnProductType(true);
          this.typeProductsService.activeTypeProducts();
        }
      })


      this.store.select(selectIsSubmittingTypeProductsUpdate).subscribe(success => {
        if (success) {
          this.typeProductsService.openTypeProductDetail(this.id)
          this.dialogRef.close()
        }
      })

      if (active) {
        this._snackBar.openFromComponent(SnackBarSuccess, {
          data: {
            message: 'El tipo de producto se habilitó correctamente',
          },
          panelClass: ['success-snackbar'],
          duration: 3000,
        });
      }
    } else {
      this.store.dispatch(
        createTypeProductsAction.createTypeProducts({
          request: dataOU,
        })
      );
      this.store.select(selectIsSubmittingTypeProductsCreate,
      ).subscribe(success => {
        if (success) {
          this.dialogRef.close()
        }
      })
    }
  }

  updateDisabledProductTypeData(value: boolean) {
    this.typeProductsService.updateBooleanData(value);
  }

  disabledBtnProductType(value: boolean) {
    this.typeProductsService.updateDisabledBtnProductTypes(value);
  }

  editTypeProduct(id: number): void {
    this.typeProductsService.setEditForm(true)
    this.typeProductsService.disabledEdit = true;
    this.typeProductsService.openModifyTypeProduct(id)
    this.dialogRef.close()
  }

  title: string = ""
  id: number = 0;
  name: string = ""
  code: string = ""
  description: string = ""
  isAcceptButtonEnabled: boolean = false;
  inputActive: boolean = false;
  acceptButton: string = ""
  cancelButton: string = ""
  editFromCard: boolean = false;
  modify: boolean = false;
  activeProductTypes: boolean = this.typeProductsService.saveActive;
  actives: boolean = this.typeProductsService.disabledProductTypes
  editDisabledBtn: boolean = this.typeProductsService.disabledEdit;
  typeProductStatus:boolean;

  ngOnInit() {
    this.store.select(selectTypeProductsStatus).subscribe(typeProduct => {
      this.typeProductStatus = typeProduct
    });
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.code = this.data.code || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.editFromCard = this.data.editFromCard || false;
    this.modify = this.data.modify || false;
    this.id = this.data.id || 0;
  }

  onInputChange() {
    this.updateAcceptButtonState();
  }

  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled = !!this.name;
  }
}
