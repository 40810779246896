import { Component, Inject, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/**
 * @title Basic snack-bar
 */
@Component({
  selector: 'snackbar-success',
  templateUrl: 'snackbar-success.component.html',
  styleUrls: ['./snackbar.component.sass'],
  standalone: true,
  imports: [MatButtonModule,  MatIconModule],
})
export class SnackBarSuccess {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
  snackBarRef = inject(MatSnackBarRef);
}
