import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseAnalystsService } from 'src/app/pages/purchase-analyst/purchase-analyst.service';
import { purchaseAnalystActions } from '../../actions/purchase-analyst/purchaseAnalyst.actions';
import { PurchaseAnalystInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalyst.interface';


@Injectable()
export class getPurchaseAnalystEffect {
  constructor(private readonly actions$: Actions, public dialog: MatDialog) {}

  getPurchaseAnalystEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      purchaseAnalystsService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(purchaseAnalystActions.getPurchaseAnalyst),
        switchMap(({ uuid }) => {
          return purchaseAnalystsService.getPurchaseAnalyst(uuid).pipe(
            map((purchaseAnalyst: PurchaseAnalystInterface) => {
              return purchaseAnalystActions.getPurchaseAnalystSuccess({
                purchaseAnalyst,
              });
            }),
            catchError(() => {
              return of(
                purchaseAnalystActions.getPurchaseAnalystFailure()
              );
            })
          );
        })
      );
    }

  );
}

