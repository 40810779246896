import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { organizationalUnitFeedActions } from '../../actions/organizational-units/organizationalUnitFeed.actions';
import { toggleStateOrganizationalUnitActions } from '../../actions/organizational-units/toggleStateOrganizationalUnit.actions';
import { CareersService } from 'src/app/pages/careers/careers.service';
import { AssociatedElementsModalComponent } from 'src/app/shared/components/associated-elements-modal/associated-elements-modal.component';

@Injectable()
export class toggleStateOrganizationalUnitEffect {
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    private store: Store<AppState>
  ) {}


  disableOrganizationalUnitEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateOrganizationalUnitActions.disableOrganizationalUnit),
        switchMap(({ idOgUnit }) => {
          return organizationalUnitsService
            .disableOrganizationalUnit(idOgUnit)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                this.store.dispatch(
                  organizationalUnitFeedActions.getOrganizationalUnitFeed()
                );
                return toggleStateOrganizationalUnitActions.disableOrganizationalUnitSuccess(
                  {
                    organizationalUnit,
                  }
                );
              }),
              catchError(() => {
                return of(
                  toggleStateOrganizationalUnitActions.disableOrganizationalUnitleFailure()
                );
              })
            );
        })
      );
    }
  );
  enableOrganizationalUnitEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateOrganizationalUnitActions.enableOrganizationalUnit),
        switchMap(({ idOgUnit }) => {
          return organizationalUnitsService
            .enableOrganizationalUnit(idOgUnit)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                this.store.dispatch(
                  organizationalUnitFeedActions.getOrganizationalUnitFeed()
                );
                return toggleStateOrganizationalUnitActions.enableOrganizationalUnitSuccess(
                  {
                    organizationalUnit,
                  }
                );
              }),
              catchError(() => {
                return of(
                  toggleStateOrganizationalUnitActions.enableOrganizationalUnitleFailure()
                );
              })
            );
        })
      );
    }
  );

  getCareersEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      careersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateOrganizationalUnitActions.getCareer),
        switchMap(({ id }) => {
          return careersService
            .getCareersByOrgUnit(id)
            .pipe(
              map((careers: string[]) => {
                if (careers?.length > 0) {
                  this.dialog.open(AssociatedElementsModalComponent, {
                    data: {
                      title: 'Deshabilitar Unidad Organizacional',
                      subtitleAbove:
                        'No puedes dar de baja esta Unidad Organizacional debido a que está vinculada con la/s siguientes carreras.',
                      subtitleBellow:
                        'Desvincula la Unidad Organizacional de estas carreras para poder deshabilitarla.',
                      subtitleQuestion:
                        '¿Deseas visitar la sección de Carreras?',
                      elementsGroup: careers,
                      redirect: 'carreras',
                    },
                  });
                }
                return toggleStateOrganizationalUnitActions.getCareerSuccess({careers})
              }),
              catchError(() => {
                return of(
                  toggleStateOrganizationalUnitActions.getCareerFailure()
                );
              })
            );
        })
      );
    },
    {dispatch:true}
  );
}
