import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

export const toggleStateOrganizationalUnitActions = createActionGroup({
  source: 'toggle state organizational unit',
  events: {
    'Disable Organizational Unit': props<{idOgUnit: number;}>(),
    'Disable Organizational Unit success': props<{organizationalUnit: OrganizationalUnitInterface;}>(),
    'Disable Organizational Unitle failure': emptyProps(),

    'Get Career': props<{ id: number }>(),
    'Get Career success': props<{careers: string[]}>(),
    'Get Career failure': emptyProps(),

    'Enable Organizational Unit': props<{
      idOgUnit: number;
    }>(),
    'Enable Organizational Unit success': props<{
      organizationalUnit: OrganizationalUnitInterface;
    }>(),
    'Enable Organizational Unitle failure': emptyProps(),
  },
});
