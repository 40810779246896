import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';

export const updateTypeProductsAction = createActionGroup({
  source: 'update type products',
  events: {
    'Get Type Products Details': props<{ id: number }>(),
    'Get Type Products Details success': props<{typeProducts: TypeProducts}>(),
    'Get Type Products Details failure': emptyProps(),

    'Update Type Products': props<{ id: number, request: TypeProducts }>(),
    'Update Type Products success': props<{typeProducts: TypeProducts}>(),
    'Update Type Products failure': emptyProps(),
  },
});
