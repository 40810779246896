import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';

export const toggleStateTypeProductsActions = createActionGroup({
  source: 'toggle state type products',
  events: {
    'Disable Type Products': props<{id: number}>(),
    'Disable Type Products success': props<{typeProducts: TypeProducts}>(),
    'Disable Type Products failure': emptyProps(),

    'Get Products': props<{ id: number }>(),
    'Get Products success': props<{products: string[]}>(),
    'Get Products failure': emptyProps(),

    'Enable Type Products': props<{id: number}>(),
    'Enable Type Products success': props<{typeProducts: TypeProducts}>(),
    'Enable Type Products failure': emptyProps(),
  },
});
