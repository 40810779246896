import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';

export const typeProductsAction = createActionGroup({
  source: 'type products',
  events: {
    'Get Type Products feed': emptyProps(),
    'Get TypeProducts feed success': props<{
      typeProducts: TypeProducts[];
    }>(),
    'Get Type Products feed failure': emptyProps(),
  },
});
