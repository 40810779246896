import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarSuccess } from "src/app/shared/components/snackbar/snackbar-success.component";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { ProductServices } from "src/app/pages/product/product.service";
import { createProductsAction } from "../../actions/products/createProduct.action";
import { productsAction } from "../../actions/products/products.action";
import { ProductsFilterInterface } from "src/app/pages/product/types/productsFilter.interface";

@Injectable()
export class createProductsEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ){}

  createProducts$ = createEffect(
    (
      actions$ = inject(Actions),
      getProductsService = inject(ProductServices)
    ) => {
      return actions$.pipe(
        ofType(createProductsAction.createProducts),
        switchMap(({request}) => {
          return getProductsService.createProduct(request).pipe(
            map((products: any) => {
              this.store.dispatch(
                productsAction.getProductsFeed( {
                  filters: <ProductsFilterInterface>{
                    page: 0,
                    limit: 10,
                    column: 'updatedAt',
                    order: 'desc',
                  },
                })
              );
              this._snackBar.openFromComponent(SnackBarSuccess, {
                data: {
                  message: 'Producto creado exitosamente',
                  subtitle:
                    'Podés realizar modificaciones o deshabilitarlo cuando necesites',
                },
                panelClass: ['success-snackbar'],
                duration: 3000,
              });
              return createProductsAction.createProductsSuccess({products});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ?  errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                createProductsAction.createProductsFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
