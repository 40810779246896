import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { OrganizationalUnitFormInterface } from 'src/app/shared/types/organizationalUnitForm.interface';

export const updateOrganizationalUnitActions = createActionGroup({
  source: 'update organizational unit',
  events: {
    'Get Organizational Unit': props<{ idOgUnit: number }>(),
    'Get Organizational Unit success': props<{
      organizationalUnit: OrganizationalUnitInterface;
    }>(),
    'Get Organizational Unit failure': emptyProps(),

    'Update Organizational Unit': props<{
      request: OrganizationalUnitFormInterface;
      idOgUnit: number;
    }>(),
    'Update Organizational Unit success': props<{
      organizationalUnit: OrganizationalUnitInterface;
    }>(),
    'Update Organizational Unit failure': emptyProps(),
  },
});
