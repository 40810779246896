import { createFeature, createReducer, on } from '@ngrx/store';
import { updateTypeProductsAction } from '../../actions/type-products/updateTypeProducts.action';


export interface UpdateTypeProductsState {
  isLoading: boolean;
  isSubmittingTypeProductsUpdate: boolean;
  error: any;
  typeProductsStatus:any;
}

export interface TypeProducts {
  name: string;
  description: string;
  active: boolean;
}

export const initialTypeProductsState: UpdateTypeProductsState = {
  isLoading: false,
  error: false,
  isSubmittingTypeProductsUpdate: false,
  typeProductsStatus: null,
};

const updateTypeProductsReducer = createFeature({
  name: 'updateTypeProducts',
  reducer: createReducer(
    initialTypeProductsState,
    on(updateTypeProductsAction.getTypeProductsDetails, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(updateTypeProductsAction.getTypeProductsDetailsSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      typeProductsStatus: action.typeProducts.active
    })),
    on(updateTypeProductsAction.getTypeProductsDetailsFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(updateTypeProductsAction.updateTypeProducts, (state) => ({
      ...state,
      isSubmittingTypeProductsUpdate: false,
    })),
    on(updateTypeProductsAction.updateTypeProductsSuccess, (state,action) => ({
      ...state,
      typeProductsStatus: action.typeProducts.active,
      isSubmittingTypeProductsUpdate: true,
    })),
    on(updateTypeProductsAction.updateTypeProductsFailure, (state, action) => ({
      ...state,
      isSubmittingTypeProductsUpdate: false,
      validationErrors: '',
    })),
  ),
});

export const {
  name: updateTypeProductKey,
  reducer: updateTypeProductReducer,
  selectError,
  selectTypeProductsStatus,
  selectIsSubmittingTypeProductsUpdate,
} = updateTypeProductsReducer;
