import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

export const organizationalUnitActions = createActionGroup({
  source: 'organizationalUnit',
  events: {
    'Get Organizational Unit': props<{ idOgUnit: number }>(),
    'Get Organizational Unit success': props<{
      organizationalUnit: OrganizationalUnitInterface;
    }>(),
    'Get Organizational Unit failure': emptyProps(),
  },
});
