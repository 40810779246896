import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { carrersAction } from "../../actions/careers/careers.action";
import { catchError, map, of, switchMap } from "rxjs";
import { CareersService } from "src/app/pages/careers/careers.service";
import { Careers } from "../../reducers/careers.reducer";

@Injectable()
export class getCareersEffect {
  constructor(private readonly actions$: Actions) {}

  getCareersEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getCareersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(carrersAction.getCareersFeed),
        switchMap(() => {
          return getCareersService.getCareers().pipe(            
            map((careers: Careers[]) => {
              return carrersAction.getCareersFeedSuccess({careers});
            }),
            catchError(() => {
              return of(
                carrersAction.getCareersFeedFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
