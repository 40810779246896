import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { OrganizationalUnitsService } from '../organizational-unit.service';
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';
import { DisableElementModalComponent } from 'src/app/shared/components/disable-element-modal/disable-element-modal.component';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { organizationalUnitFeedActions } from '../../../state/actions/organizational-units/organizationalUnitFeed.actions';
import {
  selectIsLoading,
  selectOrganizationalUnitInactives,
  selectOrganizationalUnitbtn,
} from '../../../state/reducers/organizational-units/organizationalUnitFeed.reducers';
import { toggleStateOrganizationalUnitActions } from 'src/app/state/actions/organizational-units/toggleStateOrganizationalUnit.actions';
import { selectEnableCareer } from 'src/app/state/reducers/organizational-units/toggleStateOrgnizationalUnit.reducers';

@Component({
  selector: 'organizationalUnit-table',
  styleUrls: ['organizationalUnit-table.component.sass'],
  templateUrl: 'organizationalUnit-table.component.html',
  standalone: true,
  imports: [
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    EmptyStateComponent,
  ],
})
export class OrganizationalUnitTable implements OnInit {
  btnOrgUnit$: any;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private organizationalUnitsService: OrganizationalUnitsService,
    private _snackBar: MatSnackBar,
    private store: Store
  ) {
    this.btnOrgUnit$ = this.store.select(selectOrganizationalUnitbtn);
  }

  @ViewChild(MatSort)
  sort!: MatSort;

  iconColumn: string[] = ['Acciones'];

  isChecked: boolean = false;

  mainColumnTitle: string = 'idOgUnit';
  displayedColumns: string[] = ['idOgUnit', 'name', 'Acciones'];
  columnsToShow: string[] = ['idOgUnit', 'name', 'editButtons'];
  dataSource = this.organizationalUnitsService.dataSources;

  selectedRowIndex: number = -1;
  dataRes: any;
  disabledUnits: boolean = false;
  disabledBtn: boolean = true;
  enableCareers: string[];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  checkboxStateMap: { [key: number]: boolean } = {};
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  timeTooltip: string = '500';

  openOrganizationalUnitDetail(idOgUnit: number) {
    this.organizationalUnitsService.openOrganizationalUnitDetail(idOgUnit)
  }

  async openModifyOrganizationalUnit(idOgUnit: number) {
    this.organizationalUnitsService.disabledEdit = true;
    this.organizationalUnitsService.setEditForm(false);
    this.organizationalUnitsService.openModifyOrganizationalUnit(idOgUnit);
  }

  setSelectedRow(element: any): void {
    this.selectedRowIndex = this.dataSource.data.indexOf(element);
  }

  async deleteOrganizationalUnit(idOgUnit: number) {
    await this.organizationalUnitsService.validatedCareers(idOgUnit)

    this.store.select(selectEnableCareer).subscribe((careers) => {
      this.enableCareers = careers
    });

    if(this.enableCareers?.length === 0){
      const dialogRef = this.dialog.open(DisableElementModalComponent, {
        data: {
          title: 'Deshabilitar Unidad Organizacional',
          subtitle: 'Estás por dar de baja una unidad organizacional.',
          subtitleQuestion: '¿Deseas continuar?',
          acceptButton: 'Confirmar',
          cancelButton: 'Cancelar',
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'accept') {
        try {
          await this.store.dispatch(
            toggleStateOrganizationalUnitActions.disableOrganizationalUnit({
              idOgUnit,
            })
          );
          await this.organizationalUnitsService.activeOrganizationalUnits();
          this._snackBar.openFromComponent(SnackBarSuccess, {
            data: {
              message: 'Unidad organizacional deshabilitada exitosamente',
            },
            panelClass: ['success-snackbar'],
            duration: 3000,
          });
        } catch (error) {
          this._snackBar.openFromComponent(SnackBarWarning, {
            data: {
              message: 'Ocurrió un error',
              subtitle: 'Por favor intenta nuevamente más tarde',
            },
            duration: 3000,
            panelClass: ['warning-snackbar'],
          });
        }
      }
      console.log(`Dialog result: ${result}`);
    });
    }
    this.enableCareers = []
  }

  async enabledOgUnit(idOgUnit: number) {
    const dialogRef = this.dialog.open(DisableElementModalComponent, {
      data: {
        title: 'Habilitar Unidad Organizacional',
        subtitle: 'Estás por habilitar una Unidad Organizacional.',
        subtitleQuestion: '¿Deseas continuar?',
        acceptButton: 'Confirmar',
        cancelButton: 'Cancelar',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'accept') {
        try {
          await this.store.dispatch(
            toggleStateOrganizationalUnitActions.enableOrganizationalUnit({
              idOgUnit,
            })
          );
          this.store.select(selectOrganizationalUnitInactives).subscribe((orgUnitsInActive)=>{
            if (!orgUnitsInActive.length) {
              this.disabledBtn = true
              this.disabledUnits = false
              this.organizationalUnitsService.activeOrganizationalUnits();
            }
          })

          this._snackBar.openFromComponent(SnackBarSuccess, {
            data: {
              message: 'La unidad organizacional se habilitó correctamente',
            },
            panelClass: ['success-snackbar'],
            duration: 3000,
          });
        } catch (error) {
          this._snackBar.openFromComponent(SnackBarWarning, {
            data: {
              message: 'Ocurrió un error',
              subtitle: 'Por favor intenta nuevamente más tarde',
            },
            duration: 3000,
            panelClass: ['warning-snackbar'],
          });
        }
      }
    });
  }

  async enabledOrDisabled() {
    if (this.disabledUnits === true) {
      this.organizationalUnitsService.activeOrganizationalUnits();
      this.updateDisabledUnitsData(false);
    } else {
      this.updateDisabledUnitsData(true);
      this.organizationalUnitsService.inactiveOrganizationalUnits();
    }
  }

  ngOnChanges() {
    this.displayedColumns = [...this.displayedColumns, ...this.iconColumn];
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async ngOnInit() {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch(organizationalUnitFeedActions.getOrganizationalUnitFeed());
        this.store.select(selectIsLoading).subscribe(loading => {
          if (!loading) resolve();
        });
      });

      this.organizationalUnitsService.disabledUnitsChanged.subscribe(
        (value: boolean) => {
          this.disabledUnits = value;
        }
      );
      if (this.disabledUnits === true) {
        this.organizationalUnitsService.inactiveOrganizationalUnits();
      } else {
        this.organizationalUnitsService.activeOrganizationalUnits();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  updateDisabledUnitsData(value: boolean) {
    this.organizationalUnitsService.updateBooleanData(value);
  }
}
