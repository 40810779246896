import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { Products } from '../../reducers/products/updateProducts.reducer';

export const productsDetailsAction = createActionGroup({
  source: 'productsDetails',
  events: {
    'Get Products Details': props<{ id: number }>(),
    'Get Products Details success': props<{
      products: Products;
    }>(),
    'Get Products Details failure': emptyProps(),
  },
});
