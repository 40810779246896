import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

import { selectOrganizationalUnitFeedData } from '../../reducers/organizational-units/organizationalUnitFeed.reducers';
import { selectCountries } from '../../reducers/countries/countries.reducers';
import { CountryInterface } from 'src/app/interfaces/country.interface';
import { PurchaseAnalystsService } from 'src/app/pages/purchase-analyst/purchase-analyst.service';
import { updatePurchaseAnalystActions } from '../../actions/purchase-analyst/updatePurchaseAnalyst.actions';
import { PurchaseAnalystInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalyst.interface';
import { purchaseAnalystsFeedActions } from '../../actions/purchase-analyst/purchaseAnalystFeed.actions';
import { PurchaseAnalystsFilterInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystFilter.interface';
import { purchaseAnalystActions } from '../../actions/purchase-analyst/purchaseAnalyst.actions';
import { PurchaseAnalystModal } from 'src/app/pages/purchase-analyst/purchase-analyst-modal/purchase-analyst-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';


@Injectable()
export class updatePurchaseAnalystEffect {
  organizationalUnits: OrganizationalUnitInterface[];
  countries: CountryInterface[];

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
  ) {}


  getPurchaseAnalystEffect$ = createEffect(
    (actions$ = inject(Actions), purchaseAnalystsService = inject(PurchaseAnalystsService)) => {
      return actions$.pipe(
        ofType(updatePurchaseAnalystActions.getPurchaseAnalyst),
        switchMap(({ uuid }) => {
          this.store
            .select(selectOrganizationalUnitFeedData)
            .subscribe(
              (organizationalUnits) =>
                (this.organizationalUnits = organizationalUnits)
            );
          this.store
            .select(selectCountries)
            .subscribe(
              (countries) =>
                (this.countries = countries)
            );
          return purchaseAnalystsService.getPurchaseAnalyst(uuid).pipe(
            map((purchaseAnalyst: PurchaseAnalystInterface) => {
              const dialogRef = this.dialog.open(PurchaseAnalystModal, {
                data: {
                  uuid: uuid,
                  title: 'Editar administrador',
                  name: `${purchaseAnalyst.name}`,
                  lastname: `${purchaseAnalyst.lastname}`,
                  countryPhoneCode: `${purchaseAnalyst.countryPhoneCode ? purchaseAnalyst.countryPhoneCode : ''}`,
                  phoneNumber: purchaseAnalyst.phoneNumber,
                  email: `${purchaseAnalyst.email}`,
                  organizationalUnits: this.organizationalUnits,
                  countries: this.countries,
                  countryName: `${
                    purchaseAnalyst.country ? purchaseAnalyst.country.name : ''
                  }`,
                  organizationalUnitName: purchaseAnalyst.orgUnitId.map((unit) => {return unit.name}),
                  acceptButton: 'Guardar',
                  cancelButton: 'Cancelar',
                  inputActive: true,
                  modify: true,
                  active: purchaseAnalyst.active,
                },
              });
              return updatePurchaseAnalystActions.getPurchaseAnalystSuccess({
                purchaseAnalyst,
              });
            }),
            catchError(() => {
              return of(updatePurchaseAnalystActions.getPurchaseAnalystFailure());
            })
          );
        })
      );
    }
  );

  updatePurchaseAnalystEffect$ = createEffect(
    (actions$ = inject(Actions), purchaseAnalystsService = inject(PurchaseAnalystsService)) => {
      return actions$.pipe(
        ofType(updatePurchaseAnalystActions.updatePurchaseAnalyst),
        switchMap(({ request, uuid, isActive }) => {
          return purchaseAnalystsService.updatePurchaseAnalyst(request, uuid).pipe(
            map((purchaseAnalyst: PurchaseAnalystInterface) => {
              this.store.dispatch(purchaseAnalystsFeedActions.getPurchaseAnalystsFeed({
                filters: <PurchaseAnalystsFilterInterface>{
                  actives: isActive,
                  page: 0,
                  limit: 10,
                  column: 'updatedAt',
                  order: 'desc',
                },
              }));
              this.dialog.closeAll()
              this.store.dispatch(
                purchaseAnalystActions.getPurchaseAnalyst({
                  uuid: uuid,
                })
              );
              this._snackBar.openFromComponent(SnackBarSuccess, {
                data: {
                  message: 'Administrador modificado exitosamente',
                  subtitle:
                    'Podés realizar modificaciones cuando necesites',
                },
                panelClass: ['success-snackbar'],
                duration: 3000,
              });
              return updatePurchaseAnalystActions.updatePurchaseAnalystSuccess({ purchaseAnalyst });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor ingresa nuevamente los datos',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                updatePurchaseAnalystActions.updatePurchaseAnalystFailure()
              );
            })
          );
        })
      );
    }
  );
}
