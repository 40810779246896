import { Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalityInterface } from 'src/app/interfaces/locality.interface';
import { CountryInterface } from 'src/app/interfaces/country.interface';
import { ProvinceInterface } from 'src/app/interfaces/province.interface';

@Injectable({
  providedIn: 'root'
})



export class LocalityServices {
  constructor(
    private http: HttpClient
  ) {}

  getCountries(): Observable<CountryInterface[]> {
    const fullUrl = `${environment.apiUrl}/country`;
    return this.http.get<CountryInterface[]>(fullUrl);
  }

  getLocalities(): Observable<LocalityInterface[]> {
    // const fullUrl = `${environment.apiUrl}/country`;
    // return this.http.get<ProvinceInterface[]>(fullUrl);
    const provinces: LocalityInterface[] = [
      {
        cod: "ON",
        name: "Ontario",
      },
      {
        cod: "BC",
        name: "British Columbia",
      },
      {
        cod: "QC",
        name: "Quebec",
      },
    ];
    return of(provinces);
  }
  getProvinces(): Observable<ProvinceInterface[]> {
    // const fullUrl = `${environment.apiUrl}/country`;
    // return this.http.get<ProvinceInterface[]>(fullUrl);
    const provinces: ProvinceInterface[] = [
      {
        cod: "ON",
        name: "Ontario",
      },
      {
        cod: "BC",
        name: "British Columbia",
      },
      {
        cod: "QC",
        name: "Quebec",
      },
    ];
    return of(provinces);
  }

}
