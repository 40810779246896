import axios from 'axios';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { isLoggedIn } from 'src/app/state/selectors/auth.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {

  isLogged = false;

  constructor( private store: Store<AppState> ) { }

  ngOnInit() {
    this.store.select(isLoggedIn).subscribe(authState => {
      this.isLogged = authState;
    });
  }
}

