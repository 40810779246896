import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { TypeProductsService } from "src/app/pages/type-products/type-product.service";
import { typeProductsDetailsAction } from "../../actions/type-products/typeProductsDetails.action";
import { TypeProductModal } from "src/app/pages/type-products/type-products-modal/type-product-modal.component";

@Injectable()
export class getTypeProductsDetailsEffect {
  constructor(private readonly actions$: Actions, public dialog: MatDialog) {}

  getTypeProductsDetailsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getTypeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(typeProductsDetailsAction.getTypeProductsDetails),
        switchMap(({id}) => {
          return getTypeProductsService.getTypeProductsDetails(id).pipe(
            map((typeProducts: any) => {
              this.dialog.open(TypeProductModal, {
                data: {
                  id: `${typeProducts.id}`,
                  title: `Tipo de Producto - Nro ${typeProducts.id}`,
                  name: `${typeProducts.name}`,
                  description: `${typeProducts.description}`,
                  cancelButton: 'Volver',
                  code: `${typeProducts.cod}`,
                  saveButton: 'Guardar',
                  inputActive: false,
                },
              })
              return typeProductsDetailsAction.getTypeProductsDetailsSuccess({typeProducts});
            }),
            catchError(() => {
              return of(
                typeProductsDetailsAction.getTypeProductsDetailsFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
