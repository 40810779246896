import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { isEqual } from 'lodash';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { Store } from '@ngrx/store';
import { PurchaseAnalystsService } from '../purchase-analyst.service';
import { PurchaseAnalystFormInterface } from '../types/purchaseAnalystForm.interface';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';
import { Observable, map, startWith } from 'rxjs';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { selectOrganizationalUnitActives } from 'src/app/state/reducers/organizational-units/organizationalUnitFeed.reducers';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { updatePurchaseAnalystActions } from 'src/app/state/actions/purchase-analyst/updatePurchaseAnalyst.actions';
import { purchaseAnalystActions } from 'src/app/state/actions/purchase-analyst/purchaseAnalyst.actions';
import { createPurchaseAnalystActions } from 'src/app/state/actions/purchase-analyst/createPurchaseAnalyst.actions';
import { selectIsSubmittingCreatePurchaseAnalyst } from 'src/app/state/reducers/purchase-analyst/createPurchaseAnalyst.reducers';
import { selectIsSubmittingUpdatePurchaseAnalyst } from 'src/app/state/reducers/purchase-analyst/updatePurchaseAnalyst.reducers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { PhoneNumberComponent } from 'src/app/shared/components/phone-number/phone-number.component';
import { PurchaseAnalystsFilterInterface } from '../types/purchaseAnalystFilter.interface';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './purchase-analyst-modal.component.html',
  standalone: true,
  styleUrls: ['./purchase-analyst-modal.component.sass'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    PhoneNumberComponent,
    NgxMatInputTelComponent,
  ],
})
export class PurchaseAnalystModal implements OnInit  {
  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;
  purchaseAnalystForm: FormGroup;

  title: string;
  uuid: string;
  name: string;
  lastname: string;
  phoneNumber: string;
  email: string;
  organizationalUnits: any = [];
  countries: any = [];
  purchaseAnalystActive: string;
  isAcceptButtonEnabled: boolean;
  inputActive: boolean;
  acceptButton: string;
  cancelButton: string;
  editFromCard: boolean;
  modify: boolean;
  formModified: boolean = false;
  copyOriginalValue: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  unitSelected: string[] = [];
  allOrganizationalUnit: any[] = [];
  unitCtrl = new FormControl('');
  filteredUnit: Observable<string[]>;
  selectionFromDropdown = false;
  announcer = inject(LiveAnnouncer);

  errorCode?: any = null;

  get phone() { return this.purchaseAnalystForm.get('phoneNumber'); }
  @ViewChild('phoneInput') phoneInput: NgxMatInputTelComponent;
  @ViewChild('unitInput', { static: true }) unitInput!: ElementRef<HTMLInputElement>;
  @ViewChild('unitInput') unitsInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PurchaseAnalystModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private purchaseAnalystsService: PurchaseAnalystsService,
    private fb: FormBuilder,
    private store: Store,
    private _snackBar: MatSnackBar,
  ) {
    // Form Control for validation
    this.purchaseAnalystForm = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: [
        {value: '', disabled: false},
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ],
      ],
      organizationalUnitName: [''],
      countryName: ['AR'],
      countryPhoneCode: ['54', [Validators.maxLength(5)]],
      countryPhoneName: ['ar', [Validators.maxLength(5)]],
      phoneNumber: ['', [Validators.minLength(8)]],
    });
  }

  ngOnInit() {
    this.store.select(selectOrganizationalUnitActives).subscribe(units => {
      this.allOrganizationalUnit = units?.map((units)=> units.name)
    });

    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.editFromCard = this.data.editFromCard || false;
    this.modify = this.data.modify || false;
    this.uuid = this.data.uuid || '';
    this.organizationalUnits = this.data.organizationalUnits || [];
    this.countries = this.data.countries.map(country=> {return country.name}) || [];
    this.purchaseAnalystActive = this.data.active?.toString();

    this.purchaseAnalystForm.patchValue({
      name: this.data.name || '',
      lastname: this.data.lastname || '',
      email: this.data.email || '',
      countryPhoneCode: this.data.phoneNumber != null ? this.data.phoneNumber.countryCode : '54',
      countryPhoneName: this.data.phoneNumber != null ? this.data.phoneNumber.countryName : 'ar',
      phoneNumber: this.data.phoneNumber != null ? this.data.phoneNumber.number : '',
      organizationalUnitName: this.data.organizationalUnitName || [],
      countryName: this.data.countryName || '',
    });

    if (this.modify) {
      const { email, ...rest } = this.purchaseAnalystForm.value;
      this.copyOriginalValue = { ...rest };
      const emailControl = this.purchaseAnalystForm.get('email');
      this.unitSelected = this.data.organizationalUnitName.map((unit) => {return unit});
      emailControl.disable();
      this.purchaseAnalystForm.valueChanges.subscribe(() => {
        this.formModified = !isEqual(this.purchaseAnalystForm.value, this.copyOriginalValue);
      });
    }

    this.purchaseAnalystForm.get('phoneNumber')!.valueChanges.subscribe((value) => {
      this.purchaseAnalystForm.get('phoneNumber')!.setValue(value.toString(), { emitEvent: false });
    });

    this.purchaseAnalystForm.valueChanges.subscribe(() => {
      this.updateAcceptButtonState();
    });

    this.filteredUnit = this.unitCtrl.valueChanges.pipe(
      startWith(null),
      map((unit: string | null) => (unit ? this._filter(unit) : this.allOrganizationalUnit.slice()))
    );

    this.purchaseAnalystForm.valueChanges.subscribe(() => {
      this.formModified = !isEqual(this.purchaseAnalystForm.value, this.copyOriginalValue);
    });

  }

  onCancelButtonClick(): void {
    if (this.inputActive && this.isAcceptButtonEnabled) {
      this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent);
      this.notSavedDialogRef.afterClosed().subscribe((result) => {
        if (result === 'accept') {
          this.dialog.closeAll();
        }
      });
    } else if (this.inputActive && this.purchaseAnalystsService.getEditForm()) {
      this.dialogRef.close();
      this.purchaseAnalystsService.setEditForm(false);
    } else {
      this.purchaseAnalystsService.disabledEdit = false;
      this.dialogRef.close();
    }
  }

  async onCreateButtonClick(modify: boolean, active?: boolean) {
    const dataPurchaseAnalyst: PurchaseAnalystFormInterface = {
      name: this.purchaseAnalystForm.value.name,
      lastname: this.purchaseAnalystForm.value.lastname,
      email: this.purchaseAnalystForm.value.email,
      countryPhoneCode: this.purchaseAnalystForm.value.countryPhoneCode ? this.purchaseAnalystForm.value.countryPhoneCode : null,
      phoneNumber: this.purchaseAnalystForm.value.phoneNumber ? {
        number: this.purchaseAnalystForm.value.phoneNumber,
        countryName: this.purchaseAnalystForm.value.countryPhoneName,
        countryCode: this.purchaseAnalystForm.value.countryPhoneCode,
      } : null,
      orgUnitId: this.purchaseAnalystForm.value.organizationalUnitName,
      password: 'Ucc+2024',
      roles: ['admin'],
      country: this.purchaseAnalystForm.value.countryName ? this.purchaseAnalystForm.value.countryName : null,
      active: active ? true : undefined,
    };

    if (modify) {
      try {
        this.store.dispatch(
          updatePurchaseAnalystActions.updatePurchaseAnalyst({
            request: dataPurchaseAnalyst,
            uuid: this.data.uuid,
            isActive: !this.purchaseAnalystsService.disabledPurchaseAnalysts,
            filters: <PurchaseAnalystsFilterInterface>{
              actives: this.data.active == 'true' ? true : false,
              page: 0,
              limit: 10,
              column: 'updatedAt',
              order: 'desc',
            },
          })
        );
        if (!dataPurchaseAnalyst.active) {
          this.store.dispatch(
            purchaseAnalystActions.getPurchaseAnalyst({
              uuid: this.uuid,
            })
          );
        }
        this.purchaseAnalystsService.setEditForm(false);
        this.store.select(selectIsSubmittingUpdatePurchaseAnalyst).subscribe(success => {
          if (success) {
            this._snackBar.openFromComponent(SnackBarSuccess, {
              data: {
                message: active? `El administrador ${this.purchaseAnalystForm.value.name} se modificó y habilitó correctamente` : `El administrador ${this.purchaseAnalystForm.value.name} se modificó correctamente`,
              },
              panelClass: ['success-snackbar'],
              duration: 3000,
            });
            this.dialogRef.close()
          }
        })
      } catch (error: any) {
        this.errorCode = error;
      }
    } else {
      this.store.dispatch(
        createPurchaseAnalystActions.createPurchaseAnalyst({
          request: dataPurchaseAnalyst
        })
      );
      this.store.select(selectIsSubmittingCreatePurchaseAnalyst).subscribe(success => {
        if (success) {
          this.dialogRef.close()
        }
      })
    }
  }

  editPurchaseAnalyst(uuid: string): void {
    this.purchaseAnalystsService.setEditForm(true);
    this.store.dispatch(updatePurchaseAnalystActions.getPurchaseAnalyst({ uuid }));
    this.dialogRef.close();
  }

   // Organizational Unit mat-chip Input
  addOnBlur(): void {
    const value = this.unitCtrl.value?.trim();
    setTimeout(() => {
      const existUnit = this.allOrganizationalUnit.find((unit)=> unit == value?.toLowerCase());
      if (value && existUnit) {
        this.unitSelected.push(value);

        // Organizational Unit Control - Set value and validity
        const unitsArray = this.purchaseAnalystForm.get('organizationalUnitName') as FormArray;
        unitsArray.setValue(this.unitSelected);
        unitsArray.updateValueAndValidity();

        // Remove selected author from the Organizational Unit list
        this.removeFromAllUnits(value);
        this.updateFilteredUnits();
      }

      // Clear the input value
      this.unitsInput.nativeElement.value = ''; // Clear input content
      this.unitCtrl.setValue(null);

      this.selectionFromDropdown = false;
    }, 200);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '')?.trim();
    const existUnit = this.allOrganizationalUnit.find((unit)=> unit == value?.toLowerCase());

    if (value && existUnit) {
      this.unitSelected.push(value);

      // Organizational Unit Control - Set value and validity
      const unitsArray = this.purchaseAnalystForm.get('organizationalUnitName') as FormArray;
      unitsArray.setValue(this.unitSelected);
      unitsArray.updateValueAndValidity();

      // Remove selected Organizational Unit from the author list
      this.removeFromAllUnits(value);
      this.updateFilteredUnits();
    }

    // Clear the input value
    event.chipInput!.clear();
    this.unitCtrl.setValue(null);
  }

  remove(unit: string): void {
    const index = this.unitSelected.indexOf(unit);

    if (index >= 0) {
      this.unitSelected.splice(index, 1); // Delete Organizational Unit
      this.allOrganizationalUnit.push(unit); // Add Organizational Unit to Organizational Unit list
      this.updateFilteredUnits();
      this.announcer.announce(`Removed ${unit}`);
    }

    // Organizational Unit Control - Set value and validity
    const unitsArray = this.purchaseAnalystForm.get('organizationalUnitName') as FormArray;
    unitsArray.setValue(this.unitSelected);
    unitsArray.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectionFromDropdown = true;
    this.unitSelected.push(event.option.viewValue);

    // Organizational Unit Control - Set value and validity
    const unitsArray = this.purchaseAnalystForm.get('organizationalUnitName') as FormArray;
    unitsArray.setValue(this.unitSelected);
    unitsArray.updateValueAndValidity();

    this.removeFromAllUnits(event.option.viewValue);
    this.updateFilteredUnits();

    // Clear the input value
    this.unitsInput.nativeElement.value = '';
    this.unitCtrl.setValue(null);
  }

  private _filter(value: any): string[] {
    const filterValue = value?.toLowerCase();

    return this.allOrganizationalUnit.filter(unit => unit?.toLowerCase().includes(filterValue));
  }

  private removeFromAllUnits(autor: string): void {
    const index = this.allOrganizationalUnit.indexOf(autor);
    if (index >= 0) {
      this.allOrganizationalUnit.splice(index, 1);
    }
  }

  private updateFilteredUnits(): void {
    this.allOrganizationalUnit.sort((a, b) => a.localeCompare(b));

    this.filteredUnit = this.unitCtrl.valueChanges.pipe(
      startWith(null),
      map((unit: string | null) => (unit ? this._filter(unit) : this.allOrganizationalUnit.slice()))
    );
  }




  charactersValidationLetters(event: any, formControlName: string): void {
    const value = event.target.value;

    const newValue = value.replace(/[^a-zA-Z0-9,´'`"\s]/g, '');
    this.purchaseAnalystForm.get(formControlName).setValue(newValue);
  }

  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled = this.purchaseAnalystForm.valid;
  }

  updateDisabledUnitsData(value: boolean) {
    this.purchaseAnalystsService.updateBooleanData(value);
  }

  disabledBtnUnits(value: boolean) {
    this.purchaseAnalystsService.updateDisabledBtnPurchaseAnalysts(value);
  }
}
