import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PurchaseAnalystInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalyst.interface";

export const purchaseAnalystActions = createActionGroup({
  source: 'purchase analyst',
  events: {
    'Get purchase analyst': props<{ uuid: string }>(),
    'Get purchase analyst success': props<{
      purchaseAnalyst: PurchaseAnalystInterface;
    }>(),
    'Get purchase analyst failure': emptyProps(),
  },
});
