import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { OrganizationalUnitModal } from 'src/app/pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-header-table',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    OrganizationalUnitModal,
  ],
  templateUrl: './organizational-unit-header.component.html',
  styleUrls: ['./organizational-unit-header.component.sass'],
})

export class HeaderTableComponent {
  title: string = 'Unidades Organizacionales';
  chipText: string = 'Nueva Unidad Organizacional';
  searchText: string = '';

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  async openCreateOrganizationalUnit() {
    try {
      const dialogRef = this.dialog.open(OrganizationalUnitModal, {
        data: {
          title: 'Crear Unidad Organizacional',
          name: '',
          description: '',
          acceptButton: 'Crear',
          cancelButton: 'Cancelar',
          inputActive: true,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    } catch (error) {
      this._snackBar.openFromComponent(SnackBarWarning, {
        data: {
          message: 'Ocurrió un error',
          subtitle: 'Por favor ingresa nuevamente los datos',
          duration: 3000,
          panelClass: ['warning-snackbar'],
        },
      });
    }
  }
}
