import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { CareersService } from "src/app/pages/careers/careers.service";
import { carrerDetailsAction } from "../../actions/careers/careerDetails.action";
import { CareersModal } from "src/app/pages/careers/careers-modal/careers-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class getCareerEffect {
  constructor(private readonly actions$: Actions, public dialog: MatDialog) {}

  getCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getCareersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(carrerDetailsAction.getCareerDetails),
        switchMap(({idCar}) => {
          return getCareersService.getCareerDetails(idCar).pipe(
            map((career: any) => {
              this.dialog.open(CareersModal, {
                data: {
                  title: `Carrera - Nro ${career.idCar}`,
                  name: `${career.name}`,
                  description: `${career.description}`,
                  code: `${career.cod}`,
                  category: `${career.career_category.name}`,
                  level: `${career.career_level.name}`,
                  idCar: career.idCar,
                  orgUnitId: career.orgUnitId.idOgUnit,
                  organizationalUnitName: `${career.orgUnitId.name}`,
                  cancelButton: 'Volver',
                  inputActive: false,
                },
              })
              return carrerDetailsAction.getCareersDetailsSuccess({career});
            }),
            catchError(() => {
              return of(
                carrerDetailsAction.getCareersDetailsFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
