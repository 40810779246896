import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientInterface } from './types/client.interface';
import { ClientFormInterface } from './types/clientForm.interface';
import { ClientsResponseInterface } from './types/clientsResponse.interface';
import { ClientsFilterInterface } from './types/clientsFilter.interface';
import { toggleStateClientActions } from 'src/app/state/actions/clients/toggleStateClient.actions';
import { selectIsLoadingStatus } from 'src/app/state/reducers/clients/toggleStateClient.reducers';
import { map } from 'rxjs/operators';
import { CountryInterface } from 'src/app/interfaces/country.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}

  private editForm = new BehaviorSubject<boolean>(false);
  disabledData: any;
  dataSources = new MatTableDataSource<any>();
  disabledEdit: boolean;

  disabledClientsChanged = new EventEmitter<boolean>();
  disabledClients: boolean = false;

  disabledBtnClientsChanged = new EventEmitter<boolean>();
  disabledBtnClients: boolean = true;

  updateBooleanData(value: boolean) {
    this.disabledClients = value;
    this.disabledClientsChanged.emit(this.disabledClients);
  }

  updateDisabledBtnClients(newValue: boolean) {
    this.disabledBtnClients = newValue;
    this.disabledBtnClientsChanged.emit(this.disabledBtnClients);
  }

  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }

  getClientsFeed(
    filters: ClientsFilterInterface
  ): Observable<ClientsResponseInterface> {
    const { actives, page, limit, column, order } = filters;
    const fullUrl = `${environment.apiUrl}/users/user-by-filter?actives=${actives}&page=${page}&limit=${limit}&column=${column}&order=${order}&rol=cliente`;
    return this.http.get<ClientsResponseInterface>(fullUrl);
  }

  getPurhcaseRequestByClient(uuid:string): Observable<string[]> {
    return this.http.get<{ id: string }[]>(`${environment.apiUrl}/users/purchase-request/${uuid}`)
      .pipe(
        map(purchaseRequests => purchaseRequests.map(request => `Solicitud de Compra ${request.id}`))
      );
  }

  async associatedPurchaseRequest(uuid: string): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch(toggleStateClientActions.getPurchaseRequest({uuid}));
        const subscription = this.store.select(selectIsLoadingStatus).subscribe({
          next: (loading) => {
            if (!loading) {
              resolve();
              subscription.unsubscribe();

            }
          },
          error: (err) => {
            reject(err);
            subscription.unsubscribe();
          },
        });
      });
    } catch (error) {
      this.store.dispatch(toggleStateClientActions.getPurchaseRequestFailure());
    }
  }

  getClient(
    uuid: string
  ): Observable<ClientInterface> {
    const fullUrl = `${environment.apiUrl}/users/${uuid}`;
    return this.http.get<ClientInterface>(fullUrl);
  }

  createClient(
    clientRequest: ClientFormInterface
  ): Observable<ClientInterface> {
    const fullUrl = environment.apiUrl + '/users/new-user';
    return this.http.post<ClientInterface>(fullUrl, clientRequest);
  }

  updateClient(
    clientRequest: ClientFormInterface,
    uuid: string
  ): Observable<ClientInterface> {
    const fullUrl = `${environment.apiUrl}/users/${uuid}`;
    return this.http.patch<ClientInterface>(
      fullUrl,
      clientRequest
    );
  }

  disableClient(
    uuid: string
  ): Observable<ClientInterface> {
    const fullUrl = `${environment.apiUrl}/users/disabled/${uuid}`;
    return this.http.patch<ClientInterface>(
      fullUrl,
      null
    );
  }


  enableClient(
    uuid: string
  ): Observable<ClientInterface> {
    const fullUrl = `${environment.apiUrl}/users/enabled/${uuid}`;
    return this.http.patch<ClientInterface>(
      fullUrl,
      null
    );
  }


  getCountries(): Observable<CountryInterface[]> {
    const fullUrl = `${environment.apiUrl}/country`;
    return this.http.get<CountryInterface[]>(fullUrl);
  }
}
