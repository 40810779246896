import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { toggleStateCareerActions } from '../../actions/careers/toggleStateCareer.actions';
import { CareersService } from 'src/app/pages/careers/careers.service';
import { carrersAction } from '../../actions/careers/careers.action';
import { Careers } from '../../reducers/careers.reducer';
import { AssociatedElementsModalComponent } from 'src/app/shared/components/associated-elements-modal/associated-elements-modal.component';
import { organizationalUnitActions } from '../../actions/organizational-units/organizationalUnit.actions';


@Injectable()
export class toggleStateCareerEffect {
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  disableCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      careersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateCareerActions.disableCareer),
        switchMap(({ idCar }) => {
          return careersService
            .disableCareer(idCar)
            .pipe(
              map((career: Careers) => {
                this.store.dispatch(
                  carrersAction.getCareersFeed()
                );
                return toggleStateCareerActions.disableCareerSuccess({career}
                );
              }),
              catchError(() => {
                return of(
                  toggleStateCareerActions.disableCareerFailure()
                );
              })
            );
        })
      );
    }
  );

  getOrganizationalUnitEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateCareerActions.getOrganizationalUnit),
        switchMap(({ idOgUnit }) => {
          return organizationalUnitsService
            .getOrganizationalUnit(idOgUnit)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                if (organizationalUnit.active == 'false') {
                  this.dialog.open(AssociatedElementsModalComponent, {
                    data: {
                      title: 'Habilitar Carrera',
                      subtitleAbove:
                        'No puedes habilitar esta carrera debido a que está vinculada con la siguiente Unidad Organizacional deshabilitada.',
                      subtitleBellow:
                        'Habilitá la Unidad Organizacional para conservarla, o vinculá la carrera con otra existente.',
                      subtitleQuestion:
                        '¿Deseas visitar la sección de Unidades Organizacionales?',
                      elementsGroup: [organizationalUnit.name],
                      redirect: 'unidades-organizacionales',
                    },
                  });
                }
                return toggleStateCareerActions.getOrganizationalUnitSuccess({organizationalUnit})
              }),
              catchError(() => {
                return of(
                  organizationalUnitActions.getOrganizationalUnitFailure()
                );
              })
            );
        })
      );
    },
    {dispatch:true}
  );

  enableCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      careersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateCareerActions.enableCareer),
        switchMap(({ idCar }) => {
          return careersService
            .enableCareer(idCar)
            .pipe(
              map((career: Careers) => {
                this.store.dispatch(
                  carrersAction.getCareersFeed()
                );
                return toggleStateCareerActions.enableCareerSuccess({career});
              }),
              catchError(() => {
                return of(
                  toggleStateCareerActions.enableCareerFailure()
                );
              })
            );
        })
      );
    }
  );
}
