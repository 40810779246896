import { createFeature, createReducer, on } from "@ngrx/store"
import { updateClientActions } from "../../actions/clients/updateClient.actions"
import { ClientInterface } from "src/app/pages/clients/types/client.interface"

export interface UpdateClientStateInterface {
    client: ClientInterface,
    isSubmittingUpdateClient: boolean,
    validationErrors: string,
  }

const initialState: UpdateClientStateInterface = {
    client: null,
    isSubmittingUpdateClient: false,
    validationErrors: null,
  }
  
  const updateClientFeature = createFeature({
    name: 'updateClient',
    reducer: createReducer(
      initialState,
      on(updateClientActions.getClient, (state) => ({
        ...state,
        isLoading: true,
      })),
      on(updateClientActions.getClientSuccess, (state, action) => ({
        ...state,
        isLoading: false,
        client: action.client,
      })),
      on(updateClientActions.getClientFailure, (state) => ({
        ...state,
        isLoading: false,
      })),
      on(updateClientActions.updateClient, (state) => ({
        ...state,
        isSubmittingUpdateClient: false,
      })),
      on(updateClientActions.updateClientSuccess, (state) => ({
        ...state,
        isSubmittingUpdateClient: true,
      })),
      on(updateClientActions.updateClientFailure, (state, action) => ({
        ...state,
        isSubmittingUpdateClient: false,
        validationErrors: '',
      })),
    ),
  })
  
  export const {
    name: updateClientFeatureKey,
    reducer: updateClientReducer,
    selectIsSubmittingUpdateClient,
    selectValidationErrors,
    selectClient,
  } = updateClientFeature