import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, AbstractControl, ValidationErrors, ValidatorFn  } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule, MatTooltip  } from '@angular/material/tooltip';
import { MyApiService } from 'src/services/connection';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    MatButtonModule,
    MatDialogModule, 
    MatIconModule, 
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
})



export class ChangePasswordComponent {
  @ViewChild('passwordTooltip', { static: true }) passwordTooltip!: TemplateRef<any>;
  passwordTooltipContent: string = `
    La contraseña debe tener: 
    - Al menos 1 número
    - Al menos 1 carácter especial
    - Al menos una letra mayúscula
    - Mínimo 8 caracteres
  `;

  changePasswordForm: FormGroup;
  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private myApiService: MyApiService,
    private _snackBar: MatSnackBar,
  ) {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(6), this.passwordStrengthValidator()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: this.passwordMatchValidator });
  }

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
  }

  // Validamos que la contraseña cumpla con los requisitos especificados
  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newPassword = control; 

      if (!newPassword.value) {
        return null;
      }

      const hasNumber = /\d/.test(newPassword.value);
      const hasSpecial = /[!@#$%^&*(),.?":{}|<>+\-*/:]/.test(newPassword.value);
      const hasUppercase = /[A-Z]/.test(newPassword.value);
      const hasMinLength = newPassword.value.length >= 8;

      const passwordValid = hasNumber && hasSpecial && hasUppercase && hasMinLength;
      
      if (!hasNumber) {
        newPassword.setErrors({ noNumber: true });
        return { noNumber: true };
      } else if (!hasSpecial) {
        newPassword.setErrors({ noSpecial: true });
        return { noSpecial: true };
      } else if (!hasUppercase) {
        newPassword.setErrors({ noUppercase: true });
        return { noUppercase: true };
      } else if (!hasMinLength) {
        newPassword.setErrors({ noLength: true });
        return { noLength: true };
      } else if (!passwordValid) {
        newPassword.setErrors(null);
      }
      
      return null;
    };
  }

  // Validacion de contrasenas - Seteamos los errores segun corresponda
  passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const currentPassword = control.get('currentPassword');
    const newPassword = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');

    // Validamos que el confirmPassword coincida con el newPassword  
    if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value && confirmPassword.value !== "") {
      confirmPassword.setErrors({ passwordsMismatch: true });
      return { passwordsMismatch: true };
    } else {
      const errors = confirmPassword.errors || {};

      if (errors['passwordsMismatch']) {
        delete errors['passwordsMismatch'];
        if (Object.keys(errors).length === 0) {
          confirmPassword.setErrors(null);
        } else {
          confirmPassword.setErrors(errors);
        }
      }
    }
    
    // Validamos que la nueva contrase;a, no sea igual a la contrase;a actual
    if (newPassword.value === currentPassword.value && newPassword.value !== "") {
      newPassword.setErrors({ isEqualPasswords: true });
      return { isEqualPasswords: true };
    } else {
      const errors = newPassword.errors || {};
      if (errors['isEqualPasswords']) {
        delete errors['isEqualPasswords'];
        if (Object.keys(errors).length === 0) {
          newPassword.setErrors(null);
        } else {
          newPassword.setErrors(errors);
        }
      }
    }

    return null;
  };

  async onChangePassword() {
    if (this.changePasswordForm.valid) {
      const changePasswordInfo = {
        "PreviousPassword": this.changePasswordForm.value.currentPassword,
        "ProposedPassword": this.changePasswordForm.value.confirmPassword,
        "AccessToken": "eyJraWQiOiJTZ2k5RVBEME9KXC8yaEh4UFk5S2wyRWhNdzg2dkdmS0FoVEVkYXVZak1Scz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhYzY4Y2Y1Ny1lMTM3LTQzYzAtYWJmOC0wNGU5ZTU4ODNmNjciLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9BTGRXYTJGdk4iLCJjbGllbnRfaWQiOiJjN2EwZ3J0dGxyNzZwdjF0Zmxhb2htZjl2Iiwib3JpZ2luX2p0aSI6IjdiMjg5MTlmLTkxZWYtNDZhMC04ZDUzLTZkY2RhNjVhZTkyYiIsImV2ZW50X2lkIjoiZDVjODc0N2EtOGQ3Ny00NzA1LWFlNzMtN2RmYWU4Yjg3MzU2IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcxODgwNzc4MiwiZXhwIjoxNzE4ODExMzgxLCJpYXQiOjE3MTg4MDc3ODIsImp0aSI6ImY3NmIxOGZiLWU1YTYtNDBhMy04YWYyLWIxYTE5YzgzZWY0NCIsInVzZXJuYW1lIjoianVhbl8xNzE2ODM1ODQ5MDY1In0.Myl1FWHp67tY7vWHrRsRC-scsz-OdoO4l7zW2OsanDTP32g9ATxE3Ee9T_5HK2EsLwFCCCwDyJPnqf5GsLs7zKVPgC99hdVN5-EfmAPaXYALYqaP3mpEOLxePweGCUn8US_XNmkyNF9mrIhByOy7ypTXPSR_wC_LML2pWR4tU2HCg5yI3yWutchdVMQZp1X8--Qml154EsR08TDgtpuU9QXIKQXw6l_431TapTlXLUgU35Lg8_9HwwAbQIilbSvZzsJDC91X8_69dDhIKamPyL29SZvSbDiJCUEHUHPt3ru2rOOCuFUZMJvBYQ-enIom1eAfLil2WumXI9RdDTd6rQ", // El token debe estar guardado localmente al momento de loguearse
        "email": "2230828@ucc.edu.ar", // Esta en el userData que se obtiene al momento de loguearse
      }

      try {
        const res = await this.myApiService.post('auth/change-password', changePasswordInfo);
        
        this.dialogRef.close();
        this._snackBar.openFromComponent(SnackBarSuccess, {
          data: {
            message: res.message,
          },
          panelClass: ['success-snackbar'],
          duration: 5000,
        });
      } catch (error) {
        this._snackBar.openFromComponent(SnackBarWarning, {
          data: {
            message: error.data.error,
            subtitle: error.data.message,
          },
          panelClass: ['warning-snackbar'],
          duration: 5000,
        });
        console.error('Error fetching data:', error.data);
      } 
    }
  }
}
