import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Careers } from '../../reducers/careers.reducer';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

export const toggleStateCareerActions = createActionGroup({
  source: 'toggle state career',
  events: {
    'Disable Career': props<{idCar: number}>(),
    'Disable Career success': props<{career: Careers}>(),
    'Disable Career failure': emptyProps(),

    'Get Organizational Unit': props<{ idOgUnit: number }>(),
    'Get Organizational Unit success': props<{organizationalUnit: OrganizationalUnitInterface}>(),
    'Get Organizational Unit failure': emptyProps(),

    'Enable Career': props<{idCar: number}>(),
    'Enable Career success': props<{career: Careers}>(),
    'Enable Career failure': emptyProps(),
  },
});
