import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { updateClientActions } from '../../actions/clients/updateClient.actions';
import { ClientsService } from 'src/app/pages/clients/clients.service';
import { ClientModal } from 'src/app/pages/clients/client-modal/client-modal.component';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';
import { clientsFeedActions } from '../../actions/clients/clientsFeed.actions';
import { clientActions } from '../../actions/clients/client.actions';
import { selectOrganizationalUnitFeedData } from '../../reducers/organizational-units/organizationalUnitFeed.reducers';
import { selectCountries } from '../../reducers/countries/countries.reducers';
import { ClientsFilterInterface } from 'src/app/pages/clients/types/clientsFilter.interface';
import { CountryInterface } from 'src/app/interfaces/country.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';

@Injectable()
export class updateClientEffect {
  organizationalUnits: OrganizationalUnitInterface[];
  countries: CountryInterface[];

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
  ) {}
  getClientEffect$ = createEffect(
    (actions$ = inject(Actions), clientsService = inject(ClientsService)) => {
      return actions$.pipe(
        ofType(updateClientActions.getClient),
        switchMap(({ uuid }) => {
          this.store
            .select(selectOrganizationalUnitFeedData)
            .subscribe(
              (organizationalUnits) =>
                (this.organizationalUnits = organizationalUnits)
            );
          this.store
            .select(selectCountries)
            .subscribe(
              (countries) =>
                (this.countries = countries)
            );
          return clientsService.getClient(uuid).pipe(
            map((client: ClientInterface) => {
              const dialogRef = this.dialog.open(ClientModal, {
                data: {
                  uuid: uuid,
                  title: 'Editar cliente',
                  name: `${client.name}`,
                  lastname: `${client.lastname}`,
                  countryPhoneCode: `${client.countryPhoneCode ? client.countryPhoneCode : ''}`,
                  phoneNumber: client.phoneNumber,
                  email: `${client.email}`,
                  organizationalUnits: this.organizationalUnits,
                  countries: this.countries,
                  countryName: `${
                    client.country ? client.country.name : ''
                  }`,
                  organizationalUnitName: client.orgUnitId.map((unit) => {return unit.name}),
                  acceptButton: 'Guardar',
                  cancelButton: 'Cancelar',
                  inputActive: true,
                  modify: true,
                  active: client.active,
                },
              });
              return updateClientActions.getClientSuccess({
                client,
              });
            }),
            catchError(() => {
              return of(updateClientActions.getClientFailure());
            })
          );
        })
      );
    }
  );

  updateClientEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(updateClientActions.updateClient),
        switchMap(({ request, uuid, isActive }) => {
          return clientsService.updateClient(request, uuid).pipe(
            map((client: ClientInterface) => {
              this.store.dispatch(
                clientsFeedActions.getClientsFeed({
                  filters: <ClientsFilterInterface>{
                    actives: isActive,
                    page: 0,
                    limit: 10,
                    column: 'updatedAt',
                    order: 'desc',
                  },
                })
              );
              this.dialog.closeAll()
              this.store.dispatch(
                clientActions.getClient({
                  uuid: uuid,
                })
              );
              this._snackBar.openFromComponent(SnackBarSuccess, {
                data: {
                  message: 'Cliente modificado exitosamente',
                  subtitle:
                    'Podés realizar modificaciones cuando necesites',
                },
                panelClass: ['success-snackbar'],
                duration: 3000,
              });

              return updateClientActions.updateClientSuccess({ client });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor ingresa nuevamente los datos',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                updateClientActions.updateClientFailure()
              );
            })
          );
        })
      );
    }
  );
}
