import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { OrganizationalUnitsService } from '../organizational-unit.service';
import { Store } from '@ngrx/store';
import { OrganizationalUnitFormInterface } from 'src/app/shared/types/organizationalUnitForm.interface';
import { createOrganizationalUnitActions } from 'src/app/state/actions/organizational-units/createOrganizationalUnit.actions';
import { updateOrganizationalUnitActions } from 'src/app/state/actions/organizational-units/updateOrganizationalUnit.actions';
import { selectIsSubmittingUpdateUnit, selectOrgUnitStatus } from 'src/app/state/reducers/organizational-units/updateOrganizationalUnit.reducers';
import { selectOrganizationalUnitInactives } from 'src/app/state/reducers/organizational-units/organizationalUnitFeed.reducers';
import { selectIsSubmittingOrganizationalCreate } from 'src/app/state/reducers/organizational-units/createOrganizationalUnit.reducers';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './organizational-unit-modal.component.html',
  standalone: true,
  styleUrls: ['./organizational-unit-modal.component.sass'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class OrganizationalUnitModal {
  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OrganizationalUnitModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationalUnitsService: OrganizationalUnitsService,
    private _snackBar: MatSnackBar,
    private store: Store
  ) {
  }

  onCancelButtonClick(): void {
    if (this.inputActive && this.isAcceptButtonEnabled) {
      this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent);
      this.notSavedDialogRef.afterClosed().subscribe((result) => {
        if (result === 'accept') {
          this.dialog.closeAll();
        }
      });
    } else if (
      this.inputActive &&
      this.organizationalUnitsService.getEditForm()
    ) {
      this.dialogRef.close();
      this.organizationalUnitsService.openOrganizationalUnitDetail(this.idOgUnit);
      this.organizationalUnitsService.setEditForm(false);
    } else {
      this.organizationalUnitsService.disabledEdit = false;
      this.dialogRef.close();
    }
  }

  async onCreateButtonClick(modify: boolean, active?: boolean) {
    this.organizationalUnitsService.disabledEdit = false;
    const dataOU: OrganizationalUnitFormInterface = {
      name: this.name,
      description: this.description,
      cod: this.code ? this.code : null,
      active: active ? true : undefined,
    };

    if (modify) {
      this.store.dispatch(
        updateOrganizationalUnitActions.updateOrganizationalUnit({
          request: dataOU,
          idOgUnit: this.data.id,
        })
      );
      this.store.select(selectOrganizationalUnitInactives).subscribe((orgUnitsInActive)=>{
        if (!orgUnitsInActive.length) {
          this.updateDisabledUnitsData(false);
          this.disabledBtnUnits(true);
          this.organizationalUnitsService.activeOrganizationalUnits();
        }
      })
      this.store.select(selectIsSubmittingUpdateUnit).subscribe(success => {
          if (success) {
         this.organizationalUnitsService.openOrganizationalUnitDetail(this.idOgUnit);
            this.dialogRef.close()
          }
        })

        if(active){
          this._snackBar.openFromComponent(SnackBarSuccess, {
            data: {
              message: 'La unidad Organizacional se habilitó correctamente',
            },
            panelClass: ['success-snackbar'],
            duration: 3000,
          });
          active = false;
        }
    } else {
      this.store.dispatch(
        createOrganizationalUnitActions.createOrganizationalUnit({
          request: dataOU,
        })
      );
      this.store.select(selectIsSubmittingOrganizationalCreate).subscribe(success => {
        if (success) {
          this.dialogRef.close()
        }
      })
    }
  }

  editOrganizationalUnit(idOgUnit: number): void {
    this.organizationalUnitsService.setEditForm(true);
    this.organizationalUnitsService.disabledEdit = true;
    this.organizationalUnitsService.openModifyOrganizationalUnit(idOgUnit);
    this.dialogRef.close();
  }

  title: string;
  idOgUnit: number;
  name: string;
  code: string;
  description: string;
  isAcceptButtonEnabled: boolean;
  inputActive: boolean;
  acceptButton: string;
  cancelButton: string;
  editFromCard: boolean;
  modify: boolean;
  activeOrgUnit: boolean = this.organizationalUnitsService.saveActive;
  actives: boolean = this.organizationalUnitsService.disabledUnits;
  editDisabledBtn: boolean = this.organizationalUnitsService.disabledEdit;
  organizationalUnitStatus: boolean;


  async ngOnInit() {
    this.store.select(selectOrgUnitStatus).subscribe(orgUnit => {
      this.organizationalUnitStatus = orgUnit
    });
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.editFromCard = this.data.editFromCard || false;
    this.modify = this.data.modify || false;
    this.idOgUnit = this.data.idOgUnit || 0;
    if (this.data.code?.toLowerCase() === 'null') {
      this.code = '';
    } else {
      this.code = this.data.code || '';
    }
  }

  onInputChange() {
    this.updateAcceptButtonState();
  }

  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled = !!this.name;
  }

  updateDisabledUnitsData(value: boolean) {
    this.organizationalUnitsService.updateBooleanData(value);
  }

  disabledBtnUnits(value: boolean) {
    this.organizationalUnitsService.updateDisabledBtnUnits(value);
  }
}
