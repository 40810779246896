import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as TermsActions from '../../state/actions/term.actions';
import { selectAllTerms, currentTerm } from '../../state/selectors/term.selectors';
import { MyApiService } from 'src/services/connection';
import { TermModalComponent } from './term-modal/term-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  constructor(
    public dialog: MatDialog,
    private myApiService: MyApiService,
    private store: Store<AppState>
  ) {}

  // Update the status of all Terms in NgRx
  async loadTerms(): Promise<void> {
    try {
      const data = await this.myApiService.get('term');   
      this.store.dispatch(TermsActions.loadTermsSuccess({ terms: data }));
    } catch (error) {
      console.error('Error fetching Terms:', error);
      this.store.dispatch(TermsActions.loadTermsFailure({ error }));
    }
  }

  // Update the status of Current Term
  async loadCurrentTerm(): Promise<void> {
    try {
      const data = await this.myApiService.get('term/current');      
      this.store.dispatch(TermsActions.loadCurrentTermSuccess({ currentTerm: data }));
    } catch (error) {
      console.error('Error fetching Terms:', error);
      this.store.dispatch(TermsActions.loadCurrentTermFailure({ error }));
    }
  }

  // Reload period table data after changes
  async reloadTableTerm(dataSource: MatTableDataSource<any>): Promise<void> {
    this.store.select(selectAllTerms).subscribe(terms => {
      var termsList = []

      // Current Term
      {terms.currentTerm !== null &&
        termsList.unshift({ ...terms.currentTerm, colorClass: 'currentTerm', disabled: false })
      }

      // Future Terms
      {terms.futureTerms !== null &&
        terms.futureTerms.forEach((termFuture) => {
          termsList.push({ ...termFuture, colorClass: 'futureTerm', disabled: false });
          // console.log("FUTUROS -------------------");
          // console.log(termFuture);
        })
      }

      // Historical Terms
      {terms.futureTerms !== null &&
        terms.historicalTerms.forEach((termPast) => {
          termsList.push({ ...termPast, colorClass: 'pastTerm', disabled: true })
          // console.log("TERMINADOS -------------------");
          // console.log(termPast);
        })
      }
      
      dataSource.data = termsList;
    });
  }

  // Get Current Period of NgRx
  currentTerm(): Observable<any> {    
    return this.store.select(currentTerm);
  }

  // Get all periods of NgRx
  async findAll(): Promise<any[]> {
    try {
      return new Promise<any>((resolve, reject) => {
        this.store.select(selectAllTerms).subscribe(term => {
          resolve(term);
        });
      });
    } catch (error) {
      console.error('Error fetching all Terms:', error);
      throw error;
    }
  }

  async openModifyTerm(idTerm: number) {
    try {
      const data = await this.myApiService.get(`term/${idTerm}`);
      
      const dialogRef = this.dialog.open(TermModalComponent, {
        data: {
          title: 'Modificar Periodo de Compra',
          name: `${data.name}`,
          description: `${data.description}`,
          acceptButton: 'Guardar Cambios',
          cancelButton: 'Cancelar',
          modifyTerm: true,
          dataTerm: {
            id: data.id,
            name: `${data.name}`,
            description: `${data.description}`,
            active: true,
            timeTerm: data.timeTerm.map((element, index) => { 
              return {
                startDate: moment(element.startDate, "DD/MM/YYYY"),
                endDate: moment(element.endDate, "DD/MM/YYYY"),
                id: element.id,
                orderTime: element.orderTime,
                typeTimeTerm: element.typeTimeTerm.name,
                minStartDate: element.orderTime === 1 ? new Date() : moment(element.startDate, "DD/MM/YYYY"),
                minEndDate: new Date() < new Date(moment(element.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")) ? moment(element.startDate, "DD/MM/YYYY").clone().add(1, 'days') : new Date(),
                maxStartDate: element.typeTimeTerm.id !== 1 ? moment(data.timeTerm[index - 1].endDate, "DD/MM/YYYY").clone().add(1, 'days') : null,
                disabledDateStart: new Date() > new Date(moment(element.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")),
                disabledDateEnd: new Date() > new Date(moment(element.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"))
              }
            })
          }
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.loadCurrentTerm()
        this.loadTerms();
        console.log(`Dialog result: ${result}`);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}
