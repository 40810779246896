import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ClientInterface } from "src/app/pages/clients/types/client.interface";
import { ClientFormInterface } from "src/app/pages/clients/types/clientForm.interface";
import { ClientsFilterInterface } from "src/app/pages/clients/types/clientsFilter.interface";

export const createClientActions = createActionGroup({
    source: 'create client',
    events: {
      'Create client': props<{
        request: ClientFormInterface;
      }>(),
      'Create client success': props<{
        client: ClientInterface;
      }>(),
      'Create client failure': emptyProps(),
    },
  });