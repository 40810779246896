import { createFeature, createReducer, on } from "@ngrx/store"
import { createOrganizationalUnitActions } from "../../actions/organizational-units/createOrganizationalUnit.actions"

export interface CreateOrganizationalUnitStateInterface {
    isSubmittingOrganizationalCreate: boolean,
    validationErrors: string,
  }

const initialState: CreateOrganizationalUnitStateInterface = {
    isSubmittingOrganizationalCreate: false,
    validationErrors: null,
  }

  const createOrganizationalUnitFeature = createFeature({
    name: 'createOrganizationalUnit',
    reducer: createReducer(
      initialState,
      on(createOrganizationalUnitActions.createOrganizationalUnit, (state) => ({
        ...state,
        isSubmittingOrganizationalCreate: false,
      })),
      on(createOrganizationalUnitActions.createOrganizationalUnitSuccess, (state, action) => ({
        ...state,
        isSubmittingOrganizationalCreate: true,
      })),
      on(createOrganizationalUnitActions.createOrganizationalUnitFailure, (state, action) => ({
        ...state,
        isSubmittingOrganizationalCreate: false,
        validationErrors: "",
      })),
    ),
  })

  export const {
    name: createOrganizationalUnitFeatureKey,
    reducer: createOrganizationalUnitReducer,
    selectIsSubmittingOrganizationalCreate,
    selectValidationErrors,
  } = createOrganizationalUnitFeature
