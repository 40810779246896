import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { organizationalUnitFeedActions } from '../../actions/organizational-units/organizationalUnitFeed.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';

@Injectable()
export class getOrganizationalUnitFeedEffect {
  constructor() {}

  getOrganizationalUnitFeedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitFeedService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(organizationalUnitFeedActions.getOrganizationalUnitFeed),
        switchMap(() => {
          return organizationalUnitFeedService.getOrganizationalUnitFeed().pipe(
            map((organizationalUnitFeed: OrganizationalUnitInterface[]) => {
              return organizationalUnitFeedActions.getOrganizationalUnitFeedSuccess(
                {
                  organizationalUnitFeed,
                }
              );
            }),
            catchError(() => {
              return of(
                organizationalUnitFeedActions.getOrganizationalUnitFeedFailure()
              );
            })
          );
        })
      );
    },
    { functional: true }
  );
}
