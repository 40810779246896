
<term-header (openCreateTerm)="openCreateTerm()"></term-header>
<ng-container>
  <app-summary-term></app-summary-term>
</ng-container>
<ng-container *ngIf="terms.currentTerm !== null || terms.futureTerms?.length > 0 || terms.historicalTerms?.length > 0">
  <table-term class="table-containers"></table-term>
</ng-container>
<ng-container *ngIf="terms.currentTerm === null && terms.futureTerms?.length === 0 && terms.historicalTerms?.length === 0">
  <app-empty-state [errorCode]="errorCode"></app-empty-state>
</ng-container>
  
