import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { countriesActions } from '../../actions/countries/countries.actions';
import { LocalityServices } from 'src/services/locality-service';
import { CountryInterface } from 'src/app/interfaces/country.interface';

@Injectable()
export class getCountriesEffect {
  constructor(private readonly actions$: Actions) {}

  getCountriesEffect$ = createEffect(
    (actions$ = inject(Actions), countriesService = inject(LocalityServices)) => {
      return actions$.pipe(
        ofType(countriesActions.getCountries),
        switchMap(() => {
          return countriesService.getCountries().pipe(
            map((countries: CountryInterface[]) => {
              return countriesActions.getCountriesSuccess({
                countries,
              });
            }),
            catchError(() => {
              return of(countriesActions.getCountriesFailure());
            })
          );
        })
      );
    }
  );
}
