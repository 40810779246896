import { CommonModule } from '@angular/common';
import {  OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { logIn } from 'src/app/state/actions/auth.actions';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule, Routes } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MyApiService } from 'src/services/connection';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent {

  constructor(private myApiService: MyApiService){

  }

  public hide = true;
  public email = '';
  public name = '';
  public lastname = '';
  public password = '';


  async register(){
    const payload =
    {
    email: this.email,
    name: this.name,
    lastname: this.lastname,
    password: this.password,
    roles: ["1"]
    }
    await this.myApiService.post('users/new-user', payload);
  }

}
