import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'term-header',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatIconModule],
  templateUrl: './term-header.component.html',
  styleUrls: ['./term-header.component.sass'],
})

export class TermHeader {
  @Input() title: string = 'Administración de Periodos';
  @Input() chipText: string = 'Nuevo Periodo';
  searchText: string = '';
  dialog: any;

  @Output() openCreateTerm = new EventEmitter<void>();

  onOpenCreateTerm() {
    this.openCreateTerm.emit();
  }
}
