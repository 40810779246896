<div class="header-container">
  <div class="header-title">
    <h2>{{ title }}</h2>
  </div>
  <div class="header-elements">
      <mat-chip  (click)="openCreateOrganizationalUnit()"  class="mat-chip">
        <div class="chip-content">
          <mat-icon class="icon-style">add</mat-icon>
          <span class="chip-text">{{ chipText }}</span>
        </div>
      </mat-chip>

    <div class="right-content">
      <div class="header-input">
        <mat-icon class="search-icon">search</mat-icon>
        <input class="search-style" type="text" id="search-input"  placeholder="Buscar">
      </div>
    </div>
  </div>
  <hr class="divider" />
</div>
