import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';

export const typeProductsDetailsAction = createActionGroup({
  source: 'typeProductsDetails',
  events: {
    'Get Type Products Details': props<{ id: number }>(),
    'Get Type Products Details success': props<{
      typeProducts: TypeProducts;
    }>(),
    'Get Type Products Details failure': emptyProps(),
  },
});
