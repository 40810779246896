import { Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { YearsInterface } from 'src/app/interfaces/years.interface';

@Injectable({
  providedIn: 'root'
})



export class DateServices {
  constructor(
    private http: HttpClient
  ) {}


  getYears(): Observable<YearsInterface[]> {
    // const fullUrl = `${environment.apiUrl}/country`;
    // return this.http.get<ProvinceInterface[]>(fullUrl);
    const years: YearsInterface[] = [
      {
        year: "2023",
      },
      {
        year: "2024",
      },
      {
        year: "2025",
      },
    ];
    return of(years);
  }

}
