import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SupplierInterface } from "src/app/pages/supplier/types/supplier.interface";
import { SupplierFormInterface } from "src/app/pages/supplier/types/supplierForm.interface";

export const createSupplierActions = createActionGroup({
  source: 'create supplier',
  events: {
    'Get Position feed': emptyProps(),
    'Get Position feed success': props<{
      position: any[];
    }>(),
    'Get Position feed failure': emptyProps(),
  
    'Create supplier': props<{
      request: SupplierFormInterface;
    }>(),
    'Create supplier success': props<{
      supplier: SupplierInterface;
    }>(),
    'Create supplier failure': emptyProps(),
  },
});