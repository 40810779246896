import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { Careers } from '../../reducers/careers.reducer';
  
export const carrersAction = createActionGroup({
  source: 'careers',
  events: {
    'Get Careers feed': emptyProps(),
    'Get Careers feed success': props<{
      careers: Careers[];
    }>(),
    'Get Careers feed failure': emptyProps(),
  },
});
  