import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/state/app.state';
import { environment } from 'src/environments/environment';
import { SupplierFilterInterface } from './types/supplierFilter.interface';
import { SupplierResponseInterface } from './types/supplierResponse.interface';
import { TypeSupplierInterface } from './types/typeSupplier.interface';
import { SupplierFormInterface } from './types/supplierForm.interface';
import { SupplierInterface } from './types/supplier.interface';

@Injectable({
  providedIn: 'root'
})

export class SupplierServices {
  constructor(
    private store: Store<AppState>,
    private http: HttpClient
  ) {}

  dataSource = new MatTableDataSource<any>();

  getSupplierFeed(
    filters: SupplierFilterInterface
  ): Observable<SupplierResponseInterface> {
    const { actives, page, limit, column, order } = filters;
    const fullUrl = `${environment.apiUrl}/suppliers/suppliers-by-filter?actives=${actives}&page=${page}&limit=${limit}&column=${column}&order=${order.toUpperCase()}`;
    return this.http.get<SupplierResponseInterface>(fullUrl);
  }

  getSupplier(
    id: number
  ): Observable<SupplierInterface> {
    const fullUrl = `${environment.apiUrl}/suppliers/${id}`;
    return this.http.get<SupplierInterface>(fullUrl);
  }

  enableSupplier(
    idSupplier: number,
    users: string[]
  ): Observable<SupplierInterface> {
    const body = {
      IDs: users
    };
    const fullUrl = `${environment.apiUrl}/suppliers/enabled/${idSupplier}`;
    return this.http.patch<SupplierInterface>(
      fullUrl,
      body
    );
  }


  disableSupplier(
    idSupplier: number
  ): Observable<SupplierInterface> {
    const fullUrl = `${environment.apiUrl}/suppliers/disabled/${idSupplier}`;
    return this.http.patch<SupplierInterface>(
      fullUrl,
      null
    );
  }

  createSupplier(
    supplierRequest: SupplierFormInterface
  ): Observable<SupplierInterface> {
    console.log("Estamos en Create Supplier");
    const fullUrl = environment.apiUrl + '/suppliers/new-supplier';
    return this.http.post<SupplierInterface>(fullUrl, supplierRequest);
  }

  updateSupplier(
    clientRequest: SupplierFormInterface,
    uuid: string
  ): Observable<SupplierInterface> {
    const fullUrl = `${environment.apiUrl}/users/${uuid}`;
    return this.http.patch<SupplierInterface>(
      fullUrl,
      clientRequest
    );
  }

  getPositionUser() {
    const fullUrl = `${environment.apiUrl}/position`;
    return this.http.get<SupplierInterface>(fullUrl);
  }

  getTypeSupplier(): Observable<TypeSupplierInterface[]> {
    const fullUrl = `${environment.apiUrl}/type-supplier`;
    return this.http.get<TypeSupplierInterface[]>(fullUrl);
  }
}
