import { createFeature, createReducer, on } from "@ngrx/store"
import { createPurchaseAnalystActions } from "../../actions/purchase-analyst/createPurchaseAnalyst.actions"

export interface CreatePurchaseAnalystStateInterface {
  isSubmittingCreatePurchaseAnalyst: boolean,
  validationErrors: string,
}

const initialState: CreatePurchaseAnalystStateInterface = {
    isSubmittingCreatePurchaseAnalyst: false,
    validationErrors: null,
  }

  const createPurchaseAnalystFeature = createFeature({
    name: 'createPurchaseAnalyst',
    reducer: createReducer(
      initialState,
      on(createPurchaseAnalystActions.createPurchaseAnalyst, (state) => ({
        ...state,
        isSubmittingCreatePurchaseAnalyst: false,
      })),
      on(createPurchaseAnalystActions.createPurchaseAnalystSuccess, (state, action) => ({
        ...state,
        isSubmittingCreatePurchaseAnalyst: true,
      })),
      on(createPurchaseAnalystActions.createPurchaseAnalystFailure, (state, action) => ({
        ...state,
        isSubmittingCreatePurchaseAnalyst: false,
        validationErrors: "",
      })),
    ),
  })

  export const {
    name: createPurchaseAnalystFeatureKey,
    reducer: createPurchaseAnalystReducer,
    selectIsSubmittingCreatePurchaseAnalyst,
    selectValidationErrors,
  } = createPurchaseAnalystFeature
