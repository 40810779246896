import {  Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {  Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordService {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient
  ) {}



  recoveryPassword(
    email: String
  ): Observable<any> {
    const fullUrl = environment.apiUrl + '/auth/recovery-password';
    return this.http.post<any>(
      fullUrl,
      {email}
    );
  }
  recoveryChangePassword(    
    email:string, 
    password:string,
    verificationCode :string
  ): Observable<any> {
    const fullUrl = environment.apiUrl + '/auth/confirm-password';
    const body = {email,password,verificationCode}
    return this.http.post<any>(
      fullUrl,
      body
    );
  }

}
