import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { OrganizationalUnitInterface } from "src/app/shared/types/organizationalUnit.interface";
import { OrganizationalUnitFormInterface } from "src/app/shared/types/organizationalUnitForm.interface";

export const createOrganizationalUnitActions = createActionGroup({
    source: 'create organizational unit',
    events: {
      'Create organizational unit': props<{
        request: OrganizationalUnitFormInterface;
      }>(),
      'Create organizational unit success': props<{
        organizationalUnit: OrganizationalUnitInterface;
      }>(),
      'Create organizational unit failure': emptyProps(),
    },
  });