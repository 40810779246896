import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';

@Component({
  standalone:true,
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.sass'],
  imports: [
    CommonModule,
    NgxMatInputTelComponent,
    ReactiveFormsModule,
    MatInputModule
  ],
})
export class PhoneNumberComponent implements OnInit, AfterViewInit {
  constructor(){}
  @Input() dataForm: FormGroup;
  @Input() required: boolean = false;
  @ViewChild('phoneInput') phoneInput: NgxMatInputTelComponent;
  
  codeCountry:string = '54';
  codeName:string = 'ar';
  defaultCountryNumber: string[] = ['ar'];
  
  ngOnInit() {
    this.defaultCountryNumber = this.dataForm.value.countryPhoneName ? [this.dataForm.value.countryPhoneName] : ['ar'];
    this.codeCountry = this.dataForm.value.countryPhoneCode ? this.dataForm.value.countryPhoneCode : '54';
  }

  ngAfterViewInit() {
    this.phoneInput.countryChanged.subscribe(() => {
      this.codeCountry = this.phoneInput.selectedCountry?.dialCode || '';
      this.codeName = this.phoneInput.selectedCountry?.flagClass.toLowerCase() || 'ar';
      this.defaultCountryNumber = [this.phoneInput.selectedCountry?.flagClass.toLowerCase()] || ['ar'];
      this.dataForm.patchValue({
        countryPhoneCode: this.codeCountry,
        countryPhoneName: this.codeName, 
      });
    });
  }

  charactersValidation(event: any): void {
    const value = event.target.value;
    const newValue = value.replace(/[^0-9]/g, '');
    this.dataForm.get(`phoneNumber`).setValue(newValue);  
  }
}
