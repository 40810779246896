import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PurchaseAnalystsService } from 'src/app/pages/purchase-analyst/purchase-analyst.service';
import { purchaseAnalystsFeedActions } from '../../actions/purchase-analyst/purchaseAnalystFeed.actions';
import { PurchaseAnalystsResponseInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystResponse.interface';


@Injectable()
export class getPurchaseAnalystsEffect {
  constructor(private readonly actions$: Actions) {}

  getPurchaseAnalystsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      purchaseAnalystsFeedService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(purchaseAnalystsFeedActions.getPurchaseAnalystsFeed),
        switchMap(({filters}) => {
          return purchaseAnalystsFeedService
            .getPurchaseAnalystsFeed({
              actives: filters.actives,
              page: filters.page,
              limit: filters.limit,
              column: filters.column,
              order: filters.order,
            })
            .pipe(
              map((purchaseAnalystsFeed: PurchaseAnalystsResponseInterface) => {
                return purchaseAnalystsFeedActions.getPurchaseAnalystsFeedSuccess({
                  purchaseAnalystsFeed,
                });
              }),
              catchError(() => {
                return of(purchaseAnalystsFeedActions.getPurchaseAnalystsFeedFailure());
              })
            );
        })
      );
    }
  );
}
