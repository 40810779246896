import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { Products } from '../../reducers/products/updateProducts.reducer';

export const updateProductsAction = createActionGroup({
  source: 'update products',
  events: {
    'Get Products Details': props<{ id: number }>(),
    'Get Products Details success': props<{products: Products}>(),
    'Get Products Details failure': emptyProps(),

    'Update Products': props<{ id: number, request: Products }>(),
    'Update Products success': props<{products: Products}>(),
    'Update Products failure': emptyProps(),
  },
});
