import { createFeature, createReducer, on } from "@ngrx/store"
import { toggleStateCareerActions } from "../../actions/careers/toggleStateCareer.actions"

export interface DisableCareerStateInterface {
  isSubmittingCareer: boolean,
  isLoadingStatus: boolean,
  disableOU:any,
}

const initialState: DisableCareerStateInterface = {
  isSubmittingCareer: false,
  isLoadingStatus: false,
  disableOU: null,
}
  
const toggleStateCareerFeature = createFeature({
  name: 'toggleStateCareer',
  reducer: createReducer(
    initialState,
    on(toggleStateCareerActions.disableCareer, (state) => ({
      ...state,
      isSubmittingCareer: true,
    })),
    on(toggleStateCareerActions.disableCareerSuccess, (state) => ({
      ...state,
      isSubmittingCareer: false,
    })),
    on(toggleStateCareerActions.disableCareerFailure, (state) => ({
      ...state,
      isSubmittingCareer: false,
    })),

    on(toggleStateCareerActions.getOrganizationalUnit, (state) => ({
      ...state,
      isLoadingStatus: true,
    })),
    on(
      toggleStateCareerActions.getOrganizationalUnitSuccess,
      (state, action) => ({
        ...state,
        isLoadingStatus: false,
        disableOU: action.organizationalUnit,
      })
    ),
    on(toggleStateCareerActions.getOrganizationalUnitFailure, (state) => ({
      ...state,
      isLoadingStatus: false,
    })),
    
    on(toggleStateCareerActions.enableCareer, (state) => ({
      ...state,
      isSubmittingCareer: true,
    })),
    on(toggleStateCareerActions.enableCareerSuccess, (state) => ({
      ...state,
      isSubmittingCareer: false,
    })),
    on(toggleStateCareerActions.enableCareerFailure, (state) => ({
      ...state,
      isSubmittingCareer: false,
    }))
  ),
})

export const {
  name: toggleStateCareerFeatureKey,
  reducer: toggleStateCareerUnitReducer,
  selectIsSubmittingCareer,
  selectDisableOU,
  selectIsLoadingStatus
} = toggleStateCareerFeature