import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Career} from './careers.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Careers } from 'src/app/state/reducers/careers.reducer';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { carrerDetailsAction } from 'src/app/state/actions/careers/careerDetails.action';
import { updateCarrerAction } from 'src/app/state/actions/careers/updateCareer.action';
import { toggleStateCareerActions } from 'src/app/state/actions/careers/toggleStateCareer.actions';
import { selectIsLoadingStatus } from 'src/app/state/reducers/careers/toggleStateCareer.reducers';
import { selectCareersActives, selectCareersInactives, selectIsLoading } from 'src/app/state/reducers/careers/careers.reducer';
import { createCreerAction } from 'src/app/state/actions/careers/createCareer.action';
@Injectable({
  providedIn: 'root',
})
export class CareersService {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}
  saveActive: boolean = false;
  dataSource = new MatTableDataSource<any>();
  disabledEdit: boolean;

  disabledCareerChanged = new EventEmitter<boolean>();
  disabledCareer: boolean = false;

  disabledBtnUnitsChanged = new EventEmitter<boolean>();
  disabledBtn: boolean = true;

  updateDisabledCareerData(value: boolean) {
    this.disabledCareer = value;
    this.disabledCareerChanged.emit(this.disabledCareer);
  }

  updateDisabledBtnUnits(newValue: boolean) {
    this.disabledBtn = newValue;
    this.disabledBtnUnitsChanged.emit(this.disabledBtn);
  }

  private editForm = new BehaviorSubject<boolean>(false);
  editFormServ = this.editForm.asObservable();

  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }

  getCareers(): Observable<Careers[]> {
    return this.http.get<Careers[]>(`${environment.apiUrl}/careers`);
  }

  getCareerDetails(idCar:number): Observable<Career> {
    return this.http.get<Career>(`${environment.apiUrl}/careers/${idCar}`);
  }

  getCareersByOrgUnit(idOgUnit:number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/careers/organizational-unit/${idOgUnit}`);
  }

  createCareer(career: any): Observable<Careers> {
    const url = `${environment.apiUrl}/careers`
    return this.http.post<Careers>(url, career);
  }

  updateCareer(idCar:number, career: any): Observable<Careers> {
    const url = `${environment.apiUrl}/careers/update/${idCar}`
    return this.http.patch<Careers>(url, career);
  }

  disableCareer(
    idCar: number
  ): Observable<Careers> {
    const fullUrl = `${environment.apiUrl}/careers/disable/${idCar}`;
    return this.http.patch<Careers>(
      fullUrl,
      null
    );
  }

  enableCareer(
    idCar: number
  ): Observable<Careers> {
    const fullUrl = `${environment.apiUrl}/careers/enabled/${idCar}`;
    return this.http.patch<Careers>(
      fullUrl,
      null
    );
  }

  async existDisabled(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.store.select(selectCareersInactives).subscribe((careers) => {
        if (!careers.length) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async validatedOU(idOgUnit: number): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch(toggleStateCareerActions.getOrganizationalUnit({ idOgUnit }));

        const subscription = this.store.select(selectIsLoadingStatus).subscribe({
          next: (loading) => {
            if (!loading) {
              resolve();
              subscription.unsubscribe();
            }
          },
          error: (err) => {
            reject(err);
            subscription.unsubscribe();
          },
        });
      });
    } catch (error) {
      this.store.dispatch(toggleStateCareerActions.getOrganizationalUnitFailure());
    }
  }
  async activeCareers() {
    this.store
      .select(selectCareersActives)
      .subscribe((careersActive: Careers[]) =>{
        this.dataSource.data = careersActive
    })
  }

  async inactiveCareers() {
    this.store
      .select(selectCareersInactives)
      .subscribe((careersInActive: Careers[]) =>{
        this.dataSource.data = careersInActive
    })
  }

  openCreateCareer() {
    this.store.dispatch(createCreerAction.getCareerModal());
  }

  openCareerDetail(id: number) {
    this.store.dispatch(carrerDetailsAction.getCareerDetails({idCar: id}))
  }

  async openModifyCareer(idCar: number) {
    try {
      this.store.dispatch(updateCarrerAction.getCareerDetails({idCar}))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}
