import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { logIn, logInSuccess } from 'src/app/state/actions/auth.actions';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule, Routes } from '@angular/router';
import { MyApiService } from 'src/services/connection';


@Component({
  selector: 'login-component',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private myApiService: MyApiService,
    private router: Router)
    { }

  // We subscribe to hear the changes in the Store
  ngOnInit() {
    this.store.select('auth').subscribe( logInState => {
      console.log(logInState);
    })
  }

  hide = true;
  public email: string = '';
  public password: string = '';


  async login() {
    // Implement login logic
    const payload = {
      email: this.email,
      password: this.password
    };
    const data = await this.myApiService.post('auth/signin', payload);
    if(data){
      console.warn(data.status);
      const accion = logIn(payload);
      this.store.dispatch( accion );
      this.router.navigate(['/home']);
      console.log("data: ", data);
    }
  }
}
