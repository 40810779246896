import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface Country {
  dialCode: string;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  getCountryCodeByDialCode(dialCode: string): Observable<string> {
    console.log("dialCode adentro: ", dialCode);
    return of(countryData).pipe(
      map(countries => {
        const country = countries.find(c => c.dialCode == dialCode);
        return country ? country.code : 'ar';
      })
    );
  }
}

const countryData: Country[] = [
  { dialCode: '93', code: 'af' },
  { dialCode: '355', code: 'al' },
  { dialCode: '213', code: 'dz' },
  { dialCode: '376', code: 'ad' },
  { dialCode: '244', code: 'ao' },
  { dialCode: '54', code: 'ar' },
  { dialCode: '374', code: 'am' },
  { dialCode: '297', code: 'aw' },
  { dialCode: '61', code: 'au' },
  { dialCode: '43', code: 'at' },
  { dialCode: '994', code: 'az' },
  { dialCode: '973', code: 'bh' },
  { dialCode: '880', code: 'bd' },
  { dialCode: '375', code: 'by' },
  { dialCode: '32', code: 'be' },
  { dialCode: '501', code: 'bz' },
  { dialCode: '229', code: 'bj' },
  { dialCode: '975', code: 'bt' },
  { dialCode: '591', code: 'bo' },
  { dialCode: '387', code: 'ba' },
  { dialCode: '267', code: 'bw' },
  { dialCode: '55', code: 'br' },
  { dialCode: '673', code: 'bn' },
  { dialCode: '359', code: 'bg' },
  { dialCode: '226', code: 'bf' },
  { dialCode: '257', code: 'bi' },
  { dialCode: '855', code: 'kh' },
  { dialCode: '237', code: 'cm' },
  { dialCode: '1', code: 'ca' },
  { dialCode: '238', code: 'cv' },
  { dialCode: '236', code: 'cf' },
  { dialCode: '235', code: 'td' },
  { dialCode: '56', code: 'cl' },
  { dialCode: '86', code: 'cn' },
  { dialCode: '57', code: 'co' },
  { dialCode: '269', code: 'km' },
  { dialCode: '242', code: 'cg' },
  { dialCode: '682', code: 'ck' },
  { dialCode: '506', code: 'cr' },
  { dialCode: '385', code: 'hr' },
  { dialCode: '53', code: 'cu' },
  { dialCode: '357', code: 'cy' },
  { dialCode: '420', code: 'cz' },
  { dialCode: '243', code: 'cd' },
  { dialCode: '45', code: 'dk' },
  { dialCode: '253', code: 'dj' },
  { dialCode: '670', code: 'tl' },
  { dialCode: '593', code: 'ec' },
  { dialCode: '20', code: 'eg' },
  { dialCode: '503', code: 'sv' },
  { dialCode: '240', code: 'gq' },
  { dialCode: '291', code: 'er' },
  { dialCode: '372', code: 'ee' },
  { dialCode: '251', code: 'et' },
  { dialCode: '679', code: 'fj' },
  { dialCode: '358', code: 'fi' },
  { dialCode: '33', code: 'fr' },
  { dialCode: '594', code: 'gf' },
  { dialCode: '241', code: 'ga' },
  { dialCode: '220', code: 'gm' },
  { dialCode: '995', code: 'ge' },
  { dialCode: '49', code: 'de' },
  { dialCode: '233', code: 'gh' },
  { dialCode: '30', code: 'gr' },
  { dialCode: '299', code: 'gl' },
  { dialCode: '502', code: 'gt' },
  { dialCode: '224', code: 'gn' },
  { dialCode: '245', code: 'gw' },
  { dialCode: '592', code: 'gy' },
  { dialCode: '509', code: 'ht' },
  { dialCode: '504', code: 'hn' },
  { dialCode: '852', code: 'hk' },
  { dialCode: '36', code: 'hu' },
  { dialCode: '354', code: 'is' },
  { dialCode: '91', code: 'in' },
  { dialCode: '62', code: 'id' },
  { dialCode: '98', code: 'ir' },
  { dialCode: '964', code: 'iq' },
  { dialCode: '353', code: 'ie' },
  { dialCode: '972', code: 'il' },
  { dialCode: '39', code: 'it' },
  { dialCode: '225', code: 'ci' },
  { dialCode: '81', code: 'jp' },
  { dialCode: '962', code: 'jo' },
  { dialCode: '7', code: 'kz' },
  { dialCode: '254', code: 'ke' },
  { dialCode: '686', code: 'ki' },
  { dialCode: '383', code: 'xk' },
  { dialCode: '965', code: 'kw' },
  { dialCode: '996', code: 'kg' },
  { dialCode: '856', code: 'la' },
  { dialCode: '371', code: 'lv' },
  { dialCode: '961', code: 'lb' },
  { dialCode: '266', code: 'ls' },
  { dialCode: '231', code: 'lr' },
  { dialCode: '218', code: 'ly' },
  { dialCode: '423', code: 'li' },
  { dialCode: '370', code: 'lt' },
  { dialCode: '352', code: 'lu' },
  { dialCode: '853', code: 'mo' },
  { dialCode: '389', code: 'mk' },
  { dialCode: '261', code: 'mg' },
  { dialCode: '265', code: 'mw' },
  { dialCode: '60', code: 'my' },
  { dialCode: '960', code: 'mv' },
  { dialCode: '223', code: 'ml' },
  { dialCode: '356', code: 'mt' },
  { dialCode: '692', code: 'mh' },
  { dialCode: '222', code: 'mr' },
  { dialCode: '230', code: 'mu' },
  { dialCode: '52', code: 'mx' },
  { dialCode: '691', code: 'fm' },
  { dialCode: '373', code: 'md' },
  { dialCode: '377', code: 'mc' },
  { dialCode: '976', code: 'mn' },
  { dialCode: '382', code: 'me' },
  { dialCode: '212', code: 'ma' },
  { dialCode: '258', code: 'mz' },
  { dialCode: '95', code: 'mm' },
  { dialCode: '264', code: 'na' },
  { dialCode: '674', code: 'nr' },
  { dialCode: '977', code: 'np' },
  { dialCode: '31', code: 'nl' },
  { dialCode: '687', code: 'nc' },
  { dialCode: '64', code: 'nz' },
  { dialCode: '505', code: 'ni' },
  { dialCode: '227', code: 'ne' },
  { dialCode: '234', code: 'ng' },
  { dialCode: '47', code: 'no' },
  { dialCode: '968', code: 'om' },
  { dialCode: '92', code: 'pk' },
  { dialCode: '680', code: 'pw' },
  { dialCode: '970', code: 'ps' },
  { dialCode: '507', code: 'pa' },
  { dialCode: '675', code: 'pg' },
  { dialCode: '595', code: 'py' },
  { dialCode: '51', code: 'pe' },
  { dialCode: '63', code: 'ph' },
  { dialCode: '48', code: 'pl' },
  { dialCode: '351', code: 'pt' },
  { dialCode: '974', code: 'qa' },
  { dialCode: '40', code: 'ro' },
  { dialCode: '7', code: 'ru' },
  { dialCode: '250', code: 'rw' },
  { dialCode: '590', code: 'bl' },
  { dialCode: '685', code: 'ws' },
  { dialCode: '378', code: 'sm' },
  { dialCode: '239', code: 'st' },
  { dialCode: '966', code: 'sa' },
  { dialCode: '221', code: 'sn' },
  { dialCode: '381', code: 'rs' },
  { dialCode: '248', code: 'sc' },
  { dialCode: '232', code: 'sl' },
  { dialCode: '65', code: 'sg' },
  { dialCode: '421', code: 'sk' },
  { dialCode: '386', code: 'si' },
  { dialCode: '677', code: 'sb' },
  { dialCode: '252', code: 'so' },
  { dialCode: '27', code: 'za' },
  { dialCode: '82', code: 'kr' },
  { dialCode: '211', code: 'ss' },
  { dialCode: '34', code: 'es' },
  { dialCode: '94', code: 'lk' },
  { dialCode: '249', code: 'sd' },
  { dialCode: '597', code: 'sr' },
  { dialCode: '268', code: 'sz' },
  { dialCode: '46', code: 'se' },
  { dialCode: '41', code: 'ch' },
  { dialCode: '963', code: 'sy' },
  { dialCode: '886', code: 'tw' },
  { dialCode: '992', code: 'tj' },
  { dialCode: '255', code: 'tz' },
  { dialCode: '66', code: 'th' },
  { dialCode: '228', code: 'tg' },
  { dialCode: '676', code: 'to' },
  { dialCode: '216', code: 'tn' },
  { dialCode: '90', code: 'tr' },
  { dialCode: '993', code: 'tm' },
  { dialCode: '688', code: 'tv' },
  { dialCode: '256', code: 'ug' },
  { dialCode: '380', code: 'ua' },
  { dialCode: '971', code: 'ae' },
  { dialCode: '44', code: 'gb' },
  { dialCode: '1', code: 'us' },
  { dialCode: '598', code: 'uy' },
  { dialCode: '998', code: 'uz' },
  { dialCode: '678', code: 'vu' },
  { dialCode: '58', code: 've' },
  { dialCode: '84', code: 'vn' },
  { dialCode: '681', code: 'wf' },
  { dialCode: '967', code: 'ye' },
  { dialCode: '260', code: 'zm' },
  { dialCode: '263', code: 'zw' }
];
