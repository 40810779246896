import { CommonModule } from '@angular/common';
import { Component, Inject  } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  standalone:true,
  imports: [MatIconModule, CommonModule, MatDialogModule, MatButtonModule],
  selector: 'associated-elements-modal',
  templateUrl: './associated-elements-modal.component.html',
  styleUrls: ['./associated-elements-modal.component.sass']
})

export class AssociatedElementsModalComponent {
  constructor(
    private dialogRef: MatDialogRef<AssociatedElementsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
    ) {}


  title:string
  subtitleAbove:string
  subtitleBellow:string
  subtitleQuestion:string
  elementsGroup: string[]
  acceptButton: string
  cancelButton: string
  redirect: string

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.subtitleAbove = this.data.subtitleAbove || '';
    this.subtitleBellow = this.data.subtitleBellow || '';
    this.acceptButton = this.data.acceptButton ===  'N/A' ? '' : 'Visitar';
    this.cancelButton = this.data.cancelButton || 'Cerrar';
    this.subtitleQuestion = this.data.subtitleQuestion || '';
    this.elementsGroup = this.data.elementsGroup || [''];
    this.redirect = this.data.redirect || [''];
  }


  onAcceptButtonClick(): void {
    this.router.navigate([`/${this.redirect}`])
    this.dialogRef.close('accept');
  }

  onCancelButtonClick(): void {
    this.dialogRef.close(true);
  }

}


