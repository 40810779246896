import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { CareersService } from '../careers.service';
import { Store } from '@ngrx/store';
import { updateCarrerAction } from 'src/app/state/actions/careers/updateCareer.action';
import { selectCareerStatus, selectIsSubmittingUpdateCareer } from 'src/app/state/reducers/careers/updateCareer.reducer';
import { createCreerAction } from 'src/app/state/actions/careers/createCareer.action';
import { selectCareersInactives } from 'src/app/state/reducers/careers/careers.reducer';
import { selectIsSubmittingCareerCreate } from 'src/app/state/reducers/careers/createCareer.reducer';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CareerInterface } from '../careers.interface';
import { selectOrganizationalUnitActives } from 'src/app/state/reducers/organizational-units/organizationalUnitFeed.reducers';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './careers-modal.component.html',
  standalone: true,
  styleUrls: ['./careers-modal.component.sass'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class CareersModal {
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CareersModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private careersService: CareersService,
    private _snackBar: MatSnackBar,
    private store: Store
  ) {}

  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;
  dataRes: any;
  errorCode?: any = null;

  onCancelButtonClick(): void {
    if (this.inputActive && this.isAcceptButtonEnabled) {
      this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent);
      this.notSavedDialogRef.afterClosed().subscribe((result) => {
        if (result === 'accept') {
          this.dialog.closeAll();
        }
      });
    } else if (this.inputActive && this.careersService.getEditForm()) {
      this.dialogRef.close();
      this.careersService.openCareerDetail(this.idCar);
      this.careersService.setEditForm(false);
    } else {
      this.careersService.disabledEdit = false;
      this.dialogRef.close();
    }
  }

  async onCreateButtonClick(modify: boolean, active?: boolean) {
    this.careersService.disabledEdit = false;
    const selectedUnit = this.organizationalUnits.find(
      (unit: { name: string }) => unit.name === this.organizationalUnitName
    );
    const selectedCategory = this.categories.find(
      (category: { name: string }) => category.name === this.category
    );
    const selectedLevel = this.levels.find(
      (level: { name: string }) => level.name === this.level
    );

    if (!selectedUnit) {
      console.error(
        'Unidad organizacional no encontrada para el nombre seleccionado'
      );
      return;
    }
    if (!selectedCategory) {
      console.error('Categoria no encontrada para el nombre seleccionado');
      return;
    }
    if (!selectedLevel) {
      console.error(
        'Nivel Academico no encontrado para el nombre seleccionado'
      );
      return;
    }

    const dataOU : CareerInterface = {
      name: this.name,
      cod: this.code ? this.code : null,
      description: this.description,
      career_level: selectedLevel.id.toString(),
      career_category: selectedCategory.id.toString(),
      active: active ? true : undefined,
      orgUnitId: selectedUnit.idOgUnit.toString(),
    };

    if (modify) {
      this.store.dispatch(updateCarrerAction.updateCareer({
        request: dataOU,
        idCar: this.data.idCar
      }))

        this.store.select(selectCareersInactives).subscribe((careersInActive)=>{
          if (!careersInActive.length) {
            this.updateDisabledUnitsData(false);
            this.disabledBtnUnits(true);
            this.careersService.activeCareers();
          }
        })

        this.store.select(selectIsSubmittingUpdateCareer).subscribe(success => {
          if (success) {
            this.careersService.openCareerDetail(this.idCar);
            this.dialogRef.close()
          }
        })

        if(active){
          this._snackBar.openFromComponent(SnackBarSuccess, {
            data: {
              message: 'La carrera se habilitó correctamente',
            },
            panelClass: ['success-snackbar'],
            duration: 3000,
          });
        }
    } else {
      this.store.dispatch(createCreerAction.createCareer({
        resquest: dataOU,
      }));
      this.store.select(selectIsSubmittingCareerCreate).subscribe(success => {
        if (success) {
          this.dialogRef.close()
        }
      })
    }
  }

  editCareer(idCar: number): void {
    this.careersService.setEditForm(true);
    this.careersService.disabledEdit = true;
    this.careersService.openModifyCareer(idCar);
    this.dialogRef.close();
  }

  title: string;
  name: string;
  code: string;
  description: string;
  organizationalUnitName: string;
  organizationalUnits: any = [];
  isAcceptButtonEnabled: boolean;
  inputActive: boolean;
  acceptButton: string;
  cancelButton: string;
  modify: boolean;
  idCar: number;
  orgUnitId: number;
  activeCareers: boolean;
  actives: boolean = this.careersService.disabledCareer;
  editDisabledBtn: boolean = this.careersService.disabledEdit;
  category: string;
  categories: any[];
  level: string;
  levels: any[];
  careerStatus: boolean;

  async ngOnInit() {
    this.store.select(selectCareerStatus).subscribe(loading => {
      this.careerStatus = loading
    });
    this.store
    .select(selectOrganizationalUnitActives)
    .subscribe((organizationalUnits: OrganizationalUnitInterface[]) => {
      this.organizationalUnits = organizationalUnits;
    });
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.categories = this.data.categories || [];
    this.category = this.data.category || '';
    this.levels = this.data.levels || [];
    this.level = this.data.level || '';
    this.organizationalUnitName = this.data.organizationalUnitName || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.modify = this.data.modify || false;
    this.idCar = this.data.idCar || null;
    this.orgUnitId = this.data.orgUnitId || 0;
    if (this.data.code?.toLowerCase() === 'null') {
      this.code = '';
    } else {
      this.code = this.data.code || '';
    }
  }

  onInputChange() {
    this.updateAcceptButtonState();
  }

  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled =
      !!this.name &&
      !!this.category &&
      !!this.organizationalUnitName &&
      !!this.level;
  }

  updateDisabledUnitsData(value: boolean) {
    this.careersService.updateDisabledCareerData(value);
  }

  disabledBtnUnits(value: boolean) {
    this.careersService.updateDisabledBtnUnits(value);
  }
}
