import { createReducer, on } from "@ngrx/store";
import { logIn, logInFailure, logInSuccess } from "../actions/auth.actions";

export interface AuthState {
    isLoggedIn: boolean,
    userData: any
}

export const initialState: AuthState = {
    isLoggedIn: false,
    userData: {}
}

export const authReducer = createReducer (
    initialState,
    on(logIn, (state) => ({
        ...state,
        isLoggedIn: true,
    })),
    on(logInSuccess, (state, { clientsFeed }) => ({
      ...state,
      userData : clientsFeed,
    })),
    on(logInFailure, (state, { error }) => ({
      ...state,
      error,
    }))
)

