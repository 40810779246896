import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PurchaseAnalystsFilterInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystFilter.interface';
import { PurchaseAnalystsResponseInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystResponse.interface';

export const purchaseAnalystsFeedActions = createActionGroup({
  source: 'purchase analysts Feed',
  events: {
    'Get purchase analysts feed': props<{
      filters: PurchaseAnalystsFilterInterface;
    }>(),
    'Get purchase analysts feed success': props<{
      purchaseAnalystsFeed: PurchaseAnalystsResponseInterface;
    }>(),
    'Get purchase analysts feed failure': emptyProps(),
  },
});
