import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { clientsFeedActions } from '../../actions/clients/clientsFeed.actions';
import { ClientsService } from 'src/app/pages/clients/clients.service';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';
import { ClientsResponseInterface } from 'src/app/pages/clients/types/clientsResponse.interface';

@Injectable()
export class getClientsEffect {
  constructor(private readonly actions$: Actions) {}

  getClientsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsFeedService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(clientsFeedActions.getClientsFeed),
        switchMap(({filters}) => {
          return clientsFeedService
            .getClientsFeed({
              actives: filters.actives,
              page: filters.page,
              limit: filters.limit,
              column: filters.column,
              order: filters.order,
            })
            .pipe(
              map((clientsFeed: ClientsResponseInterface) => {
                return clientsFeedActions.getClientsFeedSuccess({
                  clientsFeed,
                });
              }),
              catchError(() => {
                return of(clientsFeedActions.getClientsFeedFailure());
              })
            );
        })
      );
    }
  );
}
