import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { ProductServices } from "src/app/pages/product/product.service";
import { productsAction } from "../../actions/products/products.action";

@Injectable()
export class getProductsEffect {
  constructor(private readonly actions$: Actions) {}

  getProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getProductsService = inject(ProductServices)
    ) => {
      return actions$.pipe(
        ofType(productsAction.getProductsFeed),
        switchMap(({filters}) => {
          return getProductsService.getProductsFeed(  {
            page: filters.page,
            limit: filters.limit,
            column: filters.column,
            order: filters.order,
          })
          .pipe(
            map((products: any) => {
              return productsAction.getProductsFeedSuccess({products});
            }),
            catchError(() => {
              return of(
                productsAction.getProductsFeedFailure()
              );
            })
          );
        })
      );
    },
  );
}
