import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';

export const createTypeProductsAction = createActionGroup({
  source: 'create type products',
  events: {
    'Create Type Products': props<{ request: TypeProducts }>(),
    'Create Type Products success': props<{typeProducts: TypeProducts}>(),
    'Create Type Products failure': emptyProps(),
  },
});
