import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PurchaseAnalystsService } from 'src/app/pages/purchase-analyst/purchase-analyst.service';
import { createPurchaseAnalystActions } from '../../actions/purchase-analyst/createPurchaseAnalyst.actions';
import { PurchaseAnalystInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalyst.interface';
import { purchaseAnalystsFeedActions } from '../../actions/purchase-analyst/purchaseAnalystFeed.actions';
import { PurchaseAnalystsFilterInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystFilter.interface';

@Injectable()
export class createPurchaseAnalystffect {
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}
  createPurchaseAnalystffect$ = createEffect(
    (
      actions$ = inject(Actions),
      createPurchaseAnalystService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(createPurchaseAnalystActions.createPurchaseAnalyst),
        switchMap(({ request}) => {
          return createPurchaseAnalystService
            .createPurchaseAnalyst(request)
            .pipe(
              map((purchaseAnalyst: PurchaseAnalystInterface) => {
                this.store.dispatch(
                  purchaseAnalystsFeedActions.getPurchaseAnalystsFeed({
                    filters: <PurchaseAnalystsFilterInterface>{
                      actives: true,
                      page: 0,
                      limit: 10,
                      column: 'updatedAt',
                      order: 'desc',
                    },
                  })
                );
                this._snackBar.openFromComponent(SnackBarSuccess, {
                  data: {
                    message: 'Administrador creado exitosamente',
                    subtitle:
                      'Podés realizar modificaciones o deshabilitarlo cuando necesites',
                  },
                  panelClass: ['success-snackbar'],
                  duration: 3000,
                });
                return createPurchaseAnalystActions.createPurchaseAnalystSuccess(
                  { purchaseAnalyst }
                );
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 409
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  createPurchaseAnalystActions.createPurchaseAnalystFailure()
                );
              })
            );
        })
      );
    },
    { functional: true }
  );
}
