import { createAction, props } from "@ngrx/store";
import { TypeProduct } from "../reducers/typeProduct.reducer";

export const loadTypeProducts = createAction("[Type Products] Load Type Products");

export const loadTypeProductsSuccess = createAction(
  "[Type Products] Load Type Products Success",
  props<{ typeProducts: TypeProduct[] }>()
);

export const loadTypeProductsFailure = createAction(
  '[Type Products] Load Type Products Failure',
  props<{ error: any }>()
);
