import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { MyApiService } from "src/services/connection";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { updateCarrerAction } from "../../actions/careers/updateCareer.action";
import { CareersService } from "src/app/pages/careers/careers.service";
import { CareersModal } from "src/app/pages/careers/careers-modal/careers-modal.component";
import { carrersAction } from "../../actions/careers/careers.action";
import { OrganizationalUnitInterface } from "src/app/shared/types/organizationalUnit.interface";
@Injectable()
export class updateCareerEffect {
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private myApiService: MyApiService,
  ){}

  categories:any;
  levels:any;
  dataOrganizationalUnits:OrganizationalUnitInterface[];

  async getLevels(){
    this.levels = await this.myApiService.get('career-level');

    this.categories = await this.myApiService.get('career-category');

  }

  getCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getCareersService = inject(CareersService)
    ) => {
      this.getLevels();
      return actions$.pipe(
        ofType(updateCarrerAction.getCareerDetails),
        switchMap(({idCar}) => {
          return getCareersService.getCareerDetails(idCar).pipe(
            map((career: any) => {
              this.dialog.open(CareersModal, {
                data: {
                  idCar: `${career.idCar}`,
                  title: `Carrera - Nro ${career.idCar}`,
                  name: `${career.name}`,
                  description: `${career.description}`,
                  code: `${career.cod}`,
                  category: `${career.career_category.name}`,
                  categories: this.categories,
                  level: `${career.career_level.name}`,
                  levels: this.levels,
                  organizationalUnitName: `${
                    career.orgUnitId.active === true ? career.orgUnitId.name : ''
                  }`,
                  organizationalUnits: this.dataOrganizationalUnits,
                  acceptButton: 'Guardar Cambios',
                  cancelButton: 'Cancelar',
                  inputActive: true,
                  modify: true,
                },
              });

              return updateCarrerAction.getCareersDetailsSuccess({career});
            }),
            catchError(() => {
              return of(
                updateCarrerAction.getCareersDetailsFailure()
              );
            })
          );
        })
      );
    },
  );

  updateCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getCareersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(updateCarrerAction.updateCareer),
        switchMap(({idCar, request}) => {
          return getCareersService.updateCareer(idCar, request).pipe(
            map((career: any) => {
              this.store.dispatch(
                carrersAction.getCareersFeed()
              );
              return updateCarrerAction.updateCareersSuccess({career});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                updateCarrerAction.updateCareersFailure()
              );
            })
          );
        })
      );
    },
  );
}
