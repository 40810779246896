import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

import { AssociatedElementsModalComponent } from 'src/app/shared/components/associated-elements-modal/associated-elements-modal.component';
import { PurchaseAnalystsService } from 'src/app/pages/purchase-analyst/purchase-analyst.service';
import { toggleStatePurchaseAnalystActions } from '../../actions/purchase-analyst/toggleStatePurchaseAnalyst.actions';
import { PurchaseAnalystInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalyst.interface';
import { purchaseAnalystsFeedActions } from '../../actions/purchase-analyst/purchaseAnalystFeed.actions';
import { purchaseAnalystActions } from '../../actions/purchase-analyst/purchaseAnalyst.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class toggleStatePurchaseAnalystEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}

  disablePurchaseAnalystEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      purchaseAnalystsService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStatePurchaseAnalystActions.disablePurchaseAnalyst),
        switchMap(({ uuid , filters}) => {
          return purchaseAnalystsService
            .disablePurchaseAnalyst(uuid)
            .pipe(
              map((purchaseAnalyst: PurchaseAnalystInterface) => {
                if (filters) {
                  this.store.dispatch(
                    purchaseAnalystsFeedActions.getPurchaseAnalystsFeed({ filters })
                  );
                } else {
                  this.store.dispatch(
                    purchaseAnalystActions.getPurchaseAnalyst({ uuid })
                  );
                }
                return toggleStatePurchaseAnalystActions.disablePurchaseAnalystSuccess({ purchaseAnalyst });
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 400
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  toggleStatePurchaseAnalystActions.disablePurchaseAnalystFailure()
                );
              })
            );
        })
      );
    }
  );

  enablePurchaseAnalystEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      purchaseAnalystsService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStatePurchaseAnalystActions.enablePurchaseAnalyst),
        switchMap(({ uuid , filters}) => {
          return purchaseAnalystsService
            .enablePurchaseAnalyst(uuid)
            .pipe(
              map((purchaseAnalyst: PurchaseAnalystInterface) => {
                if (filters) {
                  this.store.dispatch(
                    purchaseAnalystsFeedActions.getPurchaseAnalystsFeed({ filters })
                  );
                } else {
                  this.store.dispatch(
                    purchaseAnalystActions.getPurchaseAnalyst({ uuid })
                  );
                }
                return toggleStatePurchaseAnalystActions.enablePurchaseAnalystSuccess({ purchaseAnalyst });
              }),
              catchError(() => {
                return of(
                  toggleStatePurchaseAnalystActions.enablePurchaseAnalystFailure()
                );
              })
            );
        })
      );
    }
  );

  getPurchaseRequestsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      purchaseAnalystsService = inject(PurchaseAnalystsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStatePurchaseAnalystActions.getPurchaseRequest),
        switchMap(({ uuid }) => {
          return purchaseAnalystsService
            .getPurhcaseRequestByPurchaseAnalyst(uuid)
            .pipe(
              map((purchaseRequests: string[]) => {
                if (purchaseRequests?.length > 0) {
                  this.dialog.open(AssociatedElementsModalComponent, {
                    data: {
                      title: 'Deshabilitar Administrador',
                      subtitleAbove:
                        'No puedes dar de baja este administrador debido a que posee Solicitudes de Compra activas.',
                      acceptButton: 'N/A',
                      elementsGroup: purchaseRequests,
                    },
                  });
                }
                return toggleStatePurchaseAnalystActions.getPurchaseRequestSuccess({purchaseRequests})
              }),
              catchError(() => {
                return of(
                  toggleStatePurchaseAnalystActions.getPurchaseRequestFailure()
                );
              })
            );
        })
      );
    },
    {dispatch:true}
  );
}
