<div class="modalContainer">
  <mat-dialog-content class="informationContainer">
    <div class="titleContainer">{{ title }}</div>
    <div class="subtitleContainer">{{ subtitleAbove }}</div>
    <div class="careersToDeleteContainer">
      <div *ngFor="let carrera of elementsGroup">
        <span class="career">{{ carrera }}</span>
        <hr style="margin-bottom: 3px" />
      </div>
    </div>
    <div class="subtitleContainer">{{ subtitleBellow }}</div>
    <div class="subtitleContainer">{{ subtitleQuestion }}</div>
  </mat-dialog-content>
  <mat-dialog-actions class="buttonContainer">
    <button mat-button  *ngIf="acceptButton !== ''"  (click)="onAcceptButtonClick()" class="acceptButton">
      {{acceptButton}}
    </button>
    <button mat-button class="cancelButton" [mat-dialog-close]="true">
      {{cancelButton}}
    </button>
  </mat-dialog-actions>
</div>
