import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { SupplierServices } from 'src/app/pages/supplier/supplier.service';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';
import { supplierActions } from '../../actions/supplier/supplier.actions';

@Injectable()
export class getSupplierEffect {
  constructor() {}

  getSupplierEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      suppliersService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(supplierActions.getSupplier),
        switchMap(({ id }) => {
          return suppliersService.getSupplier(id).pipe(
            map((supplier: SupplierInterface) => {
              return supplierActions.getSupplierSuccess({
                supplier,
              });
            }),
            catchError(() => {
              return of(
                supplierActions.getSupplierFailure()
              );
            })
          );
        })
      );
    }

  );
}

