import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ClientInterface } from "src/app/pages/clients/types/client.interface";
import { ClientsFilterInterface } from "src/app/pages/clients/types/clientsFilter.interface";


export const toggleStateClientActions = createActionGroup({
  source: 'toggle state client',
  events: {
    'Disable Client': props<{
      uuid: string;
      filters: ClientsFilterInterface;
    }>(),
    'Disable Client success': props<{
      client: ClientInterface;
    }>(),
    'Disable Client failure': emptyProps(),

    'Get Purchase Request': props<{ uuid: string }>(),
    'Get Purchase Request success': props<{purchaseRequests: string[]}>(),
    'Get Purchase Request failure': emptyProps(),

    'Enable Client': props<{
      uuid: string;
      filters: ClientsFilterInterface;
    }>(),
    'Enable Client success': props<{
      client: ClientInterface;
    }>(),
    'Enable Client failure': emptyProps(),

  },
});
