
<div class="modalContainer">
  <mat-dialog-content class="sectionContainer">
    <h2 class="titleContainer">Modificar contraseña</h2>
    
    <div class="alertContainer">
      <mat-icon
        aria-hidden="false"
        fontIcon="info"
      ></mat-icon>
      <p class="infoText">
        Para garantizar la seguridad de tu cuenta, es necesario cambiar la contraseña la primera vez que accedes a ella. Después de este cambio inicial, podrás comenzar a utilizar la plataforma.
      </p>
    </div>
    
    <form [formGroup]="changePasswordForm">
      <div class="headerForm">
        <h3 class="subtitleContainer">Ingresá la contraseña actual y creá una nueva</h3>
        <mat-icon
          class="tooltip"
          [matTooltip]="passwordTooltipContent"
          matTooltipClass="custom-tooltip"
          aria-hidden="false"
          fontIcon="help"
        ></mat-icon>
      </div>

      <mat-form-field appearance="outline" class="fullWidth">
        <mat-label>Contraseña actual</mat-label>
        <input 
          matInput 
          formControlName="currentPassword"
          [type]="hideCurrentPassword ? 'password' : 'text'" 
        >
        <button type="button" mat-icon-button matSuffix (click)="hideCurrentPassword = !hideCurrentPassword">
          <mat-icon>{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="fullWidth">
        <mat-label>Nueva contraseña</mat-label>
        <input 
          matInput 
          formControlName="newPassword"
          [type]="hideNewPassword ? 'password' : 'text'" 
        >
        <button type="button" mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword">
          <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('noNumber') && changePasswordForm.get('newPassword').touched">
          La contraseña debe tener al menos un número
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('noSpecial') && changePasswordForm.get('newPassword').touched">
          La contraseña debe tener al menos un carácter especial
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('noUppercase') && changePasswordForm.get('newPassword').touched">
          La contraseña debe tener al menos una letra mayúscula
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('noLength') && changePasswordForm.get('newPassword').touched">
          La contraseña debe tener al menos 8 caracteres
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('isEqualPasswords') && changePasswordForm.get('newPassword').touched">
          No puedes usar la misma contraseña. Ingresá otra.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="fullWidth">
        <mat-label>Confirmación de contraseña</mat-label>
        <input 
          matInput 
          formControlName="confirmPassword"
          [type]="hideConfirmPassword ? 'password' : 'text'" 
        >
        <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
          <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.hasError('passwordsMismatch') && changePasswordForm.get('confirmPassword').touched">
          Las contraseñas no coinciden
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class=" actions-section">
    <button
      mat-button 
      [disabled]="!changePasswordForm.valid"
      class="acceptButton {{ (changePasswordForm.valid) ? 'enabled' : 'disabled' }}"
      (click)="onChangePassword()"
    >
      Confirmar cambios
    </button>

    <button
      mat-button
      class="cancelButton"
      [mat-dialog-close]="true"
    >
      <span style="padding-left: 5px">
        Cancelar
      </span>
    </button>
  </mat-dialog-actions>
</div>