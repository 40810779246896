import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PurchaseAnalystInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalyst.interface";
import { PurchaseAnalystsFilterInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalystFilter.interface";


export const toggleStatePurchaseAnalystActions = createActionGroup({
  source: 'toggle state purchase analyst',
  events: {
    'Disable purchase analyst': props<{
      uuid: string;
      filters: PurchaseAnalystsFilterInterface;
    }>(),
    'Disable purchase analyst success': props<{
      purchaseAnalyst: PurchaseAnalystInterface;
    }>(),
    'Disable purchase analyst failure': emptyProps(),
    // 'Update PurchaseAnalyst failure': props<{errors: BackendErrorsInterface}>(),
    'Get Purchase Request': props<{ uuid: string }>(),
    'Get Purchase Request success': props<{purchaseRequests: string[]}>(),
    'Get Purchase Request failure': emptyProps(),

    'Enable purchase analyst': props<{
      uuid: string;
      filters: PurchaseAnalystsFilterInterface;
    }>(),
    'Enable purchase analyst success': props<{
      purchaseAnalyst: PurchaseAnalystInterface;
    }>(),
    'Enable purchase analyst failure': emptyProps(),
    // 'Update Organizational Unitle failure': props<{errors: BackendErrorsInterface}>(),
  },
});
