import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TypeSupplierInterface } from 'src/app/pages/supplier/types/typeSupplier.interface';


export const typeSupplierActions = createActionGroup({
  source: 'typeSupplier',
  events: {
    'Get typeSupplier': emptyProps(),
    'Get typeSupplier success': props<{
      TypeSupplier: TypeSupplierInterface[];
    }>(),
    'Get typeSupplier failure': emptyProps(),
  },
});
