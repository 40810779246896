import { CommonModule } from '@angular/common';
import { Component, Inject  } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  standalone:true,
  imports: [MatIconModule, CommonModule, MatDialogModule, MatButtonModule],
  selector: 'disable-element-modal',
  templateUrl: './disable-element-modal.component.html',
  styleUrls: ['./disable-element-modal.component.sass']
})

export class DisableElementModalComponent {
  constructor(
    private dialogRef: MatDialogRef<DisableElementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  title:string
  subtitle:string
  subtitleQuestion:string
  acceptButton:string
  cancelButton:string

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.subtitle = this.data.subtitle || '';
    this.subtitleQuestion = this.data.subtitleQuestion || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
  }


  onAcceptButtonClick(): void {
    this.dialogRef.close('accept');
  }

  onCancelButtonClick(): void {
    this.dialogRef.close(true);
  }

}


