import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { TypeProductsService } from 'src/app/pages/type-products/type-product.service';
import { toggleStateTypeProductsActions } from '../../actions/type-products/toggleStateTypeProducts.actions';
import { TypeProducts } from '../../reducers/type-products/updateTypeProducts.reducer';
import { typeProductsAction } from '../../actions/type-products/typeProducts.action';
import { AssociatedElementsModalComponent } from 'src/app/shared/components/associated-elements-modal/associated-elements-modal.component';


@Injectable()
export class toggleStateTypeProductsEffect {
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  disableTypeProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      typeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateTypeProductsActions.disableTypeProducts),
        switchMap(({ id }) => {
          return typeProductsService
            .disableTypeProducts(id)
            .pipe(
              map((typeProducts: TypeProducts) => {
                this.store.dispatch(
                  typeProductsAction.getTypeProductsFeed()
                );
                return toggleStateTypeProductsActions.disableTypeProductsSuccess({typeProducts}
                );
              }),
              catchError(() => {
                return of(
                  toggleStateTypeProductsActions.disableTypeProductsFailure()
                );
              })
            );
        })
      );
    }
  );

  getProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      typeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateTypeProductsActions.getProducts),
        switchMap(({ id }) => {
          return typeProductsService
            .getProductsByType(id)
            .pipe(
              map((products: string[] ) => {
                if (products?.length > 0) {
                  this.dialog.open(AssociatedElementsModalComponent, {
                    data: {
                      title: 'Deshabilitar Tipo de Producto',
                      subtitleAbove:
                        'No puedes dar de baja esta tipo de producto debido a que está vinculado con lo/s siguientes productos.',
                      subtitleBellow:
                        'Desvincula el tipo de producto de estos productos para poder deshabilitarlo.',
                      subtitleQuestion:
                        '¿Deseas visitar la sección de Productos?',
                      elementsGroup: products,
                      redirect: 'productos',
                    },
                  });
                }
                return toggleStateTypeProductsActions.getProductsSuccess({products})
              }),
              catchError(() => {
                return of(
                  toggleStateTypeProductsActions.getProductsFailure()
                );
              })
            );
        })
      );
    },
    {dispatch:true}
  );

  enableTypeProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      typeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateTypeProductsActions.enableTypeProducts),
        switchMap(({ id }) => {
          return typeProductsService
            .enableTypeProducts(id)
            .pipe(
              map((typeProducts: TypeProducts) => {
                this.store.dispatch(
                  typeProductsAction.getTypeProductsFeed()
                );
                return toggleStateTypeProductsActions.enableTypeProductsSuccess({typeProducts});
              }),
              catchError(() => {
                return of(
                );
              })
            );
        })
      );
    }
  );
}
