import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientsFilterInterface } from 'src/app/pages/clients/types/clientsFilter.interface';
import { ClientsResponseInterface } from 'src/app/pages/clients/types/clientsResponse.interface';

export const clientsFeedActions = createActionGroup({
  source: 'clientsFeed',
  events: {
    'Get Clients feed': props<{
      filters: ClientsFilterInterface;
    }>(),
    'Get Clients feed success': props<{
      clientsFeed: ClientsResponseInterface;
    }>(),
    'Get Clients feed failure': emptyProps(),
  },
});
