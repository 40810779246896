import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CountryInterface } from 'src/app/interfaces/country.interface';

export const countriesActions = createActionGroup({
  source: 'countries',
  events: {
    'Get countries': emptyProps(),
    'Get countries success': props<{
      countries: CountryInterface[];
    }>(),
    'Get countries failure': emptyProps(),
  },
});
