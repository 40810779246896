import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { User } from '../User';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';
import { toggleStateSupplierActions } from 'src/app/state/actions/supplier/toggleStateSupplier.actions';
import { SupplierFilterInterface } from 'src/app/pages/supplier/types/supplierFilter.interface';
import { SnackBarWarning } from '../snackbar/snackbar-warning.component';

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  selector: 'enable-suppliers-modal',
  templateUrl: './enable-suppliers-modal.component.html',
  styleUrls: ['./enable-suppliers-modal.component.sass']
})

export class EnableSupplierModalComponent {
  constructor(
    private dialogRef: MatDialogRef<EnableSupplierModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private store: Store,
  ) {}

  title: string;
  subtitleAbove: string;
  subtitleBellow: string;
  subtitleQuestion: string;
  supplier: SupplierInterface;
  acceptButton: string;
  cancelButton: string;
  redirect: string;
  supplierUser: User[];
  supplierId: number;
  filters: SupplierFilterInterface;
  selectedUserIds: Set<string> = new Set<string>(); // Conjunto de IDs seleccionados
  selectedUserIdsArray: string[] = [];

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.subtitleAbove = this.data.subtitleAbove || '';
    this.subtitleBellow = this.data.subtitleBellow || '';
    this.acceptButton = this.data.acceptButton === 'N/A' ? '' : this.data.acceptButton;
    this.cancelButton = this.data.cancelButton || 'Cerrar';
    this.subtitleQuestion = this.data.subtitleQuestion || '';
    this.supplierId = this.data.supplierId || 0;
    this.supplierUser = this.data.supplierUser || [];
    this.redirect = this.data.redirect || [''];
    this.filters = this.data.filters;
  }

  isSelected(userId: string): boolean {
    return this.selectedUserIds.has(userId);
  }

  toggleSelection(userId: string, event: any): void {
    if (event.checked) {
      this.selectedUserIds.add(userId);
      this.selectedUserIdsArray = Array.from(this.selectedUserIds);
    } else {
      this.selectedUserIds.delete(userId);
      this.selectedUserIdsArray = Array.from(this.selectedUserIds);
    }
  }

  onAcceptButtonClick(): void {
    console.log('Selected User IDs:', this.selectedUserIdsArray);

    // Disparo la acción para habilitar el proveedor
    try {
      this.store.dispatch(
        toggleStateSupplierActions.enableSupplier(
          {
            filters: this.filters,
            idSupplier: this.supplierId,
            users: this.selectedUserIdsArray
          }
        )
      );
      this.dialogRef.close('accept');
    } catch (error) {
      this._snackBar.openFromComponent(SnackBarWarning, {
        data: {
          message: 'Ocurrió un error',
          subtitle:
            'Por favor intenta nuevamente más tarde',
        },
        duration: 3000,
        panelClass: ['warning-snackbar'],
      });
    }
  }

  onCancelButtonClick(): void {
    this.dialogRef.close(true);
  }
}


