import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { EmptyStateComponent } from './empty-state/empty-state.component';

@NgModule({
  imports: [CommonModule, MatIconModule, EmptyStateComponent],
  declarations: [

  ],
})
export class ComponentsModule {}
