import { createActionGroup, emptyProps, props } from "@ngrx/store";


export const confirmPasswordAction = createActionGroup({
    source: 'confirm password',
    events: {
      'Confirm password': props<{ email: string; password: string; verificationCode: string }>(),
      'Recovery password success': emptyProps(),
      'Recovery password failure': emptyProps(),
    },
  });