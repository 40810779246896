<div class="dynamic-table mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8 table-unit"
  >
    <ng-container
      class="data-container"
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <p style="margin: 0" *ngIf="dataSource.data.length != 0">
          {{ column === "name" ? "Nombre" : "Nro." }}
        </p>
      </th>
      <td
        (click)="openOrganizationalUnitDetail(element.idOgUnit)"
        class="detail-unit"
        mat-cell
        *matCellDef="let element"
      >
        <span
          [matTooltip]="element[column]"
          [matTooltipShowDelay]="timeTooltip"
          [matTooltipPosition]="position.value!"
          matTooltipClass="title-tooltip"
        >
          {{ element[column] }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="editButtons">
      <th mat-header-cell *matHeaderCellDef>
        <button
          (click)="enabledOrDisabled()"
          [disabled]="!(btnOrgUnit$ | async) && !disabledUnits"
          class="btn-disabled {{
            !(btnOrgUnit$ | async) && !disabledUnits ? 'disabled' : ''
          }}"
        >
          <mat-icon
            aria-hidden="false"
            fontIcon="keyboard_double_arrow_left"
            *ngIf="disabledUnits"
          ></mat-icon>
          <mat-icon
            aria-hidden="false"
            fontIcon="keyboard_double_arrow_right"
            *ngIf="!disabledUnits"
          ></mat-icon>
          <label>{{
            !disabledUnits ? "Ver Deshabilitados" : "Ver Habilitados"
          }}</label>
        </button>
      </th>
      <td mat-cell *matCellDef="let element" class="edit-container">
        <span class="vertical-line"></span>
        <button
          (click)="openModifyOrganizationalUnit(element.idOgUnit)"
          matTooltip="Editar"
          [matTooltipShowDelay]="timeTooltip"
          [matTooltipPosition]="position.value!"
          mat-icon-button
        >
          <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
        </button>

        <button
          mat-icon-button
          class="delete-component"
          *ngIf="!disabledUnits"
          (click)="deleteOrganizationalUnit(element.idOgUnit)"
          matTooltip="Deshabilitar"
          [matTooltipShowDelay]="timeTooltip"
          [matTooltipPosition]="position.value!"
        >
          <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        </button>

        <button
          *ngIf="disabledUnits"
          (click)="enabledOgUnit(element.idOgUnit)"
          mat-icon-button
          class="add-component"
          matTooltip="Habilitar"
          [matTooltipShowDelay]="timeTooltip"
          [matTooltipPosition]="position.value!"
        >
          <mat-icon
            aria-hidden="false"
            fontIcon="add_circle_outline"
          ></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToShow; let i = index"
      [class.disabled-status]="disabledUnits"
      [class.selected-row]="i === selectedRowIndex"
    ></tr>
  </table>
  <ng-container *ngIf="!disabledUnits && dataSource.data.length === 0">
    <app-empty-state [entity]="'unidades organizacionales'"></app-empty-state>
  </ng-container>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  ></mat-paginator>
</div>
