import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { SupplierServices } from 'src/app/pages/supplier/supplier.service';
import { typeSupplierActions } from '../../actions/supplier/supplierType.action';
import { TypeSupplierInterface } from 'src/app/pages/supplier/types/typeSupplier.interface';


@Injectable()
export class getTypeSupplierEffect {
  constructor() {}

  getSupplierEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      suppliersService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(typeSupplierActions.getTypeSupplier),
        switchMap(() => {
          return suppliersService.getTypeSupplier().pipe(
            map((TypeSupplier: TypeSupplierInterface[]) => {
              return typeSupplierActions.getTypeSupplierSuccess({
                TypeSupplier,
              });
            }),
            catchError(() => {
              return of(
                typeSupplierActions.getTypeSupplierFailure()
              );
            })
          );
        })
      );
    }

  );
}
