import { createFeature, createReducer, on } from '@ngrx/store';
import { createCreerAction } from '../../actions/careers/createCareer.action';


export interface CreateCareersState {
  isSubmittingCareerCreate: boolean,
  error: string,
}

export const initialCareerState: CreateCareersState = {
  isSubmittingCareerCreate: false,
  error: null,
};

const createCareerReducer = createFeature({
  name: 'createCareer',
  reducer: createReducer(
    initialCareerState,
    on(createCreerAction.createCareer, (state) => ({
      ...state,
      isSubmittingCareerCreate: false,
    })),
    on(createCreerAction.createCareersSuccess, (state) => ({
      ...state,
      isSubmittingCareerCreate: true,
    })),
    on(createCreerAction.createCareersFailure, (state) => ({
      ...state,
      isSubmittingCareerCreate: false,
      error: 'Error al crear cargar la carrera'
    })),
    on(createCreerAction.getCareerModal, (state) => ({
      ...state,
      isSubmittingCareerCreate: false,
    })),
    on(createCreerAction.getCareersModalSuccess, (state) => ({
      ...state,
      isSubmittingCareerCreate: false,
    })),
    on(createCreerAction.getCareersModalFailure, (state) => ({
      ...state,
      isSubmittingCareerCreate: false,
    })),
  ),
});

export const {
  name: careerReducerKey,
  reducer: createCareersReducer,
  selectIsSubmittingCareerCreate,
  selectError,
} = createCareerReducer;
