import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';
import { ClientFormInterface } from 'src/app/pages/clients/types/clientForm.interface';
import { ClientsFilterInterface } from 'src/app/pages/clients/types/clientsFilter.interface';

export const updateClientActions = createActionGroup({
  source: 'update client',
  events: {
    'Get Client': props<{
      uuid: string;
    }>(),
    'Get Client success': props<{
      client: ClientInterface;
    }>(),
    'Get Client failure': emptyProps(),

    'Update Client': props<{
      request: ClientFormInterface;
      uuid: string;
      isActive: boolean;
      filters: ClientsFilterInterface;
    }>(),
    'Update Client success': props<{
      client: ClientInterface;
    }>(),
    'Update Client failure': emptyProps(),
  },
});
