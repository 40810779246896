<div class="modalContainer">
  <h2 class="title" mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content class="content">
    <div *ngIf="inputActive; else noInputContent">
      <div class="input-component">
        <h3 class="inputActive">Titulo*</h3>
        <input
          [(ngModel)]="name"
          (ngModelChange)="onInputChange()"
          class="inputText"
          type="text"
          placeholder="Título"
          maxlength="70"
        />
        <hr class="inputLine" />
        <mat-error *ngIf="name.length === 70">
          Maximo de caracteres alcanzado (70)
        </mat-error>
      </div>

      <div class="input-component">
        <h3 class="inputActive">Descripción</h3>
        <input
          [(ngModel)]="description"
          (ngModelChange)="onInputChange()"
          class="inputDescription"
          type="text"
          placeholder="Descrpción"
          maxlength="140"
        />
        <hr class="inputLine" />
        <mat-error *ngIf="description.length === 140">
          Maximo de caracteres alcanzado (140)
        </mat-error>
      </div>

    </div>
    <ng-template #noInputContent>
      <div class="input-component" style="display: flex">
        <p class="inputText">{{ name }}</p>
        <button
          (click)="editTypeProduct(id)"
          style="
            outline: none;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
          "
        >
        <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
        </button>
      </div>
      <h3 *ngIf="description" class="inputActive">Descripción</h3>
      <p *ngIf="description" class="inputDescription">{{ description }}</p>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button
    *ngIf=" typeProductStatus == false &&  editDisabledBtn"
    mat-button
    [disabled]="!isAcceptButtonEnabled"
    class="acceptButton {{ isAcceptButtonEnabled ? 'enabled' : 'disabled' }}"
    (click)="onCreateButtonClick(modify, true)"
  >
    Guardar y Habilitar
  </button>
    <button
      *ngIf="inputActive"
      mat-button
      [disabled]="!isAcceptButtonEnabled"
      class="acceptButton {{ isAcceptButtonEnabled ? 'enabled' : 'disabled' }}"
      (click)="onCreateButtonClick(modify)"
    >
      {{ acceptButton }}
    </button>
    <button   mat-button
    [style.width.px]="inputActive ? 172 : 140"
    class="cancelButton"
    (click)="onCancelButtonClick()">
    <div style="display: flex; align-items: center">
      <mat-icon
        *ngIf="!inputActive"
        aria-hidden="false"
        style="transform: scaleX(-1)"
        fontIcon="start"
      ></mat-icon>
      <span style="padding-left: 5px">
        {{ cancelButton }}
      </span>
    </div>
        </button>
  </mat-dialog-actions>
</div>
