<form class="content">
  <h3 class="subtitle">Iniciar sesión</h3>
  <div class="inputsContainer">
    <mat-form-field appearance="outline">
      <mat-label>Correo electrónico</mat-label>
      <input [(ngModel)]="email" name="email" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Contraseña</mat-label>
      <input matInput [(ngModel)]="password" name="password" [type]="hide ? 'password' : 'text'" />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <button
    routerLinkActive="active"
    class="loginButton"
    (click)="login()"
  >
    Iniciar sesión
  </button>
  <a routerLink="/password-recovery">¿Olvidaste tu contraseña?</a>
</form>
