import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormsModule } from '@angular/forms';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { TermsService } from '../term.service';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';


@Component({
  selector: 'app-summary-term',
  templateUrl: './term-summary.component.html',
  styleUrls: ['./term-summary.component.sass'],
  imports: [
    CommonModule, 
    FormsModule,
    MatChipsModule, 
    MatIconModule, 
    MatTooltipModule,
    EmptyStateComponent,
  ],
  standalone: true,
})
export class SummaryTermComponent {
  constructor(
    private termsService: TermsService
  ) {}

  currentPeriod: any = null;
  errorCode?: any = null;

  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  timeTooltip: string = '500'

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }

  async ngOnInit() {
    try {
      await this.termsService.loadCurrentTerm()
      
      this.termsService.currentTerm().subscribe(term => {
        this.currentPeriod = term;
      });
    } catch (error: any) {
      this.errorCode = error
      console.log('Error fetching data:', error);
    }
  }

  async openModifyTerm(idTerm: number) {
    this.termsService.openModifyTerm(idTerm);
  }
}