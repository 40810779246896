import { createFeature, createReducer, on } from "@ngrx/store"
import { toggleStateTypeProductsActions } from "../../actions/type-products/toggleStateTypeProducts.actions"

export interface DisableTypeProductsStateInterface {
  isSubmittingTypeProduct: boolean,
  isLoadingStatus: boolean,
  enableProducts: any,
}

const initialState: DisableTypeProductsStateInterface = {
  isSubmittingTypeProduct: false,
  isLoadingStatus: false,
  enableProducts: null
}

const toggleStateTypeProductsFeature = createFeature({
  name: 'toggleStateTypeProducts',
  reducer: createReducer(
    initialState,
    on(toggleStateTypeProductsActions.disableTypeProducts, (state) => ({
      ...state,
      isSubmittingTypeProduct: true,
    })),
    on(toggleStateTypeProductsActions.disableTypeProductsSuccess, (state) => ({
      ...state,
      isSubmittingTypeProduct: false,
    })),
    on(toggleStateTypeProductsActions.disableTypeProductsFailure, (state) => ({
      ...state,
      isSubmittingTypeProduct: false,
    })),
    on(toggleStateTypeProductsActions.getProducts, (state) => ({
      ...state,
      isLoadingStatus: true,
    })),
    on(
      toggleStateTypeProductsActions.getProductsSuccess,
      (state, action) => ({
        ...state,
        isLoadingStatus: false,
        enableProducts: action.products,
      })
    ),
    on(toggleStateTypeProductsActions.getProductsFailure, (state) => ({
      ...state,
      isLoadingStatus: false,
    })),
    on(toggleStateTypeProductsActions.enableTypeProducts, (state) => ({
      ...state,
      isSubmittingTypeProduct: true,
    })),
    on(toggleStateTypeProductsActions.enableTypeProductsSuccess, (state) => ({
      ...state,
      isSubmittingTypeProduct: false,
    })),
    on(toggleStateTypeProductsActions.enableTypeProductsFailure, (state) => ({
      ...state,
      isSubmittingTypeProduct: false,
    }))
  ),
})

export const {
  name: toggleStateTypeProductsFeatureKey,
  reducer: toggleStateTypeProductsUnitReducer,
  selectIsSubmittingTypeProduct,
  selectIsLoadingStatus,
  selectEnableProducts
} = toggleStateTypeProductsFeature
