import { createAction, props } from "@ngrx/store";

export const logIn = createAction (
    "[Auth] LogIn",
    props<{ email: string; password: string}>(),

)
export const logInSuccess = createAction(
  '[Auth] LogIn Success',
  props<{ clientsFeed: any }>()
);
export const logInFailure = createAction(
  '[Auth] LogIn Failure',
  props<{ error: any }>()
);
