import { Component, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/pages/change-password/change-password.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    MatDialogModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent {
  @Output() toggleSidebar = new EventEmitter<any>();

  // Estos valores deben ser proporcionados por el servicio de autenticación o almacenamiento de estado
  userName = 'Usuario UX UI';
  cuil = '20-33333333-0';
  
  constructor(
    private dialog: MatDialog,
  ) {
  }

  onToggleSidebar() {
    this.toggleSidebar.emit(); // Emite el evento correctamente
  }

  onClickBtn() {
    const dialogRef = this.dialog.open(ChangePasswordComponent);
  }
}
