import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { ProductsFilterInterface } from 'src/app/pages/product/types/productsFilter.interface';
import { ProductsResponseInterface } from 'src/app/pages/product/types/productsResponse.interface';

export const productsAction = createActionGroup({
  source: 'products',
  events: {
    'Get Products feed':props<{
      filters: ProductsFilterInterface;
    }>(),
    'Get Products feed success': props<{
      products: ProductsResponseInterface;
    }>(),
    'Get Products feed failure': emptyProps(),
  },
});
