import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { localityActions } from '../../actions/locality/locality.actions';
import { LocalityInterface } from 'src/app/interfaces/locality.interface';
import { LocalityServices } from 'src/services/locality-service';


@Injectable()
export class getLocalitiesEffect {
  constructor(private readonly actions$: Actions) {}

  getLocalitiesEffect$ = createEffect(
    (actions$ = inject(Actions), localitiesService = inject(LocalityServices)) => {
      return actions$.pipe(
        ofType(localityActions.getLocalities),
        switchMap(() => {
          return localitiesService.getLocalities().pipe(
            map((localities: LocalityInterface[]) => {
              return localityActions.getLocalitiesSuccess({
                localities,
              });
            }),
            catchError(() => {
              return of(localityActions.getLocalitiesFailure());
            })
          );
        })
      );
    }
  );
}
