import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';
import { SupplierFilterInterface } from 'src/app/pages/supplier/types/supplierFilter.interface';


export const toggleStateSupplierActions = createActionGroup({
  source: 'toggle state supplier',
  events: {
    'Enable supplier get modal': props<{
      idSupplier: number;
      filters: SupplierFilterInterface;
    }>(),
    'Enable supplier get modal success': props<{
      supplier: number;
    }>(),
    'Enable supplier get modal failure': emptyProps(),
    
    'Enable supplier': props<{
      idSupplier: number;
      users: string[];
      filters: SupplierFilterInterface;
    }>(),
    'Enable supplier success': emptyProps(),
    'Enable supplier failure': emptyProps(),

    'Disable supplier': props<{
      idSupplier: number;
      filters: SupplierFilterInterface;
    }>(),
    'Disable supplier success': props<{
      supplier: SupplierInterface;
    }>(),
    'Disable supplier failure': emptyProps(),
  },
});