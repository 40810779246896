import { NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
import { HomePageComponent } from './pages/home/home.component';
import { TermComponent } from './pages/term/term.component';
import { ProductComponent } from './pages/product/product.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProductDetailComponent } from './pages/product/product-detail/product-detail.component';
import { isLoggedIn } from 'src/app/state/selectors/auth.selectors';
import { AppState } from './state/app.state';
import { Store } from '@ngrx/store';
import { PageNotFound } from './pages/page-not-found/page-not-found.component';
import { ProductCreateEditComponent } from './pages/product/product-create-edit/product-create-edit.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'password-recovery',
    loadChildren: () =>
      import(
        'src/app/pages/password-recovery/password-recovery.routes'
      ).then((m) => m.routes),
  },
  { path: 'register',
    component: RegisterComponent
  },
  {
    path: 'home',
    component: HomePageComponent, children: []
  },
  {
    path: 'proveedores',
    loadChildren: () =>
      import(
        'src/app/pages/supplier/supplier.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'unidades-organizacionales',
    loadChildren: () =>
      import(
        'src/app/pages/organizational-units/organizational-unit-feed.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'tipo-de-productos',
    loadChildren: () =>
      import(
        'src/app/pages/type-products/type-products.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'periodos',
    component: TermComponent
  },
  {
    path: 'carreras',
    loadChildren: () =>
      import(
        'src/app/pages/careers/careers.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'productos',
    loadChildren: () =>
      import(
        'src/app/pages/product/products.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'clientes',
    loadChildren: () =>
      import(
        'src/app/pages/clients/clients.routes'
      ).then((m) => m.routes),
  },
  {
    path: 'administradores',
    loadChildren: () =>
      import(
        'src/app/pages/purchase-analyst/purchase-analyst.routes'
      ).then((m) => m.routes),
  },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '404', component: isLoggedIn ? PageNotFound : LoginPageComponent },
  { path: '**', component: isLoggedIn ? PageNotFound : LoginPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule implements OnInit {
  isLogged = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.select(isLoggedIn).subscribe((authState) => {
      this.isLogged = authState;
    });
  }
}
