import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppState } from '../../app.state';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { SupplierServices } from 'src/app/pages/supplier/supplier.service';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';
import { SupplierFilterInterface } from 'src/app/pages/supplier/types/supplierFilter.interface';
import { createSupplierActions } from '../../actions/supplier/createSupplier.actions';
import { supplierFeedActions } from '../../actions/supplier/supplierFeed.actions';

@Injectable()
export class createSupplierEffect {
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}

  getCareersEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      createSupplierService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(createSupplierActions.getPositionFeed),
        switchMap(() => {
          return createSupplierService.getPositionUser().pipe(            
            map((position: any) => {
              return createSupplierActions.getPositionFeedSuccess({position});
            }),
            catchError(() => {
              return of(
                createSupplierActions.getPositionFeedFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );

  createSupplierEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      createSupplierService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(createSupplierActions.createSupplier),
        switchMap(({ request }) => {
          return createSupplierService
            .createSupplier(request)
            .pipe(
              map((supplier: SupplierInterface) => {
                this.store.dispatch(
                  supplierFeedActions.getSupplierFeed({
                    filters: <SupplierFilterInterface>{
                      actives: true,
                      page: 0,
                      limit: 10,
                      column: 'updatedAt',
                      order: 'desc',
                    },
                  })
                );

                this.router.navigate(['/proveedores']);

                this._snackBar.openFromComponent(SnackBarSuccess, {
                  data: {
                    message: 'Proveedor creado exitosamente',
                    subtitle:
                      'Podés realizar modificaciones o deshabilitarlo cuando necesites',
                  },
                  panelClass: ['success-snackbar'],
                  duration: 3000,
                });

                return createSupplierActions.createSupplierSuccess(
                  { supplier }
                );
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: errorResponse.error.error,
                    subtitle: errorResponse.error.message
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });

                return of(
                  createSupplierActions.createSupplierFailure()
                );
              })
            );
        })
      );
    },
    { functional: true }
  );
}
