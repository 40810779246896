import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormsModule } from '@angular/forms';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';
import { MyApiService } from 'src/services/connection';
import { TermsService } from '../term.service';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'table-term',
  styleUrls: ['term-table.component.sass'],
  templateUrl: 'term-table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    EmptyStateComponent,
  ],
})
export class TermTable implements AfterViewInit {

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private myApiService: MyApiService,
    private termsService: TermsService,
  ) {}

  data: any[] = [];

  timeTermData: TimeTerm[] = [];
  columsToShow: string[] = ['id', 'name', 'startDateTerm', 'endDateTerm', 'editButtons']
  displayedColumns: string[] = ['id', 'name', 'startDateTerm', 'endDateTerm'];
  dataSource = new MatTableDataSource<TimeTerm>([]);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  timeTooltip: string = '500'

  ngOnChanges() {
    this.displayedColumns = [...this.displayedColumns];
  }

  async ngOnInit() {
    // Load Table Terms
    await this.termsService.reloadTableTerm(this.dataSource)
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getColumnHeader(column: string): string {
    switch (column) {
      case 'startDateTerm':
        return 'Inicio';
      case 'endDateTerm':
        return 'Finalización';
      case 'id':
        return 'Nro';
      case 'name':
        return 'Título';
      default:
        return '';
    }
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }

  // Sort
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async openModifyTerm(idTerm: number) {
    // this.termsService.setEditForm(false);
    this.termsService.openModifyTerm(idTerm);
  }
}

export interface TimeTerm {
  id: number;
  startDateTerm: Date;
  endDateTerm: Date;
  name: string;
}


