import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SupplierFilterInterface } from "src/app/pages/supplier/types/supplierFilter.interface";
import { SupplierResponseInterface } from "src/app/pages/supplier/types/supplierResponse.interface";


export const supplierFeedActions = createActionGroup({
  source: 'slientsFeed',
  events: {
    'Get Supplier feed': props<{
      filters: SupplierFilterInterface;
    }>(),
    'Get Supplier feed success': props<{
      suppliersFeed: SupplierResponseInterface;
    }>(),
    'Get Supplier feed failure': emptyProps(),
  },
});
  