import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { ClientsService } from 'src/app/pages/clients/clients.service';
import { toggleStateClientActions } from '../../actions/clients/toggleStateClient.actions';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';
import { clientsFeedActions } from '../../actions/clients/clientsFeed.actions';
import { clientActions } from '../../actions/clients/client.actions';
import { AssociatedElementsModalComponent } from 'src/app/shared/components/associated-elements-modal/associated-elements-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class toggleStateClientEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {}

  disableClientEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateClientActions.disableClient),
        switchMap(({ uuid, filters }) => {
          return clientsService
            .disableClient(uuid)
            .pipe(
              map((client: ClientInterface) => {
                if (filters) {
                  this.store.dispatch(
                    clientsFeedActions.getClientsFeed({ filters })
                  );
                } else {
                  this.store.dispatch(
                    clientActions.getClient({ uuid })
                  );
                }
                return toggleStateClientActions.disableClientSuccess({ client });
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 400
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  toggleStateClientActions.disableClientFailure()
                );
              })
            );
        })
      );
    }
  );

  enableClientEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateClientActions.enableClient),
        switchMap(({ uuid, filters }) => {
          return clientsService
            .enableClient(uuid)
            .pipe(
              map((client: ClientInterface) => {
                if (filters) {
                  this.store.dispatch(
                    clientsFeedActions.getClientsFeed({ filters })
                  );
                } else {
                  this.store.dispatch(
                    clientActions.getClient({ uuid })
                  );
                }
                return toggleStateClientActions.enableClientSuccess({ client });
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 400
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  toggleStateClientActions.enableClientFailure()
                );
              })
            );
        })
      );
    }
  );

  getPurchaseRequestsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(toggleStateClientActions.getPurchaseRequest),
        switchMap(({ uuid }) => {
          return clientsService
            .getPurhcaseRequestByClient(uuid)
            .pipe(
              map((purchaseRequests: string[]) => {
                if (purchaseRequests?.length > 0) {
                  this.dialog.open(AssociatedElementsModalComponent, {
                    data: {
                      title: 'Deshabilitar Cliente',
                      subtitleAbove:
                        'No puedes dar de baja este cliente debido a que posee Solicitudes de Compra activas.',
                      acceptButton: 'N/A',
                      elementsGroup: purchaseRequests,
                    },
                  });
                }
                return toggleStateClientActions.getPurchaseRequestSuccess({purchaseRequests})
              }),
              catchError(() => {
                return of(
                  toggleStateClientActions.getPurchaseRequestFailure()
                );
              })
            );
        })
      );
    },
    {dispatch:true}
  );
}
