import { createFeature, createReducer, on } from "@ngrx/store"
import { toggleStateOrganizationalUnitActions } from "../../actions/organizational-units/toggleStateOrganizationalUnit.actions"

export interface DisableOrganizationalUnitStateInterface {
    isSubmittingOrgUnit: boolean,
    isLoadingStatus: boolean,
    enableCareer:string[],
  }

const initialState: DisableOrganizationalUnitStateInterface = {
    isSubmittingOrgUnit: false,
    isLoadingStatus: false,
    enableCareer: [],
  }

  const toggleStateOrganizationalUnitFeature = createFeature({
    name: 'toggleStateOrganizationalUnit',
    reducer: createReducer(
      initialState,
      on(toggleStateOrganizationalUnitActions.disableOrganizationalUnit, (state) => ({
        ...state,
        isSubmittingOrgUnit: true,
      })),
      on(toggleStateOrganizationalUnitActions.disableOrganizationalUnitSuccess, (state, action) => ({
        ...state,
        isSubmittingOrgUnit: false,
      })),
      on(toggleStateOrganizationalUnitActions.disableOrganizationalUnitleFailure, (state, action) => ({
        ...state,
        isSubmittingOrgUnit: false,
      })),
      on(toggleStateOrganizationalUnitActions.enableOrganizationalUnit, (state) => ({
        ...state,
        isSubmittingOrgUnit: true,
      })),
      on(toggleStateOrganizationalUnitActions.enableOrganizationalUnitSuccess, (state, action) => ({
        ...state,
        isSubmittingOrgUnit: false,
      })),
      on(toggleStateOrganizationalUnitActions.enableOrganizationalUnitleFailure, (state, action) => ({
        ...state,
        isSubmittingOrgUnit: false,
      })),
      on(toggleStateOrganizationalUnitActions.getCareer, (state) => ({
        ...state,
        isLoadingStatus: true,
    })),
      on(
        toggleStateOrganizationalUnitActions.getCareerSuccess,
        (state, action) => ({
          ...state,
          isLoadingStatus: false,
          enableCareer: action.careers,
        })
      ),
      on(toggleStateOrganizationalUnitActions.getCareerFailure, (state) => ({
        ...state,
        isLoadingStatus: false,
      })),
    ),
  })

  export const {
    name: toggleStateOrganizationalUnitFeatureKey,
    reducer: toggleStateOrganizationalUnitReducer,
    selectIsSubmittingOrgUnit,
    selectEnableCareer,
    selectIsLoadingStatus
  } = toggleStateOrganizationalUnitFeature
