import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, of } from "rxjs";
import { Router } from "@angular/router";
import { productsDetailsAction } from "../../actions/products/productsDetails.action";
import { ProductServices } from "src/app/pages/product/product.service";

@Injectable()
export class getProductsDetailsEffect {
  constructor(private router: Router, private productsService: ProductServices) {}

  getProductsDetailsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
    ) => {
      return actions$.pipe(
        ofType(productsDetailsAction.getProductsDetails),
        switchMap(({ id }) => {
          return this.productsService.getProductsDetails(id).pipe(
            map((products: any) => {
              this.router.navigate([`/productos/${id}`]);
              return productsDetailsAction.getProductsDetailsSuccess({ products });
            }),
            catchError(() => {
              return of(productsDetailsAction.getProductsDetailsFailure());
            })
          );
        })
      );
    },
  );
}
