import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';

import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { recoveryPasswordAction } from '../../actions/password-recovery/passwordRecovery.action';
import { RecoveryPasswordService } from '../../../pages/password-recovery/password-recovery.service';

@Injectable()
export class recoveryPasswordActionEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,

  ) {}
  recoveryPasswordActionEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      recoveryPasswordService = inject(RecoveryPasswordService)
    ) => {
      return actions$.pipe(
        ofType(recoveryPasswordAction.recoveryPassword),
        switchMap(( email ) => {
          return recoveryPasswordService.recoveryPassword(email.email)
            .pipe(
              map((value) => {
  

                return recoveryPasswordAction.recoveryPasswordSuccess()
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrio un error - Mail no encontrado',
                    subtitle:
                       'Este mail no pertenece a un usuario registrado. Por favor ingresa nuevamente los datos.'

                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                    recoveryPasswordAction.recoveryPasswordFailure()
                );
              })
            );
        })
      );
    }
  );
}