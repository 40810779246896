import { createFeature, createReducer, on } from "@ngrx/store"
import { createClientActions } from "../../actions/clients/createClient.actions"

export interface CreateClientStateInterface {
  isSubmittingCreateClient: boolean,
  validationErrors: string,
}

const initialState: CreateClientStateInterface = {
    isSubmittingCreateClient: false,
    validationErrors: null,
  }
  
  const createClientFeature = createFeature({
    name: 'createClient',
    reducer: createReducer(
      initialState,
      on(createClientActions.createClient, (state) => ({
        ...state,
        isSubmittingCreateClient: false,
      })),
      on(createClientActions.createClientSuccess, (state, action) => ({
        ...state,
        isSubmittingCreateClient: true,
      })),
      on(createClientActions.createClientFailure, (state, action) => ({
        ...state,
        isSubmittingCreateClient: false,
        validationErrors: "",
      })),
    ),
  })
  
  export const {
    name: createClientFeatureKey,
    reducer: createClientReducer,
    selectIsSubmittingCreateClient,
    selectValidationErrors,
  } = createClientFeature
