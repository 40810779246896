import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  standalone: true,
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.sass']
})
export class EmptyStateComponent {
  @Input() errorCode: number = 0; 
  @Input() title: string
  @Input() subTitle: string
  @Input() entity: string;
  imagePath: string

  constructor() {
    this.setDefaultValues();
  }

  ngOnChanges() {
    this.updateErrorDetails();
  }

  private setDefaultValues() {
    const defaultEntity = this.entity ? this.entity : "registros"; 
    if (!this.title) this.title = `Sin ${defaultEntity}`;
    if (!this.subTitle) this.subTitle = `Todavía no hay ${defaultEntity} cargadas`;
    this.imagePath = "../../../assets/images/empty-state.svg";
  }

  private updateErrorDetails() {
    switch (this.errorCode) {
      case 401:
        this.title = "No autorizado";
        this.subTitle = "No tienes autorización para acceder a este recurso.";
        this.imagePath = "../../../assets/images/empty-state.svg";
        break;
      case 404:
        this.title = "No encontrado";
        this.subTitle = "No se pudo encontrar el recurso solicitado.";
        this.imagePath = "../../../assets/images/empty-state.svg";
        break;
      case 500:
        this.title = "Error interno del servidor";
        this.subTitle = "Algo salió mal en el servidor.";
        this.imagePath = "../../../assets/images/no-connection.svg";
        break;
      default:
        // If no specific error code matches, use default values
        this.setDefaultValues();
    }
  }
}
