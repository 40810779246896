import { Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';

import { MatTableDataSource } from '@angular/material/table';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Product, selectProducts } from 'src/app/state/reducers/products/products.reducer';
import { productsDetailsAction } from 'src/app/state/actions/products/productsDetails.action';
import { updateProductsAction } from 'src/app/state/actions/products/updateProducts.action';
import { ProductsFilterInterface } from './types/productsFilter.interface';
import { ProductsResponseInterface } from './types/productsResponse.interface';

@Injectable({
  providedIn: 'root'
})

export class ProductServices {
  constructor(
    private myApiService: MyApiService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  dataSource = new MatTableDataSource<any>();

  createProduct(product: any): Observable<Product> {

    const url = `${environment.apiUrl}/product/purchase-analyst`
    return this.http.post<Product>(url, product);
  }

  //GET PRODUCTS WITHOUT FILTERS
  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiUrl}/product`)
  }

  getProductsFeed(filters: ProductsFilterInterface): Observable<ProductsResponseInterface> {
    const { page, limit, column, order } = filters;
    const fullUrl = `${environment.apiUrl}/product/products-by-filter?page=${page}&limit=${limit}&column=${column}&order=${order.toUpperCase()}`;
    return this.http.get<ProductsResponseInterface>(fullUrl).pipe(
      map(response => {
        response.data.forEach(product => {
          product.editorial = product.editorial.name;
        });
        return response;
      })
    );
  }

  getProductsFeed2(
    filters: ProductsFilterInterface
  ): Observable<ProductsResponseInterface> {
    const { page, limit, column, order } = filters;
    const fullUrl = `${environment.apiUrl}/product/products-by-filter?page=${page}&limit=${limit}&column=${column}&order=${order.toUpperCase()}`;
    return this.http.get<ProductsResponseInterface>(fullUrl);
  }

  getProductsDetails(id:number): Observable<Product> {
    return this.http.get<Product>(`${environment.apiUrl}/product/${id}`);
  }

  updateProducts(id:number, products: any): Observable<Product> {
    const url = `${environment.apiUrl}/product/${id}`
    return this.http.patch<Product>(url, products);
  }

  getProductsByType(id:number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/product/type-product/${id}`);
  }

  openProductDetail(id: number) {
    this.store.dispatch(productsDetailsAction.getProductsDetails({id: id}))
  }

  async openModifyProduct(id: number) {
    try {
      this.store.dispatch(updateProductsAction.getProductsDetails({id}))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async loadAuthors(): Promise<void> {
    try {
      const data = await this.myApiService.get('author');
      return data
    } catch (error) {
      console.error('Error fetching type author:', error);
    }
  }

  async loadFormats(): Promise<void> {
    try {
      const data = await this.myApiService.get('format');
      return data
    } catch (error) {
      console.error('Error fetching format:', error);
    }
  }

  async loadLanguages(): Promise<void> {
    try {
      const data = await this.myApiService.get('language');
      return data
    } catch (error) {
      console.error('Error fetching language:', error);
    }
  }

  async loadTypeProducts(): Promise<void> {
    try {
      const data = await this.myApiService.get('type-product/order?orderByCreated=false');
      return data
    } catch (error) {
      console.error('Error fetching type product:', error);
    }
  }

  async loadEditionYears() {
    const currentYear = new Date().getFullYear();
    const yearsArray: Array<any> = [];

    for (let i = currentYear; i >= currentYear - 99; i--) {
      yearsArray.push(i);
    }

    return yearsArray
  }

}
