<div class="modalContainer clientContainer">
  <h2 class="title" mat-dialog-title>{{ title }}</h2>
  <form [formGroup]="clientForm">
    <mat-dialog-content class="content">
      <div *ngIf="inputActive">
        <mat-form-field appearance="fill" class="input-component">
          <mat-label>Nombre</mat-label>
          <input
            matInput
            formControlName="name"
            [maxlength]="30"
            placeholder="Nombre"
            required
            (input)="charactersValidationLetters($event, 'name')"
          >

          <mat-error *ngIf="clientForm.get('name').hasError('required')">
            Este campo es requerido
          </mat-error>
          <mat-hint *ngIf="clientForm.get('name').value?.length >= 30">
            Máximo de caracteres alcanzado (30)
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="input-component">
          <mat-label>Apellido</mat-label>
          <input
            matInput
            formControlName="lastname"
            [maxlength]="30"
            placeholder="Apellido"
            required
            (input)="charactersValidationLetters($event, 'lastname')"
          >

          <mat-error *ngIf="clientForm.get('lastname').hasError('required')">
            Este campo es requerido
          </mat-error>
          <mat-hint *ngIf="clientForm.get('lastname').value?.length >= 30">
            Máximo de caracteres alcanzado (30)
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="input-component">
          <mat-label>Correo</mat-label>
          <input
            matInput
            formControlName="email"
            [maxlength]="50"
            placeholder="correo@correo.com"
            required [readonly]="modify"
          >

          <mat-error *ngIf="clientForm.get('email').hasError('required')">
            Este campo es requerido
          </mat-error>
          <mat-hint *ngIf="clientForm.get('email').value?.length >= 50">
            Máximo de caracteres alcanzado (50)
          </mat-hint>
          <mat-error *ngIf="clientForm.get('email').errors?.['pattern']">
            Ingresá un correo válido
          </mat-error>
        </mat-form-field>

        <div class="phone-number-client-class">
          <app-phone-number [dataForm]="clientForm"></app-phone-number>
        </div>

        <mat-form-field class="input-component-unit">
          <mat-label>Unidad Organizacional</mat-label>
          <mat-chip-grid #chipGrid aria-label="Unidad Organizacional">
            <mat-chip-row *ngFor="let unit of unitSelected" (removed)="remove(unit)">
              {{unit}}
              <button matChipRemove [attr.aria-label]="'remove ' + unit">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            placeholder="Unidad Organizacional"
            #unitInput
            [maxlength]="45"
            [formControl]="unitCtrl"
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            (blur)="addOnBlur()"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let autor of filteredUnit | async" [value]="autor">
              {{autor}}
            </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="unitCtrl.value?.length >= 45">
            Máximo de caracteres alcanzado (45)
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="input-component">
          <mat-label>País</mat-label>
          <mat-select formControlName="countryName" placeholder="País">
            <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="actions">
      <button
        mat-button
        type="button"
        *ngIf="clientActive == false"
        [disabled]="!isAcceptButtonEnabled  || !formModified"
        class="acceptButton {{
          (isAcceptButtonEnabled && formModified) ? 'enabled' : 'disabled'
        }}"
        (click)="onCreateButtonClick(modify, true)"
      >
        Guardar y Habilitar
      </button>

      <button
        *ngIf="inputActive"
        mat-button
        type="submit"
        [disabled]="!isAcceptButtonEnabled  || !formModified"
        class="acceptButton {{
          (isAcceptButtonEnabled && formModified) ? 'enabled' : 'disabled'
        }}"
        (click)="onCreateButtonClick(modify)"
      >
        {{ acceptButton }}
      </button>

      <button
        mat-button
        [style.width.px]="inputActive ? 172 : 140"
        class="cancelButton"
        type="button"
        (click)="onCancelButtonClick()"
      >
        <div style="display: flex; align-items: center">
          <mat-icon
            *ngIf="!inputActive"
            aria-hidden="false"
            style="transform: scaleX(-1)"
            fontIcon="start"
          ></mat-icon>
          <span style="padding-left: 5px">{{ cancelButton }}</span>
        </div>
      </button>

    </mat-dialog-actions>
  </form>
</div>

