import { createFeature, createReducer, on } from '@ngrx/store';
import { updateCarrerAction } from '../../actions/careers/updateCareer.action';


export interface UpdateCareersState {
  isLoading: boolean;
  isSubmittingUpdateCareer: boolean;
  error: any;
  careerStatus:any;
}

export interface Careers {
  cod: string;
  name: string;
  description: string;
  active: string;
  career_level: string;
  career_category: string;
  orgUnitId: number;
}

export const initialCareerState: UpdateCareersState = {
  isLoading: false,
  error: false,
  isSubmittingUpdateCareer: false,
  careerStatus: null,
};

const updateCareersReducer = createFeature({
  name: 'updateCareer',
  reducer: createReducer(
    initialCareerState,
    on(updateCarrerAction.getCareerDetails, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(updateCarrerAction.getCareersDetailsSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      careerStatus: action.career.active
    })),
    on(updateCarrerAction.getCareersDetailsFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(updateCarrerAction.updateCareer, (state) => ({
      ...state,
      isSubmittingUpdateCareer : false,
    })),
    on(updateCarrerAction.updateCareersSuccess, (state,action) => ({
      ...state,
      careerStatus: action.career.active,
      isSubmittingUpdateCareer: true,
    })),
    on(updateCarrerAction.updateCareersFailure, (state, action) => ({
      ...state,
      isSubmittingUpdateCareer: false,
      validationErrors: '',
    })),
  ),
});

export const {
  name: updateCareerKey,
  reducer: updateCareerReducer,
  selectError,
  selectCareerStatus,
  selectIsSubmittingUpdateCareer,
} = updateCareersReducer;
