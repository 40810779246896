import { CommonModule } from '@angular/common';
import { Component, Inject  } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { CareersService } from 'src/app/pages/careers/careers.service';

@Component({
  standalone:true,
  imports: [MatIconModule, CommonModule, MatDialogModule, MatButtonModule],
  selector: 'app-not-saved-modal',
  templateUrl: './not-saved-modal.component.html',
  styleUrls: ['./not-saved-modal.component.sass']
})

export class NotSavedModalComponent {
  constructor(
    private dialogRef: MatDialogRef<NotSavedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationalUnitsService: OrganizationalUnitsService,
    private careersService: CareersService,
    ) {}

  title:string ="¡Espera un momento!"
  subtitle:string ="No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos."
  subtitleQuestion:string ="¿Deseas salir sin guardar?"
  acceptButton:string="Si, descartar"
  cancelButton:string="Cancelar"

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
  }


  onAcceptButtonClick(): void {
    this.organizationalUnitsService.disabledEdit = false
    this.careersService.disabledEdit = false
    this.dialogRef.close('accept');
  }

  onCancelButtonClick(): void {
    this.dialogRef.close(true);
  }

}


