import { createAction, props } from '@ngrx/store';
import { Terms, CurrentTerm } from '../reducers/term.reducer';

export const loadTerms = createAction('[Terms] Load Terms');
export const loadTermsSuccess = createAction('[Terms] Load Terms Success', props<{ terms: Terms }>());
export const loadTermsFailure = createAction('[Terms] Load Terms Failure', props<{ error: any }>());

export const loadCurrentTerm = createAction('[CurrentTerm] Load Current Term');
export const loadCurrentTermSuccess = createAction('[CurrentTerm] Load Current Terms Success', props<{ currentTerm: CurrentTerm }>());
export const loadCurrentTermFailure = createAction('[CurrentTerm] Load Current Terms Failure', props<{ error: any }>());

