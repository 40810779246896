import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { createOrganizationalUnitActions } from '../../actions/organizational-units/createOrganizationalUnit.actions';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { organizationalUnitFeedActions } from '../../actions/organizational-units/organizationalUnitFeed.actions';
import { Store } from '@ngrx/store';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';

@Injectable()
export class createOrganizationalUnitffect {
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store
  ) {}
  createOrganizationalUnitffect$ = createEffect(
    (
      actions$ = inject(Actions),
      createOrganizationalUnitService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(createOrganizationalUnitActions.createOrganizationalUnit),
        switchMap(({ request }) => {
          return createOrganizationalUnitService
            .createOrganizationalUnit(request)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                this.store.dispatch(
                  organizationalUnitFeedActions.getOrganizationalUnitFeed()
                );
                this._snackBar.openFromComponent(SnackBarSuccess, {
                  data: {
                    message: 'Unidad organizacional creada exitosamente',
                    subtitle:
                      'Podés realizar modificaciones o deshabilitarla cuando necesites',
                  },
                  panelClass: ['success-snackbar'],
                  duration: 3000,
                });
                return createOrganizationalUnitActions.createOrganizationalUnitSuccess(
                  { organizationalUnit }
                );
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 409
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  createOrganizationalUnitActions.createOrganizationalUnitFailure()
                );
              })
            );
        })
      );
    }
  );
}
