import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LocalityInterface } from 'src/app/interfaces/locality.interface';

export const localityActions = createActionGroup({
  source: 'localities',
  events: {
    'Get localities': emptyProps(),
    'Get localities success': props<{
      localities: LocalityInterface[];
    }>(),
    'Get localities failure': emptyProps(),
  },
});
