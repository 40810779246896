import { createFeature, createReducer, on } from "@ngrx/store"
import { updateOrganizationalUnitActions } from "../../actions/organizational-units/updateOrganizationalUnit.actions"
import { OrganizationalUnitInterface } from "src/app/shared/types/organizationalUnit.interface"

export interface UpdateOrganizationalUnitStateInterface {
    organizationalUnit: OrganizationalUnitInterface,
    isLoading: boolean,
    isSubmittingUpdateUnit: boolean,
    validationErrors: string,
    orgUnitStatus: any;
  }

const initialState: UpdateOrganizationalUnitStateInterface = {
    organizationalUnit: null,
    isLoading: false,
    isSubmittingUpdateUnit: false,
    validationErrors: null,
    orgUnitStatus: null,
  }

  const updateOrganizationalUnitFeature = createFeature({
    name: 'updateOrganizationalUnit',
    reducer: createReducer(
      initialState,
      on(updateOrganizationalUnitActions.getOrganizationalUnit, (state) => ({
        ...state,
        isLoading: true,
      })),
      on(updateOrganizationalUnitActions.getOrganizationalUnitSuccess, (state, action) => ({
        ...state,
        isLoading: false,
        organizationalUnit: action.organizationalUnit,
        orgUnitStatus: action.organizationalUnit.active,
      })),
      on(updateOrganizationalUnitActions.getOrganizationalUnitFailure, (state) => ({
        ...state,
        isLoading: false,
      })),
      on(updateOrganizationalUnitActions.updateOrganizationalUnit, (state) => ({
        ...state,
        isSubmittingUpdateUnit: false,
      })),
      on(updateOrganizationalUnitActions.updateOrganizationalUnitSuccess, (state, action) => ({
        ...state,
        organizationalUnit: action.organizationalUnit,
        isSubmittingUpdateUnit: true,
        orgUnitStatus: action.organizationalUnit.active,
      })),
      on(updateOrganizationalUnitActions.updateOrganizationalUnitFailure, (state, action) => ({
        ...state,
        isSubmittingUpdateUnit: false,
        validationErrors: '',
      })),
    ),
  })

  export const {
    name: updateOrganizationalUnitFeatureKey,
    reducer: updateOrganizationalUnitReducer,
    selectIsSubmittingUpdateUnit,
    selectValidationErrors,
    selectIsLoading,
    selectOrganizationalUnit,
    selectOrgUnitStatus,
  } = updateOrganizationalUnitFeature
