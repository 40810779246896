import { CommonModule } from '@angular/common';
import { Component} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderTableComponent } from './organizational-unit-header/organizational-unit-header.component';
import { OrganizationalUnitTable } from './organizational-unit-table/organizationalUnit-table.component';
import { NotSavedModalComponent } from '../../shared/components/not-saved-modal/not-saved-modal.component';
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';

@Component({
  standalone: true,
  selector: 'app-organizational-units',
  templateUrl: './organizational-units.component.html',
  styleUrls: ['./organizational-units.component.sass'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    HeaderTableComponent,
    OrganizationalUnitTable,
    NotSavedModalComponent,
    EmptyStateComponent,
  ],
})
export class OrganizationalUnitsComponent {
  constructor() {}
}
