import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent {
  @Input() collapsed = false;
  showFiller = false;

  pages = [
    { name: 'Inicio', icon: 'home', slug: "home" },
    { name: 'Solicitudes de Compra', icon: 'local_offer', slug: "solicitud-de-compra" },
    { name: 'Solicitudes de Cotización', icon: 'wysiwyg', slug: "solicitud-de-cotización" },
    { name: 'Ordenes de Compra', icon: 'receipt', slug: "ordenes-de-compra" },
    { name: 'Facturas', icon: 'description', slug: "facturas" },
    { name: 'Proveedores', icon: 'groups', slug: "proveedores" },
    {
      name: 'Personas',
      icon: 'people',
      view: false,
      children: [
        { name: 'Clientes', icon: 'person_outline', slug: "clientes" },
        { name: 'Administradores', icon: 'account_circle', slug: "administradores" },
      ],
    },
    {
      name: 'Soporte',
      icon: 'inventory_2',
      view: false,
      children: [
        { name: 'Productos', icon: 'inventory_2', slug: "productos" },
        { name: 'Tipo de Productos', icon: 'inventory_2', slug: "tipo-de-productos" },
        { name: 'Unidades Organizacional', icon: 'inventory_2', slug: "unidades-organizacionales" },
        { name: 'Carreras', icon: 'inventory_2', slug: "carreras" },
      ],
    },
    { name: 'Periodos', icon: 'history', slug: "periodos" },
  ];
}
