import { createPurchaseAnalystffect } from './state/effects/purchase-analyst/createPurchaseAnalyst.effects';
import { CommonModule } from '@angular/common';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule, provideState, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import { ROOT_REDUCERS } from './state/app.state';
import { sessionStorageSyncReducer } from './state/persistence';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';

import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';

import { LoginPageComponent } from './pages/login/login.component';
import { OrganizationalUnitsComponent } from './pages/organizational-units/organizational-units.component';

import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';

import { HeaderTableComponent } from 'src/app/pages/organizational-units/organizational-unit-header/organizational-unit-header.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import * as OrganizationalUnitDetailEffect from './state/effects/organizational-units/organizationalUnit.effects';
import * as OrganizationalUnitFeedEffect from './state/effects/organizational-units/organizationalUnits.effects';
import * as CreateOrganizationalUnitEffect from './state/effects/organizational-units/createOrganizationalUnit.effects';
import * as UpdateOrganizationalUnitEffect from './state/effects/organizational-units/updateOrganizationalUnit.effects';
import * as GetCareersEffect from './state/effects/careers/careers.effects'
import * as GetCareerEffect from './state/effects/careers/careerDetails.effects'
import * as UpdateCareerEffect from './state/effects/careers/updateCareer.effects'
import * as CreateCareerEffect from './state/effects/careers/createCareer.effects'
import * as ToggleStateOrganizationalUnitEffect from './state/effects/organizational-units/toggleStateOrganizationalUnit.effects';
import * as RecoveryPasswordActionEffect  from './state/effects/password-recovery/passwordRecovery.effects';
import * as RecoveryChangePasswordActionEffect from './state/effects/password-recovery/confirmPassword.effects';
import * as ToggleStateCareerEffect from './state/effects/careers/toggleStateCareer.effects'
import * as CreateClientEffect from './state/effects/clients/createClient.effects';
import * as CountriesEffect from './state/effects/countries/countries.effects';
import * as LocalitiesEffect from './state/effects/localities/localities.effect';
import * as UpdateClientEffect from './state/effects/clients/updateClient.effects';


import * as SupplierEffect from './state/effects/suppliers/supplier.effects';
import * as SupplierFeedEffect from './state/effects/suppliers/supplierFeed.effects';
import * as ToggleStateSupplierEffect from './state/effects/suppliers/toggleStateSupplier.effect';
import * as TypeSupplierEffect from './state/effects/suppliers/supplierType.effects';
import * as yearsEffect from './state/effects/suppliers/years.effects';

import * as ToggleStateClientEffect from './state/effects/clients/toggleStateClient.effects';
import * as ClientEffect from './state/effects/clients/client.effects'
import * as ClientFeedEffect from './state/effects/clients/clientsFeed.effects'
import * as CreateSupplierEffect from './state/effects/suppliers/createSupplier.effects';
import { provideHttpClient } from '@angular/common/http';
import * as PurchaseAnalystEffect from './state/effects/purchase-analyst/purchaseAnalyst'
import * as CreatePurchaseAnalystEffect from './state/effects/purchase-analyst/createPurchaseAnalyst.effects'
import * as PurchaseAnalystFeedEffect from './state/effects/purchase-analyst/purchaseAnalystsFeed.effects'
import * as ToggleStatePurchaseAnalystEffect from './state/effects/purchase-analyst/toggleStatePurchaseAnalyst.effects'
import * as UpdatePurchaseAnalystEffect from './state/effects/purchase-analyst/updatePurchaseAnalyst.effects'

import { PhoneNumberComponent } from './shared/components/phone-number/phone-number.component';

import * as CreateTypeProductsEffect from './state/effects/type-products/createTypeProducts.effects'
import * as ToggleStateTypeProductsEffect from './state/effects/type-products/toggleStateTypeProducts.effects'
import * as GetTypeProductsEffect from './state/effects/type-products/typeProducts.effects'
import * as GetTypeProductsDetailsEffect from './state/effects/type-products/typeProductsDetails.effects'
import * as UpdateTypeProductsEffect from './state/effects/type-products/updateTypeProducts.effects'

import * as CreateProductsEffect from './state/effects/products/createProducts.effects'
import * as GetProductsEffect from './state/effects/products/products.effects'
import * as GetProductsDetailsEffect from './state/effects/products/productsDetails.effects'
import * as UpdateProductsEffect from './state/effects/products/updateProducts.effects'

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: [
        sessionStorageSyncReducer([
          'test',
          'auth',
          'organizationalUnit',
          'careers',
          'products',
        ]),
      ],
    }),
    EffectsModule.forRoot([
      // Session
      RecoveryPasswordActionEffect.recoveryPasswordActionEffect,
      RecoveryChangePasswordActionEffect.recoveryChangePasswordActionEffect,

      // Organizational Units
      OrganizationalUnitDetailEffect.getOrganizationalUnitDetailEffect,
      OrganizationalUnitFeedEffect.getOrganizationalUnitFeedEffect,
      CreateOrganizationalUnitEffect.createOrganizationalUnitffect,
      UpdateOrganizationalUnitEffect.updateOrganizationalUnitEffect,
      ToggleStateOrganizationalUnitEffect.toggleStateOrganizationalUnitEffect,
      ToggleStateClientEffect.toggleStateClientEffect,
      RecoveryPasswordActionEffect.recoveryPasswordActionEffect,
      RecoveryChangePasswordActionEffect.recoveryChangePasswordActionEffect,

      // Carrers
      GetCareersEffect.getCareersEffect, // Feed de carreras
      CreateCareerEffect.createCareerEffect,
      UpdateCareerEffect.updateCareerEffect,
      GetCareerEffect.getCareerEffect,
      ToggleStateCareerEffect.toggleStateCareerEffect,

      // Supplier
      SupplierEffect.getSupplierEffect,
      SupplierFeedEffect.getSupplierFeedEffect,
      CreateSupplierEffect.createSupplierEffect,
      ToggleStateSupplierEffect.toggleStateSupplierEffect,
      TypeSupplierEffect.getTypeSupplierEffect,
      yearsEffect.getYearsEffect,

      // Client
      ClientEffect.getClientEffect,
      ClientFeedEffect.getClientsEffect,
      CreateClientEffect.createClientffect,
      UpdateClientEffect.updateClientEffect,

      // Type of products
      GetTypeProductsEffect.getTypeProductsEffect,
      CreateTypeProductsEffect.createTypeProductsEffect,
      UpdateTypeProductsEffect.updateTypeProductsEffect,
      GetTypeProductsDetailsEffect.getTypeProductsDetailsEffect,
      ToggleStateTypeProductsEffect.toggleStateTypeProductsEffect,

      // Products
      GetProductsEffect.getProductsEffect,
      CreateProductsEffect.createProductsEffect,
      UpdateProductsEffect.updateProductsEffect,
      GetProductsDetailsEffect.getProductsDetailsEffect,


      CountriesEffect.getCountriesEffect,
      LocalitiesEffect.getLocalitiesEffect,


       // Purchase Analyst
       PurchaseAnalystEffect.getPurchaseAnalystEffect,
       PurchaseAnalystFeedEffect.getPurchaseAnalystsEffect,
       ToggleStatePurchaseAnalystEffect.toggleStatePurchaseAnalystEffect,
       CreatePurchaseAnalystEffect.createPurchaseAnalystffect,
       UpdatePurchaseAnalystEffect.updatePurchaseAnalystEffect,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    SharedModule,
    LoginPageComponent,
    CommonModule,
    MatSidenavModule,
    PhoneNumberComponent,
    NavbarComponent,
    SidebarComponent,
    MatButtonModule,
    OrganizationalUnitsComponent,
    MatInputModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderTableComponent,
    MatNativeDateModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(),
    provideStore(ROOT_REDUCERS, {
      metaReducers: [
        sessionStorageSyncReducer([
          'test',
          'auth',
          'organizationalUnit',
          'careers',
          'products',
        ]),
      ],
    }),
    provideEffects([]),
    provideStoreDevtools(),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, // Lenguaje
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, // Opcional: usar UTC
  ],
})
export class AppModule {}
