import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { SupplierServices } from 'src/app/pages/supplier/supplier.service';
import { toggleStateSupplierActions } from '../../actions/supplier/toggleStateSupplier.actions';
import { supplierFeedActions } from '../../actions/supplier/supplierFeed.actions';
import { EnableSupplierModalComponent } from 'src/app/shared/components/enable-suppliers-modal/enable-suppliers-modal.component';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';
import { SnackBarSuccess } from 'src/app/shared/components/snackbar/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { supplierActions } from '../../actions/supplier/supplier.actions';


@Injectable()
export class toggleStateSupplierEffect {
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>,
  ) {}

  enableSupplierModalEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      supplierService = inject(SupplierServices),
      dialog = inject(MatDialog) // Inyectar el servicio de diálogo
    ) => {
      return actions$.pipe(
        ofType(toggleStateSupplierActions.enableSupplierGetModal),
        switchMap(({ idSupplier, filters }) => {
          return supplierService.getSupplier(idSupplier).pipe(
            switchMap((supplier: SupplierInterface) => {
              const dialogRef = dialog.open(EnableSupplierModalComponent, {
                data: {
                  title: 'Habilitar Proveedor',
                  filters,
                  supplierId: supplier.id,
                  supplierUser: supplier.users,
                  subtitleAbove: 'Estás por dar de alta un Proveedor.\nPor favor seleccioná el/los usuarios que se van a habilitar para el mismo.',
                  acceptButton: 'Confirmar',
                  cancelButton: 'Cancelar',
                },
                autoFocus: false
              });
  
              return dialogRef.afterClosed().pipe(
                switchMap(result => {
                  if (result === 'accept') {
                    this._snackBar.openFromComponent(SnackBarSuccess, {
                      data: {
                        message: 'Proveedor habilitado exitosamente',
                      },
                      panelClass: ['success-snackbar'],
                      duration: 3000,
                    });
                  }
                  return of(toggleStateSupplierActions.enableSupplierGetModalSuccess({ supplier: idSupplier }));
                }),
                catchError(() => of(toggleStateSupplierActions.enableSupplierGetModalFailure()))
              );
            }),
            catchError(() => of(toggleStateSupplierActions.enableSupplierGetModalFailure()))
          );
        })
      );
    }
  );

  enableSupplierEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      supplierService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(toggleStateSupplierActions.enableSupplier),
        switchMap(({ idSupplier, users, filters }) => {
          return supplierService.enableSupplier( idSupplier, users ).pipe(
            map(() => {
              if (filters) {
                this.store.dispatch(
                  supplierFeedActions.getSupplierFeed({filters})
                );
              } else {
                this.store.dispatch(
                  supplierActions.getSupplier({id: idSupplier})
                );
              }
              return toggleStateSupplierActions.enableSupplierSuccess();
            }),
            catchError(() => {
              return of(toggleStateSupplierActions.enableSupplierFailure);
            })
          );
        })
      );
    }
  );

  disableSupplierEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      supplierService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(toggleStateSupplierActions.disableSupplier),
        switchMap(({ idSupplier ,filters}) => {
          return supplierService
            .disableSupplier(idSupplier)
            .pipe(
              map((supplier: SupplierInterface) => {
                if (filters) {
                  this.store.dispatch(
                    supplierFeedActions.getSupplierFeed({filters})
                  );
                } else {
                  this.store.dispatch(
                    supplierActions.getSupplier({id: idSupplier})
                  );
                }
                return toggleStateSupplierActions.disableSupplierSuccess({supplier});
              }),
              catchError(() => {
                return of(toggleStateSupplierActions.disableSupplierFailure);
              })
            );
        })
      );
    }
  );

}
