import { ActionReducerMap } from "@ngrx/store";
import { AuthState, authReducer } from "./reducers/auth.reducer"
import { TermState, termReducer } from "./reducers/term.reducer";

export interface AppState {
    test: AuthState,
    auth: AuthState,
    term: TermState
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
    test: authReducer,
    auth: authReducer,
    term: termReducer,
}
//TODO : eliminar y refactorizar
