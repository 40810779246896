import { createActionGroup, emptyProps, props } from "@ngrx/store";


export const recoveryPasswordAction = createActionGroup({
    source: 'recovery password',
    events: {
      'Recovery password': props<{
        email: string;
      }>(),
      'Recovery password success': emptyProps(),
      'Recovery password failure': emptyProps(),
    },
  });