import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { Products } from '../../reducers/products/updateProducts.reducer';

export const createProductsAction = createActionGroup({
  source: 'create products',
  events: {
    'Create Products': props<{ request: Products }>(),
    'Create Products success': props<{products: Products}>(),
    'Create Products failure': emptyProps(),
  },
});
