import { createReducer, on } from '@ngrx/store';
import * as TermsActions from '../actions/term.actions';

export interface TermState {
  terms: Terms;
  currentTerm: CurrentTerm;
  loading: boolean;
  error: any;
}

export interface Terms {
  currentTerm: TermDetail;
  futureTerms: TermDetail[];
  historicalTerms: TermDetail[];
}

export interface TermDetail {
  id: number;
  name: string;
  startDateTerm: any;
  endDateTerm: any;
  active: boolean;
  description: string;
}

export interface CurrentTerm {
  id: number;
  name: string;
  startDateTerm: any;
  endDateTerm: any;
  active: boolean;
  description: string;
  timeTerm: [
    {
      id: number,
      startDate: any,
      endDate: any,
      orderTime: number,
      typeTimeTerm: {
        id: number,
        name: string,
        shortName: string,
        description: string,
        orderType: number
      }
    }
  ]
}

export const initialTermsState: TermState = {
  terms: {
    currentTerm: null,
    futureTerms: [],
    historicalTerms: []
  },
  currentTerm: {
    id: null,
    name: null,
    startDateTerm: null,
    endDateTerm: null,
    active: null,
    description: null,
    timeTerm: [
      {
        id: null,
        startDate: null,
        endDate: null,
        orderTime: null,
        typeTimeTerm: {
          id: null,
          name: null,
          shortName: null,
          description: null,
          orderType: null
        }
      }
    ]
  },
  loading: false,
  error: null
};


export const termReducer = createReducer(
  initialTermsState,
  on(TermsActions.loadTerms, state => ({ ...state, loading: true })),
  on(TermsActions.loadTermsSuccess, (state, { terms }) => ({ ...state, terms, loading: false })),
  on(TermsActions.loadTermsFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(TermsActions.loadCurrentTerm, state => ({ ...state, loading: true })),
  on(TermsActions.loadCurrentTermSuccess, (state, { currentTerm }) => ({ ...state, currentTerm, loading: false })),
  on(TermsActions.loadCurrentTermFailure, (state, { error }) => ({ ...state, error, loading: false })),
);
