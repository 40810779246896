import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { TermState } from "../reducers/term.reducer";

export const selectTermState = (state: AppState) =>
  state.term;

export const selectAllTerms = createSelector(
  selectTermState,
  (state: TermState) => state.terms
);

export const currentTerm = createSelector(
  selectTermState,
  (state: TermState) => state.currentTerm
);