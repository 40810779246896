import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SupplierInterface } from 'src/app/pages/supplier/types/supplier.interface';

export const supplierActions = createActionGroup({
  source: 'supplier',
  events: {
    'Get Supplier': props<{ id: number }>(),
    'Get Supplier success': props<{
      supplier: SupplierInterface;
    }>(),
    'Get Supplier failure': emptyProps(),
  },
});
