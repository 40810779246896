import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';
import { ClientModal } from 'src/app/pages/clients/client-modal/client-modal.component';
import { ClientsService } from 'src/app/pages/clients/clients.service';
import { clientActions } from '../../actions/clients/client.actions';

@Injectable()
export class getClientEffect {
  constructor(private readonly actions$: Actions, public dialog: MatDialog) {}

  getClientEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      clientsService = inject(ClientsService)
    ) => {
      return actions$.pipe(
        ofType(clientActions.getClient),
        switchMap(({ uuid }) => {
          return clientsService.getClient(uuid).pipe(
            map((client: ClientInterface) => {
              return clientActions.getClientSuccess({
                client,
              });
            }),
            catchError(() => {
              return of(
                clientActions.getClientFailure()
              );
            })
          );
        })
      );
    }

  );
}

