import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { ProductServices } from "src/app/pages/product/product.service";
import { updateProductsAction } from "../../actions/products/updateProducts.action";
import { productsAction } from "../../actions/products/products.action";
import { Router } from "@angular/router";
import { ProductsFilterInterface } from "src/app/pages/product/types/productsFilter.interface";

@Injectable()
export class updateProductsEffect {
  constructor(
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private router: Router
  ){}

  getProductEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getProductsService = inject(ProductServices)
    ) => {
      return actions$.pipe(
        ofType(updateProductsAction.getProductsDetails),
        switchMap(({ id }) => {
          return getProductsService.getProductsDetails(id).pipe(
            map((products: any) => {
              this.router.navigate([`/productos/modificar/${id}`]);
              return updateProductsAction.getProductsDetailsSuccess({ products });
            }),
            catchError(() => {
              return of(updateProductsAction.getProductsDetailsFailure());
            })
          );
        })
      );
    },
  );


  updateProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getProductsService = inject(ProductServices)
    ) => {
      return actions$.pipe(
        ofType(updateProductsAction.updateProducts),
        switchMap(({id, request}) => {
          return getProductsService
          .updateProducts(id, request)
          .pipe(
            map((products: any) => {
              this.store.dispatch(
                productsAction.getProductsFeed(
                  {
                    filters: <ProductsFilterInterface>{
                      page: 0,
                      limit: 10,
                      column: 'updatedAt',
                      order: 'desc',
                    },
                  }
                )
              );
              return updateProductsAction.updateProductsSuccess({products});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                updateProductsAction.updateProductsFailure()
              );
            })
          );
        })
      );
    },
  );
}
