import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { CareersService } from "src/app/pages/careers/careers.service";
import { carrerDetailsAction } from "../../actions/careers/careerDetails.action";
import { MatDialog } from "@angular/material/dialog";
import { createCreerAction } from "../../actions/careers/createCareer.action";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarSuccess } from "src/app/shared/components/snackbar/snackbar-success.component";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { carrersAction } from "../../actions/careers/careers.action";
import { OrganizationalUnitInterface } from "src/app/shared/types/organizationalUnit.interface";
import { MyApiService } from "src/services/connection";
import { CareersModal } from "src/app/pages/careers/careers-modal/careers-modal.component";

@Injectable()
export class createCareerEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private myApiService: MyApiService,
    private store: Store<AppState>
  ){}

  categories:any;
  levels:any;
  dataOrganizationalUnits:OrganizationalUnitInterface[];

  async getLevels(){
    this.levels = await this.myApiService.get('career-level');
    this.categories = await this.myApiService.get('career-category');
  }

  getCareerEffect$ = createEffect(
    (
      actions$ = inject(Actions),
    ) => {
      this.getLevels();
      return actions$.pipe(
        ofType(createCreerAction.getCareerModal),
        switchMap(() => {
          this.dialog.open(CareersModal, {
            data: {
              title: 'Crear Carrera',
              name: '',
              description: '',
              categories: this.categories,
              category: '',
              levels: this.levels,
              level: '',
              organizationalUnitName: '',
              organizationalUnits: this.dataOrganizationalUnits,
              acceptButton: 'Crear',
              cancelButton: 'Cancelar',
              inputActive: true,
              modify: false,
            },
          });

          return of(createCreerAction.getCareersModalSuccess());
        }),
        catchError(() => {
          return of(createCreerAction.getCareersModalFailure());
        })
          );
        })

  createCareer$ = createEffect(
    (
      actions$ = inject(Actions),
      getCareersService = inject(CareersService)
    ) => {
      return actions$.pipe(
        ofType(createCreerAction.createCareer),
        switchMap(({resquest}) => {
          return getCareersService.createCareer(resquest).pipe(
            map((career: any) => {
              this.store.dispatch(
                carrersAction.getCareersFeed()
              );
              this._snackBar.openFromComponent(SnackBarSuccess, {
                data: {
                  message: 'Carrera creada exitosamente',
                  subtitle:
                    'Podés realizar modificaciones o deshabilitarla cuando necesites',
                },
                panelClass: ['success-snackbar'],
                duration: 3000,
              });
              return createCreerAction.createCareersSuccess({career});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                carrerDetailsAction.getCareersDetailsFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
