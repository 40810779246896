import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { updateTypeProductsAction } from "../../actions/type-products/updateTypeProducts.action";
import { TypeProductsService } from "src/app/pages/type-products/type-product.service";
import { TypeProductModal } from "src/app/pages/type-products/type-products-modal/type-product-modal.component";
import { typeProductsAction } from "../../actions/type-products/typeProducts.action";

@Injectable()
export class updateTypeProductsEffect {
  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
  ){}


  getTypeProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getTypeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(updateTypeProductsAction.getTypeProductsDetails),
        switchMap(({id}) => {
          return getTypeProductsService.getTypeProductsDetails(id)
          .pipe(
            map((typeProducts: any) => {
              this.dialog.open(TypeProductModal, {
                data: {
                  id: `${typeProducts.id}`,
                  title: `Tipo de Producto - Nro ${typeProducts.id}`,
                  name: `${typeProducts.name}`,
                  description: `${typeProducts.description}`,
                  cancelButton: 'Cancelar',
                  code: `${typeProducts.cod}`,
                  acceptButton: 'Guardar Cambios',
                  inputActive: true,
                  modify: true,
                  active: `${typeProducts.active}`,
                },
              });

              return updateTypeProductsAction.getTypeProductsDetailsSuccess({typeProducts});
            }),
            catchError(() => {
              return of(
                updateTypeProductsAction.getTypeProductsDetailsFailure()
              );
            })
          );
        })
      );
    },
  );

  updateTypeProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getTypeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(updateTypeProductsAction.updateTypeProducts),
        switchMap(({id, request}) => {
          return getTypeProductsService
          .updateTypeProducts(id, request)
          .pipe(
            map((typeProducts: any) => {
              this.store.dispatch(
                typeProductsAction.getTypeProductsFeed()
              );
              return updateTypeProductsAction.updateTypeProductsSuccess({typeProducts});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ? errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                updateTypeProductsAction.updateTypeProductsFailure()
              );
            })
          );
        })
      );
    },
  );
}
