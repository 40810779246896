<div *ngIf="currentPeriod.id" class="summary">
  <div class="summary-header-container" *ngIf="currentPeriod">
    <div class="header-title">
      <span class="text">Periodo Actual: </span> 
      <span
        class="title" 
        [matTooltip]="currentPeriod!.name"
        [matTooltipShowDelay]=timeTooltip
        [matTooltipPosition]="position.value!"
        matTooltipClass="title-tooltip"
      >
        {{ currentPeriod!.name }}
      </span>
    </div>

    <div class="section-container">
      <div>
        {{ currentPeriod!.startDateTerm }} -
        {{ currentPeriod!.endDateTerm }}
      </div>

      <mat-chip
        [class.pointer-on-hover]="true"
        (click)="openModifyTerm(currentPeriod.id)"
      >
        <div class="chip-content">
          <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
          <span class="chip-text">{{ "Editar" }}</span>
        </div>
      </mat-chip>
    </div>
  </div>

  <div class="summary-container" *ngIf="currentPeriod">
    <div class="summary-body-container">
      <div
        class="summary-body-row"
        *ngFor="let timeTerm of currentPeriod!.timeTerm; let last = last"
      >
        <div>
          {{ timeTerm.typeTimeTerm.name }}
        </div>
        <div class="summary-dates">
          {{ timeTerm.startDate }} -
          {{ timeTerm.endDate }}
        </div>
      </div>
    </div>
  </div>
</div>
<app-empty-state
  *ngIf="!currentPeriod"
  [errorCode]="errorCode"
></app-empty-state>
