import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import * as moment from 'moment';
import { TermModalComponent } from 'src/app/pages/term/term-modal/term-modal.component';
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';
import { TermTable } from 'src/app/pages/term/term-table/term-table.component';
import { SummaryTermComponent } from 'src/app/pages/term/term-summary/term-summary.component';
import { TermHeader } from 'src/app/pages/term/term-header/term-header.component';
import { MyApiService } from 'src/services/connection';
import { TermsService } from './term.service';


@Component({
  standalone: true,
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.sass'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    EmptyStateComponent,
    TermHeader,
    SummaryTermComponent,
    TermTable,
  ]
})
export class TermComponent {

  constructor(
    public dialog: MatDialog,
    private myApiService: MyApiService,
    private termsService: TermsService,
  ) {}

  terms: any
  errorCode?: any = null;

  async ngOnInit() {
    try {
      await this.termsService.loadTerms();
      await this.termsService.findAll().then(term => {
        this.terms = term
      });
      // console.log(this.terms);
    } catch (error) {
      this.errorCode = error
      console.error('Error fetching data:', error);
    }
  }

  openCreateTerm() {
    const dialogRef = this.dialog.open(TermModalComponent, {
      data: {
        title: 'Nuevo Periodo de Compra',
        acceptButton: 'Crear Periodo',
        cancelButton: 'Cancelar',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.termsService.loadTerms();
      this.termsService.findAll().then(term => {
        this.terms = term
      });
    });
  }
}
