import { EventEmitter, Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import * as TypeProduct from '../../state/actions/typeProduct.actions';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TypeProductModal } from './type-products-modal/type-product-modal.component';
import { TypeProducts } from 'src/app/state/reducers/type-products/updateTypeProducts.reducer';
import { updateTypeProductsAction } from 'src/app/state/actions/type-products/updateTypeProducts.action';
import { typeProductsAction } from 'src/app/state/actions/type-products/typeProducts.action';
import { selectIsLoading, selectTypeProducts, selectTypeProductsActives, selectTypeProductsInactives } from 'src/app/state/reducers/type-products/typeProducts.reducer';
import { toggleStateTypeProductsActions } from 'src/app/state/actions/type-products/toggleStateTypeProducts.actions';
import { selectIsLoadingStatus } from 'src/app/state/reducers/type-products/toggleStateTypeProducts.reducers';
import { typeProductsDetailsAction } from 'src/app/state/actions/type-products/typeProductsDetails.action';

@Injectable({
  providedIn: 'root'
})

export class TypeProductsService {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private http: HttpClient,
  ) {}


  private editForm = new BehaviorSubject<boolean>(false);
  saveActive: boolean = false;
  disabledData: any;
  disabledEdit:boolean;
  dataSources = new MatTableDataSource<any>();
  alldataResponse = new MatTableDataSource<any>();

  disabledProductTypesChanged = new EventEmitter<boolean>();
  disabledProductTypes: boolean = false;

  disabledBtnProductTypesChanged = new EventEmitter<boolean>();
  disabledBtnProductTypes: boolean = true;

  updateBooleanData(value: boolean) {
    this.disabledProductTypes = value;
    this.disabledProductTypesChanged.emit(this.disabledProductTypes);
  }

  updateDisabledBtnProductTypes(newValue: boolean) {
    this.disabledBtnProductTypes = newValue;
    this.disabledBtnProductTypesChanged.emit(this.disabledBtnProductTypes);
  }


  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }


  createTypeProducts(typeProducts: any): Observable<TypeProducts> {
    const url = `${environment.apiUrl}/type-product`
    return this.http.post<TypeProducts>(url, typeProducts);
  }

  disableTypeProducts(
    id: number
  ): Observable<TypeProducts> {
    const fullUrl = `${environment.apiUrl}/type-product/disable/${id}`;
    return this.http.patch<TypeProducts>(
      fullUrl,
      null
    );
  }

  enableTypeProducts(
    id: number
  ): Observable<TypeProducts> {
    const fullUrl = `${environment.apiUrl}/type-product/enabled/${id}`;
    return this.http.patch<TypeProducts>(
      fullUrl,
      null
    );
  }

  getTypeProducts(): Observable<TypeProducts[]> {
    return this.http.get<TypeProducts[]>(`${environment.apiUrl}/type-product/order?orderByCreated=true`)

  }

  getTypeProductsDetails(id:number): Observable<TypeProducts> {
    return this.http.get<TypeProducts>(`${environment.apiUrl}/type-product/${id}`);
  }


  updateTypeProducts(id:number, typeProducts: any): Observable<TypeProducts> {
    const url = `${environment.apiUrl}/type-product/update/${id}`
    return this.http.patch<TypeProducts>(url, typeProducts);
  }

  async loadTypeOfProducts(): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch(typeProductsAction.getTypeProductsFeed());
        this.store.select(selectIsLoading).subscribe(loading => {
          if (!loading) resolve();
        });
      });
    } catch (error) {
      this.store.dispatch(TypeProduct.loadTypeProductsFailure({ error }));
    }
  }

  getProductsByType(id:number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/product/type-product/${id}`);
  }

  async validatedProducts(id:number): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch( toggleStateTypeProductsActions.getProducts({id}));

        const subscription = this.store.select(selectIsLoadingStatus).subscribe({
          next: (loading) => {
            if (!loading) {
              resolve();
              subscription.unsubscribe();

            }
          },
          error: (err) => {
            reject(err);
            subscription.unsubscribe();
          },
        });
      });
    } catch (error) {
      this.store.dispatch(toggleStateTypeProductsActions.getProductsFailure());    }
  }

  async existDisabled():Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.store.select(selectTypeProductsInactives).subscribe(productTypes => {
        if (!productTypes.length) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }



  activeTypeProducts(): void {
    this.store
    .select(selectTypeProductsActives)
    .subscribe((typeProductsAcvive: TypeProducts[]) => {
      this.dataSources.data = typeProductsAcvive;
    });
  }

  allTypeProducts(): void {
    this.store
    .select(selectTypeProducts)
    .subscribe((typeProducts: TypeProducts[]) => {
      this.alldataResponse.data = typeProducts;
    });
  }

  inactiveTypeProducts(): void {
    this.store
    .select(selectTypeProductsInactives)
    .subscribe((typeProductsInactive: TypeProducts[]) => {
      this.dataSources.data = typeProductsInactive;
    });
  }

  openTypeProductDetail(id: number) {
    this.store.dispatch(typeProductsDetailsAction.getTypeProductsDetails({id: id}))
  }

  async openModifyTypeProduct(id: number) {
    try {
      this.store.dispatch(updateTypeProductsAction.getTypeProductsDetails({id}))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  openCreateTypeProduct() {
    const dialogRef = this.dialog.open(TypeProductModal, {
      data: {
        title: 'Creando Tipo de Producto',
        name: '',
        description: '',
        acceptButton: 'Crear',
        cancelButton: 'Cancelar',
        inputActive: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
