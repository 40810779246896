import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { organizationalUnitActions } from '../../actions/organizational-units/organizationalUnit.actions';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { OrganizationalUnitModal } from '../../../pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';

@Injectable()
export class getOrganizationalUnitDetailEffect {
  constructor(private readonly actions$: Actions, public dialog: MatDialog) {}

  getOrganizationalUnitDetailEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(organizationalUnitActions.getOrganizationalUnit),
        switchMap(({ idOgUnit }) => {
          return organizationalUnitsService.getOrganizationalUnit(idOgUnit).pipe(
            map((organizationalUnit: OrganizationalUnitInterface) => {
              const dialogRef = this.dialog.open(OrganizationalUnitModal, {
                data: {
                  idOgUnit: `${idOgUnit}`,
                  title: `Unidad Organizacional - Nro ${organizationalUnit.idOgUnit}`,
                  name: `${organizationalUnit.name}`,
                  description: `${organizationalUnit.description}`,
                  cancelButton: 'Volver',
                  code: `${organizationalUnit.cod}`,
                  inputActive: false,
                },
              });

              return organizationalUnitActions.getOrganizationalUnitSuccess({
                organizationalUnit,
              });
            }),
            catchError(() => {
              return of(
                organizationalUnitActions.getOrganizationalUnitFailure()
              );
            })
          );
        })
      );
    }

  );
}

