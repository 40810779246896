import { createFeature, createReducer, on } from "@ngrx/store"
import { toggleStatePurchaseAnalystActions } from "../../actions/purchase-analyst/toggleStatePurchaseAnalyst.actions"

export interface DisablePurchaseAnalystStateInterface {
    isSubmittingToggleAnalyst: boolean,
    isLoadingPurchaseAnalystStatus: boolean,
    purchaseRequests:string[],
  }

const initialState: DisablePurchaseAnalystStateInterface = {
    isSubmittingToggleAnalyst: false,
    isLoadingPurchaseAnalystStatus: false,
    purchaseRequests: [],
  }

  const toggleStatePurchaseAnalystFeature = createFeature({
    name: 'toggleStatePurchaseAnalyst',
    reducer: createReducer(
      initialState,
      on(toggleStatePurchaseAnalystActions.disablePurchaseAnalyst, (state) => ({
        ...state,
        isSubmittingToggleAnalyst: false,
      })),
      on(toggleStatePurchaseAnalystActions.disablePurchaseAnalystSuccess, (state, action) => ({
        ...state,
        isSubmittingToggleAnalyst: true,
      })),
      on(toggleStatePurchaseAnalystActions.disablePurchaseAnalystFailure, (state, action) => ({
        ...state,
        isSubmittingToggleAnalyst: false,
      })),
      on(toggleStatePurchaseAnalystActions.enablePurchaseAnalyst, (state) => ({
        ...state,
        isSubmittingToggleAnalyst: false,
      })),
      on(toggleStatePurchaseAnalystActions.enablePurchaseAnalystSuccess, (state, action) => ({
        ...state,
        isSubmittingToggleAnalyst: true,
      })),
      on(toggleStatePurchaseAnalystActions.enablePurchaseAnalystFailure, (state, action) => ({
        ...state,
        isSubmittingToggleAnalyst: false,
      })),
      on(toggleStatePurchaseAnalystActions.getPurchaseRequest, (state) => ({
        ...state,
        PurchaseAnalystStatus: true,
    })),
      on(
        toggleStatePurchaseAnalystActions.getPurchaseRequestSuccess,
        (state, action) => ({
          ...state,
          isLoadingPurchaseAnalystStatus: false,
          purchaseRequests: action.purchaseRequests,
        })
      ),
      on(toggleStatePurchaseAnalystActions.getPurchaseRequestFailure, (state) => ({
        ...state,
        isLoadingPurchaseAnalystStatus: false,
      })),
    ),
  })

  export const {
    name: toggleStatePurchaseAnalystFeatureKey,
    reducer: toggleStatePurchaseAnalystReducer,
    selectIsSubmittingToggleAnalyst,
    selectPurchaseRequests,
    selectIsLoadingPurchaseAnalystStatus
  } = toggleStatePurchaseAnalystFeature
