<div class="component-phone" [formGroup]="dataForm">
  <h3 class="inputActive-phone-number">Teléfono{{required == true ? '*': null}}</h3>
  <div class="input-phone-component">
    <ngx-mat-input-tel
      [preferredCountries]="defaultCountryNumber"
      [enablePlaceholder]="false"
      [enableSearch]="true"
      searchPlaceholder="Buscar país"
      name="phone"
      describedBy="phoneInput"
      [cssClass]='"phoneInput"'
      #phoneInput
    ></ngx-mat-input-tel>
    <span class="space">+{{codeCountry}} -</span>
    <input
      onKeyPress="if(this.value.length==11) return false;"
      formControlName="phoneNumber"
      matInput
      class="phoneNumber-input"
      type="text"
      pattern="[0-9]*"
      placeholder="Teléfono"
      (input)="charactersValidation($event)"
    />
  </div>
  <hr class="inputLine" />
  <div class="phone-number-container-error">
    <mat-hint *ngIf="dataForm.get('phoneNumber').value.length >= 11">
      Máximo de caracteres alcanzados(11)
    </mat-hint>
    <mat-error *ngIf="dataForm.get('phoneNumber')?.hasError('minlength')">
      Debe tener un mínimo 8 caracteres
    </mat-error>
  </div>
</div>