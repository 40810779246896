import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { OrganizationalUnitModal } from '../../../pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationalUnitsService } from 'src/app/pages/organizational-units/organizational-unit.service';
import { updateOrganizationalUnitActions } from '../../actions/organizational-units/updateOrganizationalUnit.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { organizationalUnitFeedActions } from '../../actions/organizational-units/organizationalUnitFeed.actions';
import { organizationalUnitActions } from '../../actions/organizational-units/organizationalUnit.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarWarning } from 'src/app/shared/components/snackbar/snackbar-warning.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class updateOrganizationalUnitEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>) {}

  getOrganizationalUnitEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(updateOrganizationalUnitActions.getOrganizationalUnit),
        switchMap(({ idOgUnit }) => {
          return organizationalUnitsService
            .getOrganizationalUnit(idOgUnit)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                const dialogRef = this.dialog.open(OrganizationalUnitModal, {
                  data: {
                    idOgUnit: `${organizationalUnit.idOgUnit}`,
                    title: `Unidad Organizacional - Nro ${organizationalUnit.idOgUnit}`,
                    name: `${organizationalUnit.name}`,
                    description: `${organizationalUnit.description}`,
                    acceptButton: 'Guardar Cambios',
                    code: `${organizationalUnit.cod}`,
                    cancelButton: 'Cancelar',
                    inputActive: true,
                    id: `${organizationalUnit.idOgUnit}`,
                    modify: true,
                    active: organizationalUnit.active,
                  },
                });
                return updateOrganizationalUnitActions.getOrganizationalUnitSuccess(
                  {
                    organizationalUnit,
                  }
                );
              }),
              catchError(() => {
                return of(
                  updateOrganizationalUnitActions.getOrganizationalUnitFailure()
                );
              })
            );
        })
      );
    }
  );

  updateOrganizationalUnitEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      organizationalUnitsService = inject(OrganizationalUnitsService)
    ) => {
      return actions$.pipe(
        ofType(updateOrganizationalUnitActions.updateOrganizationalUnit),
        switchMap(({ request, idOgUnit }) => {
          return organizationalUnitsService
            .updateOrganizationalUnit(request, idOgUnit)
            .pipe(
              map((organizationalUnit: OrganizationalUnitInterface) => {
                this.store.dispatch(
                  organizationalUnitFeedActions.getOrganizationalUnitFeed()
                );
                this.store.dispatch(
                  organizationalUnitActions.getOrganizationalUnit({
                    idOgUnit: idOgUnit,
                  })
                );

                return updateOrganizationalUnitActions.updateOrganizationalUnitSuccess(
                  { organizationalUnit }
                );
              }),
              catchError((errorResponse: HttpErrorResponse) => {
                const statusCode = errorResponse.error.statusCode;
                this._snackBar.openFromComponent(SnackBarWarning, {
                  data: {
                    message: 'Ocurrió un error',
                    subtitle:
                      statusCode === 409
                        ? errorResponse.error.message
                        : 'Por favor ingresa nuevamente los datos',
                  },
                  duration: 3000,
                  panelClass: ['warning-snackbar'],
                });
                return of(
                  updateOrganizationalUnitActions.updateOrganizationalUnitFailure()
                );
              })
            );
        })
      );
    }
  );
}
