<div class="modalContainer">
  <mat-dialog-content class="informationContainer">
    <div class="titleContainer">{{ title }}</div>
    <div class="subtitleContainer">{{ subtitleAbove }}</div>

    <div class="supplierToDeleteContainer">
      <div *ngFor="let user of supplierUser">
        <mat-expansion-panel [ngClass]="{'selected-user': isSelected(user.uuid)}">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox 
                [checked]="isSelected(user.id)" 
                (change)="toggleSelection(user.uuid, $event)"
                (click)="$event.stopPropagation()"
              >
              </mat-checkbox>
              <div class="person-circle">
                <div class="icon-container">
                  <mat-icon class="person-icon" fontSet="material-icons-outlined">person</mat-icon>
                </div>
              </div>
              <div class="title-card">
                <h3 class="title">{{ user.position.name }}: {{ user.name }}</h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="rowContainer">
            <div class="smallContainer">
              <h5 class="inputActive">Nombre</h5>
              <p class="inputDescription">{{ user.name }}</p>
            </div>
            <div class="smallContainer">
              <h5 class="inputActive">Apellido</h5>
              <p class="inputDescription">{{ user.lastname }}</p>
            </div>
            <div class="smallContainer">
              <h5 class="inputActive">Cargo</h5>
              <p class="inputDescription">{{ user.position.name }}</p>
            </div>
            <div class="smallContainer">
              <h5 class="inputActive">Teléfono</h5>
              <p class="inputDescription">{{ user.phoneNumber }}</p>
            </div>
            <div class="smallContainer">
              <h5 class="inputActive">Correo</h5>
              <p class="inputDescription">{{ user.email }}</p>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <div class="subtitleContainer">{{ subtitleBellow }}</div>
    <div class="subtitleContainer">{{ subtitleQuestion }}</div>
  </mat-dialog-content>

  <mat-dialog-actions class="buttonContainer">
    <button 
      mat-button 
      *ngIf="acceptButton !== ''" 
      (click)="onAcceptButtonClick()" 
      class="acceptButton"
      [disabled]="selectedUserIdsArray.length === 0"
    >
      {{ acceptButton }}
    </button>
    <button 
      mat-button 
      class="cancelButton" 
      [mat-dialog-close]="true"
    >
      {{ cancelButton }}
    </button>
  </mat-dialog-actions>
</div>
