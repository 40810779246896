import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { TypeProductsService } from "src/app/pages/type-products/type-product.service";
import { typeProductsAction } from "../../actions/type-products/typeProducts.action";
import { TypeProducts } from "../../reducers/type-products/updateTypeProducts.reducer";

@Injectable()
export class getTypeProductsEffect {
  constructor(private readonly actions$: Actions) {}

  getTypeProductsEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      getTypeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(typeProductsAction.getTypeProductsFeed),
        switchMap(() => {
          return getTypeProductsService.getTypeProducts().pipe(
            map((typeProducts: TypeProducts[]) => {
              return typeProductsAction.getTypeProductsFeedSuccess({typeProducts});
            }),
            catchError(() => {
              return of(
                typeProductsAction.getTypeProductsFeedFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
