import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { Careers } from '../../reducers/careers.reducer';
  
export const carrerDetailsAction = createActionGroup({
  source: 'careerDetails',
  events: {
    'Get Career Details': props<{ idCar: number }>(),
    'Get Careers Details success': props<{
      career: Careers;
    }>(),
    'Get careers Details failure': emptyProps(),
  },
});
  