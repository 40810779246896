
<div class="modalContainer">
  <mat-dialog-content class="informationContainer">
    <div class="iconContainer">
      <mat-icon>warning</mat-icon>
    </div>
    <div class="titleContainer" >{{title}}</div>
    <div class="subtitleContainer">{{subtitle}}<br/>{{subtitleQuestion}}</div>
  </mat-dialog-content>
  <mat-dialog-actions class="buttonContainer">
    <button mat-button (click)="onAcceptButtonClick()" class="acceptButton"> {{ acceptButton }} </button>
    <button mat-button  class="cancelButton"  [mat-dialog-close]="true">
      {{ cancelButton }}
    </button>
  </mat-dialog-actions>
</div>
