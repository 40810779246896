import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarSuccess } from "src/app/shared/components/snackbar/snackbar-success.component";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarWarning } from "src/app/shared/components/snackbar/snackbar-warning.component";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { TypeProductsService } from "src/app/pages/type-products/type-product.service";
import { typeProductsAction } from "../../actions/type-products/typeProducts.action";
import { createTypeProductsAction } from "../../actions/type-products/createTypeProduct.action";
import { typeProductsDetailsAction } from "../../actions/type-products/typeProductsDetails.action";

@Injectable()
export class createTypeProductsEffect {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private store: Store<AppState>
  ){}

  createTypeProducts$ = createEffect(
    (
      actions$ = inject(Actions),
      getTypeProductsService = inject(TypeProductsService)
    ) => {
      return actions$.pipe(
        ofType(createTypeProductsAction.createTypeProducts),
        switchMap(({request}) => {
          return getTypeProductsService.createTypeProducts(request).pipe(
            map((typeProducts: any) => {
              this.store.dispatch(
                typeProductsAction.getTypeProductsFeed()
              );
              this._snackBar.openFromComponent(SnackBarSuccess, {
                data: {
                  message: 'Tipo de producto creado exitosamente',
                  subtitle:
                    'Podés realizar modificaciones o deshabilitarla cuando necesites',
                },
                panelClass: ['success-snackbar'],
                duration: 3000,
              });
              return createTypeProductsAction.createTypeProductsSuccess({typeProducts});
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              const statusCode = errorResponse.error.statusCode;
              this._snackBar.openFromComponent(SnackBarWarning, {
                data: {
                  message: 'Ocurrió un error',
                  subtitle:
                    statusCode === 409
                      ?  errorResponse.error.message
                      : 'Por favor reintenta este registro más tarde',
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
              });
              return of(
                typeProductsDetailsAction.getTypeProductsDetailsFailure()
              );
            })
          );
        })
      );
    },
    {functional: true}
  );
}
