import { createFeature, createReducer, on } from '@ngrx/store';
import { countriesActions } from '../../actions/countries/countries.actions';
import { CountryInterface } from 'src/app/interfaces/country.interface';

export interface CountriesState {
  isLoading: boolean;
  error: string;
  countries: CountryInterface[];
}

export const initialState: CountriesState = {
  isLoading: false,
  error: null,
  countries: [],
};

const countriesFeature = createFeature({
  name: 'countries',
  reducer: createReducer(
    initialState,
    on(countriesActions.getCountries, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(countriesActions.getCountriesSuccess, (state, action) => ({
      ...state,
      isLoading: false,
      countries: action.countries,
    })),
    on(countriesActions.getCountriesFailure, (state) => ({
      ...state,
      isLoading: false,
    }))
  ),
});

export const {
  name: countriesFeatureKey,
  reducer: countriesReducer,
  selectIsLoading,
  selectError,
  selectCountries,
} = countriesFeature;
