import { createFeature, createReducer, on } from "@ngrx/store"
import { toggleStateClientActions } from "../../actions/clients/toggleStateClient.actions"

export interface DisableClientStateInterface {
    isSubmittingClient: boolean,
    isLoadingStatus: boolean,
    purchaseRequests:string[],
  }

const initialState: DisableClientStateInterface = {
    isSubmittingClient: false,
    isLoadingStatus: false,
    purchaseRequests: [],
  }

  const toggleStateClientFeature = createFeature({
    name: 'toggleStateClient',
    reducer: createReducer(
      initialState,
      on(toggleStateClientActions.disableClient, (state) => ({
        ...state,
        isSubmittingClient: false,
      })),
      on(toggleStateClientActions.disableClientSuccess, (state, action) => ({
        ...state,
        isSubmittingClient: true,
      })),
      on(toggleStateClientActions.disableClientFailure, (state, action) => ({
        ...state,
        isSubmittingClient: false,
      })),
      on(toggleStateClientActions.enableClient, (state) => ({
        ...state,
        isSubmittingClient: false,
      })),
      on(toggleStateClientActions.enableClientSuccess, (state, action) => ({
        ...state,
        isSubmittingClient: true,
      })),
      on(toggleStateClientActions.enableClientFailure, (state, action) => ({
        ...state,
        isSubmittingClient: false,
      })),
      on(toggleStateClientActions.getPurchaseRequest, (state) => ({
        ...state,
        isLoadingStatus: true,
    })),
      on(
        toggleStateClientActions.getPurchaseRequestSuccess,
        (state, action) => ({
          ...state,
          isLoadingStatus: false,
          purchaseRequests: action.purchaseRequests,
        })
      ),
      on(toggleStateClientActions.getPurchaseRequestFailure, (state) => ({
        ...state,
        isLoadingStatus: false,
      })),
    ),
  })

  export const {
    name: toggleStateClientFeatureKey,
    reducer: toggleStateClientReducer,
    selectIsSubmittingClient,
    selectPurchaseRequests,
    selectIsLoadingStatus
  } = toggleStateClientFeature
