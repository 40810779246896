import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  organizationalUnitFeedActions,
} from '../../actions/organizational-units/organizationalUnitFeed.actions';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

export interface OrganizationalUnitsState {
  isLoading: boolean;
  error: string;
  organizationalUnits: OrganizationalUnitInterface[];
}


export const initialState: OrganizationalUnitsState = {
  isLoading: false,
  error: null,
  organizationalUnits: [],
};

const organizationalUnitFeedFeature = createFeature({
  name: 'organizationalUnit',
  reducer: createReducer(
    initialState,
    on(organizationalUnitFeedActions.getOrganizationalUnitFeed, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(
      organizationalUnitFeedActions.getOrganizationalUnitFeedSuccess,
      (state, action) => ({
        ...state,
        isLoading: false,
        organizationalUnits: action.organizationalUnitFeed,
      })
    ),
    on(
      organizationalUnitFeedActions.getOrganizationalUnitFeedFailure,
      (state) => ({
        ...state,
        isLoading: false,
      })
    )
  ),
  extraSelectors: ({ selectOrganizationalUnits }) => ({
    selectOrganizationalUnitActives: createSelector(
      selectOrganizationalUnits,
      (units) => units.filter((unit) => Boolean(unit.active) == true)
    ),
    selectOrganizationalUnitInactives: createSelector(
      selectOrganizationalUnits,
      (units) => units.filter((unit) => Boolean(unit.active) == false)
    ),
    selectOrganizationalUnitbtn: createSelector(
      selectOrganizationalUnits,
      (units) => ((units.filter((unit) => Boolean(unit.active) == false).length > 0))
    ),
  }),
});


export const {
  name: organizationalUnitFeedFeatureKey,
  reducer: organizationalUnitFeedReducer,
  selectIsLoading,
  selectError,
  selectOrganizationalUnits: selectOrganizationalUnitFeedData,
  selectOrganizationalUnitActives,
  selectOrganizationalUnitInactives,
  selectOrganizationalUnitbtn
} = organizationalUnitFeedFeature;
