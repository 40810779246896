import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientInterface } from 'src/app/pages/clients/types/client.interface';

export const clientActions = createActionGroup({
  source: 'client',
  events: {
    'Get client': props<{ uuid: string }>(),
    'Get client success': props<{
      client: ClientInterface;
    }>(),
    'Get client failure': emptyProps(),
  },
});
