import { createFeature, createReducer, on } from "@ngrx/store"
import { updatePurchaseAnalystActions } from "../../actions/purchase-analyst/updatePurchaseAnalyst.actions"
import { PurchaseAnalystInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalyst.interface"

export interface UpdatePurchaseAnalystStateInterface {
    purchaseAnalyst: PurchaseAnalystInterface,
    isSubmittingUpdatePurchaseAnalyst: boolean,
    validationErrors: string,
  }

const initialState: UpdatePurchaseAnalystStateInterface = {
    purchaseAnalyst: null,
    isSubmittingUpdatePurchaseAnalyst: false,
    validationErrors: null,
  }

  const updatePurchaseAnalystFeature = createFeature({
    name: 'updatePurchaseAnalyst',
    reducer: createReducer(
      initialState,
      on(updatePurchaseAnalystActions.getPurchaseAnalyst, (state) => ({
        ...state,
        isLoading: true,
      })),
      on(updatePurchaseAnalystActions.getPurchaseAnalystSuccess, (state, action) => ({
        ...state,
        isLoading: false,
        purchaseAnalyst: action.purchaseAnalyst,
      })),
      on(updatePurchaseAnalystActions.getPurchaseAnalystFailure, (state) => ({
        ...state,
        isLoading: false,
      })),
      on(updatePurchaseAnalystActions.updatePurchaseAnalyst, (state) => ({
        ...state,
        isSubmittingUpdatePurchaseAnalyst: false,
      })),
      on(updatePurchaseAnalystActions.updatePurchaseAnalystSuccess, (state) => ({
        ...state,
        isSubmittingUpdatePurchaseAnalyst: true,
      })),
      on(updatePurchaseAnalystActions.updatePurchaseAnalystFailure, (state, action) => ({
        ...state,
        isSubmittingUpdatePurchaseAnalyst: false,
        validationErrors: '',
      })),
    ),
  })

  export const {
    name: updatePurchaseAnalystFeatureKey,
    reducer: updatePurchaseAnalystReducer,
    selectIsSubmittingUpdatePurchaseAnalyst,
    selectValidationErrors,
    selectPurchaseAnalyst,
  } = updatePurchaseAnalystFeature
