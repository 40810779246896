import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { Careers } from '../../reducers/careers.reducer';

export const createCreerAction = createActionGroup({
  source: 'create career',
  events: {
    'Get Career Modal': emptyProps(),
    'Get Careers Modal success': emptyProps(),
    'Get careers Modal failure': emptyProps(),

    'Create Career': props<{ resquest: Careers }>(),
    'Create Careers success': props<{career: Careers}>(),
    'Create careers failure': emptyProps(),
  },
});
