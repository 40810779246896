import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { carrersAction } from '../../actions/careers/careers.action';


export interface CareersState {
  careers: Careers[];
  isLoading: boolean;
  error: any;
}

export interface Careers {
  cod: string;
  name: string;
  description: string;
  active: boolean;
  career_level: string;
  career_category: string;
  orgUnitId: number;
}

export const initialCareersState: CareersState = {
  isLoading: false,
  error: null,
  careers: [],
};

const careersReducer = createFeature({
  name: 'careers',
  reducer: createReducer(
    initialCareersState,
    on(carrersAction.getCareersFeed, (state) => ({
      ...state,
      isLoading: true,
      error: null
    })),
    on(carrersAction.getCareersFeedSuccess, (state, {careers}) => ({
      ...state,
      careers,
      isLoading: false,
      error: null
    })),
    on(carrersAction.getCareersFeedFailure, (state) => ({
      ...state,
      isLoading: false,
      error: 'Error al cargar las carreras'
    }))
  ),
  extraSelectors: ({ selectCareers }) => ({
    selectCareersInactives: createSelector(
      selectCareers,
      (careers) => careers.filter((career) => Boolean(career.active) == false)
    ),
    selectCareersActives: createSelector(
      selectCareers,
      (careers) => careers.filter((career) => Boolean(career.active) == true)
    ),
  }),
});

export const {
  name: careersFeedReducerKey,
  reducer: careersFeedReducer,
  selectError,
  selectIsLoading,
  selectCareers,
  selectCareersActives,
  selectCareersInactives
} = careersReducer;
