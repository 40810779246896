import { createFeature, createReducer, on } from '@ngrx/store';
import { createTypeProductsAction } from '../../actions/type-products/createTypeProduct.action';


export interface UpdateTypeProductsState {
  isSubmittingTypeProductsCreate: boolean,
  error: string,
}

export const initialTypeProductsState: UpdateTypeProductsState = {
  isSubmittingTypeProductsCreate: false,
  error: null,
};

const createTypeProductsReducer = createFeature({
  name: 'createTypeProducts',
  reducer: createReducer(
    initialTypeProductsState,
    on(createTypeProductsAction.createTypeProducts, (state) => ({
      ...state,
      isSubmittingTypeProductsCreate: false,
    })),
    on(createTypeProductsAction.createTypeProductsSuccess, (state) => ({
      ...state,
      isSubmittingTypeProductsCreate: true,
    })),
    on(createTypeProductsAction.createTypeProductsFailure, (state) => ({
      ...state,
      isSubmittingTypeProductsCreate: false,
      error: 'Error al crear cargar la carrera'
    }))
  ),
});

export const {
  name: createTypeProductReducerKey,
  reducer: createTypeProductReducer,
  selectIsSubmittingTypeProductsCreate,
  selectError,
} = createTypeProductsReducer;
