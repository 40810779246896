import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { YearsInterface } from 'src/app/interfaces/years.interface';

export const yearsActions = createActionGroup({
  source: 'years',
  events: {
    'Get years': emptyProps(),
    'Get years success': props<{
      years: YearsInterface[];
    }>(),
    'Get years failure': emptyProps(),
  },
});
