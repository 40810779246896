<div class="snackbar-content">
  <div class="message-container">
    <mat-icon class="icon">warning</mat-icon>
    <div class="text-container">
      <div class="message">{{ data.message }}</div>
      <div class="subtitle">{{ data.subtitle }}</div>
    </div>
  </div>
  <span matSnackBarActions>
    <button mat-button (click)="snackBarRef.dismissWithAction()">Cerrar</button>
  </span>
</div>