<div class="navbar">
  <div class="user-info">
    <i class="material-icons">assignment_ind</i>

    <div class="info">
      <div class="username">{{ userName }}</div>
      <div class="cuil">{{ cuil }}</div>
    </div>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
    >
      <i class="material-icons">expand_more</i>
    </button>

    <mat-menu #menu="matMenu" backdropClass="sg-vertical-sub-menu" [hasBackdrop]="true">
      <button mat-menu-item
        routerLink="/perfil"
        routerLinkActive="btn-page-active"
      >
        <i class="material-icons">manage_accounts</i>
        <span>Perfil</span>
      </button>
      <button 
        mat-menu-item 
        (click)="onClickBtn()"
      >
        <i class="material-icons">key</i>
        <span>Modificar contraseña</span>
      </button>
      <button mat-menu-item
        routerLink="/configuración"
        routerLinkActive="btn-page-active"
      >
        <i class="material-icons">settings</i>
        <span>Configuración</span>
      </button>
      <button mat-menu-item
        routerLink="/login"
        routerLinkActive="btn-page-active"
      >
        <i class="material-icons">logout</i>
        <span>Cerrar sesión</span>
      </button>
    </mat-menu>
  </div>
</div>
