import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PurchaseAnalystInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalyst.interface';
import { PurchaseAnalystsFilterInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystFilter.interface';
import { PurchaseAnalystFormInterface } from 'src/app/pages/purchase-analyst/types/purchaseAnalystForm.interface';

export const updatePurchaseAnalystActions = createActionGroup({
  source: 'update purchase analyst',
  events: {
    'Get purchase analyst': props<{ uuid: string }>(),
    'Get purchase analyst success': props<{
      purchaseAnalyst: PurchaseAnalystInterface;

    }>(),
    'Get purchase analyst failure': emptyProps(),

    'Update purchase analyst': props<{
      request: PurchaseAnalystFormInterface;
      uuid: string;
      isActive: boolean;
      filters: PurchaseAnalystsFilterInterface;
    }>(),
    'Update purchase analyst success': props<{
      purchaseAnalyst: PurchaseAnalystInterface;
    }>(),
    'Update purchase analyst failure': emptyProps(),
  },
});
