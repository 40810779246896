import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PurchaseAnalystInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalyst.interface";
import { PurchaseAnalystFormInterface } from "src/app/pages/purchase-analyst/types/purchaseAnalystForm.interface";

export const createPurchaseAnalystActions = createActionGroup({
    source: 'create purchase analyst',
    events: {
      'Create purchase analyst': props<{
        request: PurchaseAnalystFormInterface;
      }>(),
      'Create purchase analyst success': props<{
        purchaseAnalyst: PurchaseAnalystInterface;
      }>(),
      'Create purchase analyst failure': emptyProps(),
    },
  });
