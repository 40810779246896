import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SupplierServices } from "src/app/pages/supplier/supplier.service";
import { supplierFeedActions } from "../../actions/supplier/supplierFeed.actions";
import { catchError, map, of, switchMap } from "rxjs";
import { SupplierResponseInterface } from "src/app/pages/supplier/types/supplierResponse.interface";



@Injectable()
export class getSupplierFeedEffect {
  constructor(private readonly actions$: Actions) {}

  getSupplierFeedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      supplierFeedService = inject(SupplierServices)
    ) => {
      return actions$.pipe(
        ofType(supplierFeedActions.getSupplierFeed),
        switchMap(({filters}) => {
          return supplierFeedService
            .getSupplierFeed({
              actives: filters.actives,
              page: filters.page,
              limit: filters.limit,
              column: filters.column,
              order: filters.order,
            })
            .pipe(
              map((suppliersFeed: SupplierResponseInterface) => {
                return supplierFeedActions.getSupplierFeedSuccess({
                    suppliersFeed,
                });
              }),
              catchError(() => {
                return of(supplierFeedActions.getSupplierFeedFailure());
              })
            );
        })
      );
    }
  );
}
