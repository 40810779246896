
<div class="table-name">Periodos</div>

<div class="dynamic-table mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
  >
    <ng-container
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ getColumnHeader(column) }}
      </th>
      <td 
        mat-cell 
        *matCellDef="let element" 
        [ngClass]="element.colorClass"
      >
        <span 
          *ngIf="column !== 'id'; else idColumn" 
          [matTooltip]="element[column]"
          [matTooltipShowDelay]=timeTooltip
          [matTooltipPosition]="position.value!"
          matTooltipClass="title-tooltip"
        >
          {{ element[column] }}
        </span>
        <ng-template #idColumn>
          <span>{{ element.id }}</span>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="editButtons">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.colorClass">
        <span class="vertical-line"></span>
        <button 
          mat-icon-button 
          [disabled]="element.disabled"
          class="edit-buttons"
          (click)="openModifyTerm(element.id)"
          matTooltip="Editar"
          [matTooltipShowDelay]=timeTooltip
          [matTooltipPosition]="position.value!"
        >
          <mat-icon fontSet="material-icons-outlined">
            edit
          </mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columsToShow"></tr>
    <tr mat-row *matRowDef="let row; columns: columsToShow"></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  ></mat-paginator>
</div>

