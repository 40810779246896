import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { Careers } from '../../reducers/careers.reducer';
  
export const updateCarrerAction = createActionGroup({
  source: 'update career',
  events: {
    'Get Career Details': props<{ idCar: number }>(),
    'Get Careers Details success': props<{career: Careers}>(),
    'Get careers Details failure': emptyProps(),
    
    'Update Career': props<{ idCar: number, request: Careers }>(),
    'Update Careers success': props<{career: Careers}>(),
    'Update careers failure': emptyProps(),
  },
});
  