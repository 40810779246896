<ng-container>
  <div class="centered-content">
    <img src="../../../assets/images/logo-ucc.png" alt="Logo UCC" />
    <p class="pnf-title">Página no encontrada</p>
    <p>Lo sentimos, no pudimos encontrar esa página.</p>
    <mat-chip  (click)="redirectToHome()"  class="mat-chip">
      <div class="chip-content">
        <span class="chip-text">Volver al inicio</span>
      </div>
    </mat-chip>
  </div>
</ng-container>
