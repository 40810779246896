import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { DateServices } from 'src/services/date-service';
import { yearsActions } from '../../actions/supplier/years.actions';
import { YearsInterface } from 'src/app/interfaces/years.interface';



@Injectable()
export class getYearsEffect {
  constructor(private readonly actions$: Actions) {}

  getLocalitiesEffect$ = createEffect(
    (actions$ = inject(Actions), dateServices = inject(DateServices)) => {
      return actions$.pipe(
        ofType(yearsActions.getYears),
        switchMap(() => {
          return dateServices.getYears().pipe(
            map((years: YearsInterface[]) => {
              return yearsActions.getYearsSuccess({
                years,
              });
            }),
            catchError(() => {
              return of(yearsActions.getYearsFailure());
            })
          );
        })
      );
    }
  );
}