import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  standalone: true,
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass'],
  imports: [CommonModule, MatButtonModule, MatChipsModule],
})
export class PageNotFound {
  constructor(private router: Router) {}

  redirectToHome(): void {
    this.router.navigate(['/home']);
  }
}
