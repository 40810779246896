import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';

export const organizationalUnitFeedActions = createActionGroup({
  source: 'organizationalUnitFeed',
  events: {
    'Get Organizational Unit feed': emptyProps(),
    'Get Organizational Unit feed success': props<{
      organizationalUnitFeed: OrganizationalUnitInterface[];
    }>(),
    'Get Organizational Unit feed failure': emptyProps(),
  },
});



export const loadOrganizationalUnits = createAction(
  '[Organizational Units] Load Organizational Units'
);

export const loadOrganizationalUnitsSuccess = createAction(
  '[Organizational Units] Load Organizational Units Success',
  props<{ organizationalUnits: OrganizationalUnitInterface[] }>()
);
