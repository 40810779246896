import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { HttpClient } from '@angular/common/http';
import { OrganizationalUnitInterface } from 'src/app/shared/types/organizationalUnit.interface';
import { environment } from 'src/environments/environment';
import { selectOrganizationalUnitActives, selectOrganizationalUnitInactives } from '../../state/reducers/organizational-units/organizationalUnitFeed.reducers';
import { OrganizationalUnitFormInterface } from 'src/app/shared/types/organizationalUnitForm.interface';
import { updateOrganizationalUnitActions } from 'src/app/state/actions/organizational-units/updateOrganizationalUnit.actions';
import { organizationalUnitActions } from 'src/app/state/actions/organizational-units/organizationalUnit.actions';
import { toggleStateOrganizationalUnitActions } from 'src/app/state/actions/organizational-units/toggleStateOrganizationalUnit.actions';
import { selectIsLoadingStatus } from 'src/app/state/reducers/organizational-units/toggleStateOrgnizationalUnit.reducers';

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitsService {
  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private http: HttpClient
  ) { }

  private editForm = new BehaviorSubject<boolean>(false);
  saveActive: boolean = false;
  disabledData: any;
  dataSources = new MatTableDataSource<any>();
  disabledEdit: boolean;

  disabledUnitsChanged = new EventEmitter<boolean>();
  disabledUnits: boolean = false;

  disabledBtnUnitsChanged = new EventEmitter<boolean>();
  disabledBtnUnits: boolean = true;

  updateBooleanData(value: boolean) {
    this.disabledUnits = value;
    this.disabledUnitsChanged.emit(this.disabledUnits);
  }

  updateDisabledBtnUnits(newValue: boolean) {
    this.disabledBtnUnits = newValue;
    this.disabledBtnUnitsChanged.emit(this.disabledBtnUnits);
  }

  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }

  getOrganizationalUnitFeed(): Observable<OrganizationalUnitInterface[]> {
    const fullUrl = `${environment.apiUrl}/organizational-unit`;
    return this.http.get<OrganizationalUnitInterface[]>(fullUrl);
  }

  async validatedCareers(id:number): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        this.store.dispatch( toggleStateOrganizationalUnitActions.getCareer({id}));

        const subscription = this.store.select(selectIsLoadingStatus).subscribe({
          next: (loading) => {
            if (!loading) {
              resolve();
              subscription.unsubscribe();

            }
          },
          error: (err) => {
            reject(err);
            subscription.unsubscribe();
          },
        });
      });
    } catch (error) {
      this.store.dispatch(toggleStateOrganizationalUnitActions.getCareerFailure());    }
  }

  activeOrganizationalUnits(): void {
    this.store
      .select(selectOrganizationalUnitActives)
      .subscribe((organizationalUnits) => {
        this.dataSources.data = organizationalUnits;
      });
  }

  inactiveOrganizationalUnits(): void {
    this.store
      .select(selectOrganizationalUnitInactives)
      .subscribe((organizationalUnits) => {
        this.dataSources.data = organizationalUnits;
      });
  }

  async openModifyOrganizationalUnit(idOgUnit: number) {
    try {
      this.store.dispatch(
        updateOrganizationalUnitActions.getOrganizationalUnit({ idOgUnit })
      );

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  getOrganizationalUnit(
    idOgUnit: number
  ): Observable<OrganizationalUnitInterface> {
    const fullUrl = `${environment.apiUrl}/organizational-unit/${idOgUnit}`;
    return this.http.get<OrganizationalUnitInterface>(fullUrl);
  }

  openOrganizationalUnitDetail(idOgUnit: number) {
    this.store.dispatch(
      organizationalUnitActions.getOrganizationalUnit({ idOgUnit: idOgUnit })
    );
  }

  createOrganizationalUnit(
    organizationalUnitRequest: OrganizationalUnitFormInterface
  ): Observable<OrganizationalUnitInterface> {
    const fullUrl = environment.apiUrl + '/organizational-unit';
    return this.http.post<OrganizationalUnitInterface>(
      fullUrl,
      organizationalUnitRequest
    );
  }

  updateOrganizationalUnit(
    organizationalUnitRequest: OrganizationalUnitFormInterface,
    idOgUnit: number
  ): Observable<OrganizationalUnitInterface> {
    const fullUrl = `${environment.apiUrl}/organizational-unit/update/${idOgUnit}`;
    return this.http.patch<OrganizationalUnitInterface>(
      fullUrl,
      organizationalUnitRequest
    );
  }

  enableOrganizationalUnit(
    idOgUnit: number
  ): Observable<OrganizationalUnitInterface> {
    const fullUrl = `${environment.apiUrl}/organizational-unit/enabled/${idOgUnit}`;
    return this.http.patch<OrganizationalUnitInterface>(
      fullUrl,
      null
    );
  }

  disableOrganizationalUnit(
    idOgUnit: number
  ): Observable<OrganizationalUnitInterface> {
    const fullUrl = `${environment.apiUrl}/organizational-unit/disable/${idOgUnit}`;
    return this.http.patch<OrganizationalUnitInterface>(
      fullUrl,
      null
    );
  }
}
